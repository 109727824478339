import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  FormHelperText,
  Paper,
  CircularProgress,
  IconButton,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import Draggable from "react-draggable";
import CheckIcon from "@mui/icons-material/Check";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";

const DraggableDialog = (props) => {
  const handleDrag = (event) => {
    event.preventDefault();
  };

  return (
    <Draggable handle=".drag-handle" onDrag={handleDrag}>
      <Paper {...props} />
    </Draggable>
  );
};

const SoftwareForm = ({ isOpen, onClose, onSubmit, title, software, softwareFile }) => {
  const emptyErrorMessage = "Dies ist ein Pflichtfeld"
  const validFileTypeBin = ".bin";
  const validFileTypeSrec = ".srec";
  const validFileTypeA00 = ".a00";
  const fileTypeErrorMessage = `Ungültiger Dateityp. Gültige Dateitypen: ${validFileTypeBin}, ${validFileTypeSrec}, ${validFileTypeA00}`

  const [formData, setFormData] = useState({
    software_id: {
      value: software ? software.software_id : ""
    },
    name: {
      value: software?.name || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    version: {
      value: software?.version || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    software_type: {
      value: software?.software_type || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    software_file: {
      value: softwareFile ? softwareFile : null,
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage,
      fileTypeError: false,
      fileTypeErrorMessage: fileTypeErrorMessage
    }, 
    uploading: {
      value: false,
    }
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    if(formData[name.toString()].emptyErrorMessage) {
      checkNoEmptyInput(name, value);
    } else {
      setFormData({
        ...formData,
        [name]:{
          ...formData[name],
          value
        }
      })
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const name = event.target.name;

    if (!(file.name.endsWith(validFileTypeBin) || file.name.endsWith(validFileTypeSrec) || file.name.endsWith(validFileTypeA00))) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: {
          ...prevData[name],
          emptyError: false,
          fileTypeError: true,
          value: null
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: {
          ...prevData[name],
          emptyError: false,
          fileTypeError: false,
          value: file
        },
      }));
    }
  };
  const handleFileRemove = () => {
    setFormData((prevData) => ({
      ...prevData,
      software_file:{
        ...prevData["software_file"],
        emptyError: true,
        fileTypeError: false,
        value: null
      }
    }));
  };

  const handleSubmit = async () => {
    if (formData.uploading.value) return;

    if (!validInputFields()) return;

    // setFormData((prevData) => ({
    //   ...prevData,
    //   uploading: true,
    // }));

    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));

      onSubmit(formData);

      // setFormData((prevData) => ({
      //   ...prevData,
      //   uploading: false,
      // }));

      onClose();
    } catch (error) {
      console.error("Error creating/updating software:", error);
      setFormData((prevData) => ({
        ...prevData,
        uploading: false,
      }));
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFormData((prevData) => ({
      ...prevData,
      software_file: file,
    }));
  };

  const checkNoEmptyInput = (fieldName, fieldValue) => {

    const isValid =!(fieldValue === "" || !fieldValue);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]:{
        ...prevFormData[fieldName],
        value: fieldValue,
        emptyError:!isValid
      }
    }));

    return isValid;
  }

  const validInputFields = () => {
    let isValid = true;
    const formFields = Object.keys(formData);

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formData[currentField].value;

      if(formData[currentField.toString()].emptyErrorMessage && (currentValue === "" || !currentValue)){
        isValid = false;
        checkNoEmptyInput(currentField, currentValue)
      }

      if(formData[currentField.toString()].fileTypeErrorMessage && formData[currentField.toString()].fileTypeError) {
        isValid = false;
      }
    }

    return isValid;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      PaperComponent={DraggableDialog}
      onDrop={handleDrop}
      onDragOver={(event) => event.preventDefault()}
    >
      <DialogTitle className="drag-handle">{title}</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          name="name"
          value={formData.name.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("name", formData.name.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.name.emptyError}
          helperText={formData.name.emptyError?emptyErrorMessage:""}
        />
        <TextField
          label="Version"
          name="version"
          value={formData.version.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("version", formData.version.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.version.emptyError}
          helperText={formData.version.emptyError?emptyErrorMessage:""}
        />

        <FormControl required fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="software_type-lbl">Softwaretyp</InputLabel>
            <Select
                fullWidth
                labelId="software_type-lbl"
                id="software_type"
                label="Softwaretyp"
                name="software_type"
                value={formData.software_type.value}
                onChange={handleChange}
              >
                  <MenuItem value={"EPLC-Software"}>EPLC-Software</MenuItem>
                  <MenuItem value={"EPLC-Bootloader"}>EPLC-Bootloader</MenuItem>
                  <MenuItem value={"Software"}>Software</MenuItem>
              </Select>
        </FormControl>
        
        <FormControl
          fullWidth
          error={formData.software_file.emptyError || formData.software_file.fileTypeError}
          sx={{ marginBottom: 2 }}
        >
          {formData.software_file.value ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={handleFileRemove}
                color="primary"
                disabled={formData.uploading.value}
              >
                <DeleteIcon />
              </IconButton>
              {formData.uploading.value ? (
                <CircularProgress size={24} sx={{ marginRight: 1 }} />
              ) : (
                <CheckIcon color="primary" sx={{ marginRight: 1 }} />
              )}
              {formData.uploading.value
                ? "Wird hochgeladen..."
                : formData.software_file.value === softwareFile ? "Software" : `Datei: ${formData.software_file.value.name}`}
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                id="file-input"
                name="software_file"
                type="file"
                accept={[...[validFileTypeBin, validFileTypeSrec, validFileTypeA00]].toString()}
                style={{ display: "none" }}
                onChange={handleFileUpload}
                disabled={formData.uploading.value}
              />
                <IconButton sx={{ marginRight: 1 }}>
                <label htmlFor="file-input">
                  <CloudUploadIcon 
                    color="primary"
                    disabled={formData.uploading.value}
                    sx={{"&:hover":{cursor: "pointer"}}}
                  />
                </label>
                </IconButton>
              {formData.uploading.value ? "Wird hochgeladen..." : "Datei hochladen"}
            </div>
          )}
        <FormHelperText>{formData.software_file.emptyError?emptyErrorMessage:formData.software_file.fileTypeError?fileTypeErrorMessage:""}</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Abbrechen
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {formData.uploading.value
            ? "Wird hochgeladen..."
            : software
            ? "Aktualisieren"
            : "Erstellen"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SoftwareForm;
