function validation(values) {
  let error = {};

  const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const password_pattern = /^(?=.\d)(?=.[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,}$/;

  if (values.email === "") {
    error.email = "Email sollte nicht leer sein";
  } else if (!email_pattern.test(values.email)) {
    error.email = "Email stimmt nicht überein";
  } else {
    error.email = "";
  }
  if (values.password === "") {
    error.password = "Passwort sollte nicht leer sein";
  } else if (!password_pattern.test(values.password)) {
    error.password = "Passwort stimmt nicht überein";
  } else {
    error.password = "";
  }
  return error;
}
export default validation;
