import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  Box,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import OrderEditForm from "../order/orderEditForm";
import { Snackbar } from "@mui/material";

const OrderPositionForm = ({ order, fetchOrders }) => {
  const emptyErrorMessage = "Dies ist ein Pflichtfeld";
  const initialPositionValue = {
    position_id: {
      value: "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    order_id: {
      value: order.order_id,
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    article_number: {
      value: "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    article_stand: {
      value: "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    standard_software_id: {
      value: "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    customer_software_id: {
      value: "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    eplc_software_id: {
      value: null
    },
    parameter_set_id: {
      value: "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    case_type: {
      value: "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    label_type: {
      value: "Stercom",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    amount: {
      value: 0,
    }
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [orderPositions, setOrderPositions] = useState([]);
  const [articleTypes, setArticleTypes] = useState([]);
  const [newPosition, setNewPosition] = useState(initialPositionValue);
  const [customer, setCustomer] = useState(null);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [software, setSoftware] = useState([]);
  const [eplcSoftware, setEplcSoftware] = useState([]);
  const [parameterSets, setParameterSets] = useState([]);
  const [eplcBoardSelectLabel, setEplcBoardSelectLabel] = useState("");
  const [eplcBoardDisabled, setEplcBoardDisabled] = useState(false);

  useEffect(() => {
    fetchArticleTypes();
    fetchOrderPositions();
    fetchSoftware();
    fetchParameterSets();
    setNewPosition(initialPositionValue);
  }, [order]);

  useEffect(() => {
    if (order.customer_id) {
      fetchCustomer();
    }
  }, [order.customer_id]);

  useEffect(() => {
    if (eplcBoardDisabled) {
      setEplcBoardSelectLabel("Artikel hat kein EPLC Board");
    } else {
      setEplcBoardSelectLabel("EPLC Software");
    }
  }, [eplcBoardDisabled]);

  const fetchOrderPositions = async () => {
    try {
      const response = await axios.get(`/api/orderPositions/${order.order_id}`);
      setOrderPositions(response.data);
    } catch (error) {
      console.error("Error fetching order positions:", error);
    }
  };

  const fetchArticleTypes = async () => {
    try {
      const response = await axios.get("/api/articleTypes");
      setArticleTypes(response.data);
    } catch (error) {
      console.error("Error fetching article types:", error);
    }
  };

  const fetchSoftware = async () => {
    try {
      const response = await axios.get("/api/software");
      const standardAndCustomerSoftware = response.data.filter(software => software.eplc_bootloader === 0 && software.eplc_software === 0);
      const eplcSW = response.data.filter(software => software.eplc_software === 1);
      setSoftware(standardAndCustomerSoftware);
      setEplcSoftware(eplcSW);
    } catch (error) {
      console.error("Error fetching software:", error);
    }
  }

  const fetchParameterSets = async () => {
    try {
      const response = await axios.get("/api/parameterSets");
      setParameterSets(response.data);
    } catch (error) {
      console.error("Error fetching parameter set:", error);
    }
  }

  const fetchCustomer = async () => {
    try {
      const response = await axios.get(
        `/api/customersWorkflow/${order.customer_id}`
      );
      setCustomer(response.data);
    } catch (error) {
      console.error("Error fetching customer:", error);
    }
  };

  const handleOrderSubmit = async (updatedOrder) => {
    try {
      await axios.put(
        `/api/ordersWorkflow/${updatedOrder.order_id}`,
        updatedOrder
      );
      fetchOrders();
      fetchOrderPositions();
      handleCloseEditForm();
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const handleDeleteOrder = async () => {
    try {
      await axios.delete(`/api/ordersWorkflow/${order.order_id}`);
      fetchOrders();
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  const handleAddPosition = async () => {

    if (!validInputFields()) return;

    try {
      let flatNewPosition = {};
      Object.keys(newPosition).map((key) => flatNewPosition[key] = newPosition[key].value);
      await axios.post("/api/orderPositions", flatNewPosition);
      setNewPosition(initialPositionValue);
      setSnackbarMessage("Position wurde erfolgreich erstellt!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error creating order position:", error);
    }
  };

  const handleDeletePosition = async (positionId) => {
    try {
      await axios.delete(`/api/orderPositions/${positionId}`);
      setOrderPositions((prevPositions) =>
        prevPositions.filter((position) => position.position_id !== positionId)
      );
    } catch (error) {
      console.error("Error deleting order position:", error);
    }
  };

  const handleEditOrder = () => {
    setIsEditFormOpen(true);
  };

  const handleCloseEditForm = () => {
    setIsEditFormOpen(false);
  };

  const uniqueListOfKeyValues = (arr, key) => {
    return [...new Set(arr.map(item => item[key]))]
  }

  const uniqueListOfArticleNumbersByStand = (stand) => {
    const filteredArticles = articleTypes.filter(article => article.article_stand === stand);
    return uniqueListOfKeyValues(filteredArticles, "article_number");
  }

  const uniqueListOfArticleStandByNumber = (articleNumber) => {
    const filteredArticles = articleTypes.filter(article => article.article_number === articleNumber);
    return uniqueListOfKeyValues(filteredArticles, "article_stand");
  }

  const uniqueListOfParameterSetsByCustomer = (parameterSets, customerId) => {
    const filteredParameterSets = parameterSets.filter(set =>(set.customer_id === customerId)); 

    return filteredParameterSets;
  }

  const listOfArticleNumbers = () => {
    if (newPosition.article_stand.value) {
      return uniqueListOfArticleNumbersByStand(newPosition.article_stand.value);
    }
    return uniqueListOfKeyValues(articleTypes, "article_number");
  }

  const listOfArticleStand = () => {
    if (newPosition.article_number.value) {
      return uniqueListOfArticleStandByNumber(newPosition.article_number.value);
    }
    return uniqueListOfKeyValues(articleTypes, "article_stand");
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;

    if(newPosition[name.toString()].emptyErrorMessage) {
      checkNoEmptyInput(name, value);
    } else {
      setNewPosition({
        ...newPosition,
        [name]:{
          ...newPosition[name],
          value
        }
      })
    }
  };

  const checkNoEmptyInput = (fieldName, fieldValue) => {

    const isValid = !(fieldValue === "");

    setNewPosition((prevNewPosition) => ({
      ...prevNewPosition,
      [fieldName]:{
        ...prevNewPosition[fieldName],
        value: fieldValue,
        emptyError:!isValid
      }
    }));

    return isValid;
  }

  const validInputFields = () => {
    let isValid = true;
    const formFields = Object.keys(newPosition);

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = newPosition[currentField].value;

      if(newPosition[currentField.toString()].emptyErrorMessage && currentValue === ""){
        isValid = false;
        checkNoEmptyInput(currentField, currentValue)
      }
    }

    return isValid;
  }

  const checkIfArticleHasEPLCBoard = async (articleNumber, articleStand) => {
    const response = await axios.get(`/api/articleTypes/${articleNumber}/${articleStand}`);
    const articleType = response.data;
    
    setNewPosition((prevNewPosition) => ({
      ...prevNewPosition,
      case_type: {
        ...prevNewPosition.case_type,
        value: articleType.case_type,
      },
    }));

    if (articleType.compatible_eplc_board_sn && articleType.compatible_eplc_board_sn !== "") {
      setEplcBoardDisabled(false);
    } else {
      setEplcBoardDisabled(true);
    }
  }

  return (
    <Card sx={{ marginTop: 2 }}>
      <CardContent>
        <Typography variant="h5">Bestelldetails</Typography>
        <Typography variant="body1">Bestellnummer: {order.order_id}</Typography>
        <Typography variant="body1">
          Bestelldatum: {formatDate(order.order_date)}
        </Typography>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="body1">
              Bestellart: {order.order_type}
            </Typography>
            {customer && (
              <Typography variant="body1">Kunde: {customer.name}</Typography>
            )}
          </Grid>
          {/*
          <Grid item>
            <IconButton onClick={handleEditOrder}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleDeleteOrder}>
              <DeleteIcon />
            </IconButton>
          </Grid>
            */}
        </Grid>
      </CardContent>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Position hinzufügen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <TextField
              required
              label="Positionsnummer"
              name="position_id"
              value={newPosition.position_id.value}
              onChange={handleChange}
              onBlur={() => checkNoEmptyInput("position_id", newPosition.position_id.value)}
              sx={{ marginBottom: 2 }}
              error={newPosition.position_id.emptyError}
              helperText={newPosition.position_id.emptyError?emptyErrorMessage:""}
            />
             <FormControl
              required
              sx={{ marginBottom: 2 }}
              error={newPosition.article_number.emptyError}
              >
              <InputLabel id="article-number">Artikelnummer</InputLabel>
              <Select
                labelId="article-number"
                id="article_number"
                label="Artikelnummer"
                name="article_number"
                value={newPosition.article_number.value}
                onChange={
                  async (event) => {
                    handleChange(event);
                    await checkIfArticleHasEPLCBoard(newPosition.article_number.value, newPosition.article_stand.value);
                  }
                }
                onBlur={() => checkNoEmptyInput("article_number", newPosition.article_number.value)}
              >
                {listOfArticleNumbers().map((articleNumber) => (
                  <MenuItem
                    key={articleNumber}
                    value={articleNumber}
                  >
                    {articleNumber}
                  </MenuItem>
                ))}
              </Select>
            <FormHelperText>{newPosition.article_number.emptyError?emptyErrorMessage:""}</FormHelperText>
            </FormControl>
            <FormControl
              required
              sx={{ marginBottom: 2 }}
              error={newPosition.article_stand.emptyError}
            >
              <InputLabel id="article-stand">Artikelstand</InputLabel>
              <Select
                labelId="article-stand"
                id="article_stand"
                label="Artikelstand"
                name="article_stand"
                value={newPosition.article_stand.value}
                onChange={
                  async (event) => {
                    handleChange(event);
                    await checkIfArticleHasEPLCBoard(newPosition.article_number.value, event.target.value);
                  }
                }
                onBlur={() => checkNoEmptyInput("article_stand", newPosition.article_stand.value)}
              >
                {listOfArticleStand().map((articleStand) => (
                  <MenuItem
                    key={articleStand}
                    value={articleStand}
                  >
                    {articleStand}
                  </MenuItem>
                ))}
              </Select>
            <FormHelperText>{newPosition.article_stand.emptyError?emptyErrorMessage:""}</FormHelperText>
            </FormControl>
            <FormControl
              required
              sx={{ marginBottom: 2 }}
              error={newPosition.standard_software_id.emptyError}
            >
              <InputLabel id="standard-software-id">Standard Software</InputLabel>
              <Select
                labelId="standard-software-id"
                id="standard-software_id"
                label="Standard Software"
                name="standard_software_id"
                value={newPosition.standard_software_id.value}
                onChange={handleChange}
                onBlur={() => checkNoEmptyInput("standard_software_id", newPosition.standard_software_id.value)}
              >
                {software.map((s) => (
                  <MenuItem
                    key={s.software_id}
                    value={s.software_id}
                  >
                    {s.software_id + " - " + s.name + " - " + s.version}
                  </MenuItem>
                ))}
              </Select>
            <FormHelperText>{newPosition.standard_software_id.emptyError?emptyErrorMessage:""}</FormHelperText>
            </FormControl>
            <FormControl
              required
              sx={{ marginBottom: 2 }}
              error={newPosition.customer_software_id.emptyError}
            >
              <InputLabel id="customer-software-id">Kunden Software</InputLabel>
              <Select
                labelId="customer-software-id"
                id="customer_software_id"
                label="Kunden Software"
                name="customer_software_id"
                value={newPosition.customer_software_id.value}
                onChange={handleChange}
                onBlur={() => checkNoEmptyInput("customer_software_id", newPosition.customer_software_id.value)}
              >
                {software.map((s) => (
                  <MenuItem
                    key={s.software_id}
                    value={s.software_id}
                  >
                    {s.software_id + " - " + s.name + " - " + s.version}
                  </MenuItem>
                ))}
              </Select>
            <FormHelperText>{newPosition.customer_software_id.emptyError?emptyErrorMessage:""}</FormHelperText>
            </FormControl>
            <FormControl
              sx={{ marginBottom: 2 }}
            >
              <InputLabel id="eplc-software-id">{eplcBoardSelectLabel}</InputLabel>
              <Select
                labelId="eplc-software-id"
                id="eplc-software_id"
                label={eplcBoardSelectLabel}
                name="eplc_software_id"
                value={eplcBoardDisabled ? "" : newPosition.eplc_software_id.value}
                onChange={handleChange}
                disabled={eplcBoardDisabled}
              >
                {eplcSoftware.map((s) => (
                  <MenuItem
                    key={s.software_id}
                    value={s.software_id}
                  >
                    {s.software_id + " - " + s.name + " - " + s.version}
                  </MenuItem>
                ))}
                <MenuItem value={null}><br/></MenuItem>
              </Select>
            <FormHelperText>{newPosition.standard_software_id.emptyError?emptyErrorMessage:""}</FormHelperText>
            </FormControl>
            <FormControl
              required
              sx={{ marginBottom: 2 }}
              error={newPosition.parameter_set_id.emptyError}
            >
              <InputLabel id="parameter-set-id">Parametersatz</InputLabel>
              <Select
                labelId="parameter-set-id"
                id="parameter_set_id"
                label="Parameter Set ID"
                name="parameter_set_id"
                value={newPosition.parameter_set_id.value}
                onChange={handleChange}
                onBlur={() => checkNoEmptyInput("parameter_set_id", newPosition.parameter_set_id.value)}
              >
                {uniqueListOfParameterSetsByCustomer(parameterSets, order.customer_id).map((ps) => (
                  <MenuItem
                    key={ps.parameter_set_id}
                    value={ps.parameter_set_id}
                  >
                    {ps.parameter_set_id + " - " + ps.name}
                  </MenuItem>
                ))}
              </Select>
            <FormHelperText>{newPosition.parameter_set_id.emptyError?emptyErrorMessage:""}</FormHelperText>
            </FormControl>
            <TextField
              label="Menge"
              name="amount"
              value={newPosition.amount.value}
              onChange={handleChange}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Deckeltyp"
              name="case_type"
              value={newPosition.case_type.value}
              onChange={handleChange}
              fullWidth
              sx={{ marginBottom: 2 }}
              disabled
            />
            <FormControl
              required
              sx={{ marginBottom: 2 }}
            >
              <InputLabel id="label-type">Etikett Typ</InputLabel>
              <Select
                labelId="label-type"
                id="label-type"
                label="Etikett Typ"
                name="label_type"
                value={newPosition.label_type.value}
                onChange={handleChange}
                onBlur={() => checkNoEmptyInput("label_type", newPosition.label_type.value)}
                error={newPosition.label_type.emptyError}
                helperText={newPosition.label_type.emptyError?emptyErrorMessage:""}
              >
                  <MenuItem value={"Deutz"}>Deutz</MenuItem>
                  <MenuItem value={"Stercom"}>Stercom</MenuItem>
              </Select>
              <FormHelperText>{newPosition.label_type.emptyError?emptyErrorMessage:""}</FormHelperText>
            </FormControl>
            <Button
              onClick={handleAddPosition}
              disabled={!newPosition.article_number || !newPosition.article_stand || !newPosition.amount}
              variant="contained"
              color="primary"
            >
              Position hinzufügen
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
      {isEditFormOpen && (
        <OrderEditForm
          isOpen={isEditFormOpen}
          onClose={handleCloseEditForm}
          onSubmit={handleOrderSubmit}
          title="Bestellung bearbeiten"
          order={order}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
    />
    </Card>
  );
};

export default OrderPositionForm;
