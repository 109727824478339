import React, { useState, useEffect } from "react";
import axios from "axios";
import ParameterSetForm from "./parameterSetForm";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  TextField,
  Snackbar,
  Box,
  TablePagination,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from '@mui/icons-material/Download';
import LockIcon from '@mui/icons-material/Lock';

const ParameterSet = () => {
  const [parameterSets, setParameterSets] = useState([]);
  const [customers, setCustomers] = useState(null);
  const [filteredParameterSets, setFilteredParameterSets] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedParameterSetData, setSelectedParameterSetData] = useState(null);
  const [selectedParameterSetXML1, setSelectedParameterSetXML1] = useState(null);
  const [selectedParameterSetXML2, setSelectedParameterSetXML2] = useState(null);
  const [sortParameterSets, setSortParameterSets] = useState("asc");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsOptions = [5, 10, 15, 20];
  const [selectedColumn, setSelectedColumn] = useState("");
  const [checkboxPopupOpen, setCheckboxPopupOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState({
    parameter_set_id: true,
    name: true,
    customer_id: true,
    customer_name: true,
    parameter_xml_1: true,
    parameter_xml_2: true
  });
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [action, setAction] = useState(null); // To store the current action ('update' or 'delete')
  const [currentParameterSet, setCurrentParameterSet] = useState(null); // To store the current parameter set being edited or deleted

  const columnNames = {
    parameter_set_id: "Parametersatz ID",
    name: "Name",
    customer_id: "Kunde ID",
    customer_name: "Kunde Name",
    parameter_xml_1: "XML 1",
    parameter_xml_2: "XML 2"
  };

  useEffect(() => {
    fetchParameterSetsWithoutFile();
    fetchCustomers();
  }, []);

  const fetchParameterSetsWithoutFile = async () => {
    try {
      const response = await axios.get("/api/parameterSets");
      setParameterSets(response.data);
      setFilteredParameterSets(response.data);
    } catch (error) {
      console.error("Error fetching parameter set:", error);
    }
  };

  const fetchSelectedXML1 = async (id) => {
    try {
      const response = await axios.get(`/api/parameterSets/xml1/${id}`);
      setSelectedParameterSetXML1(response);
      return response;
    } catch (error) {
      console.error("Error fetching xml1:", error);
    }
  };

  const fetchSelectedXML2 = async (id) => {
    try {
      const response = await axios.get(`/api/parameterSets/xml2/${id}`);
      setSelectedParameterSetXML2(response);
      return response;
    } catch (error) {
      console.error("Error fetching xml2:", error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await axios.get("/api/customersWorkflow");
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const getCustomerName = (customerId) => {
    if (customers && customerId) {
      return customers.filter(c => c.customer_id === customerId)[0].name;
    }

    return "";
  }

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const searchColumns = Object.keys(visibleColumns);
    setFilteredParameterSets(
      parameterSets.filter((sw) =>
        searchColumns.some((column) =>
          sw[column].toString().toLowerCase().includes(searchValue)
        )
      )
    );
  };

  const handleCheckboxChange = (event) => {
    setVisibleColumns({
      ...visibleColumns,
      [event.target.name]: event.target.checked,
    });
  };

  const handleColumnSelect = (event) => {
    setSelectedColumn(event.target.value);
  };

  const handleSort = (field) => {
    const sortedParameterSets = [...filteredParameterSets].sort((a, b) => {
      if (sortedParameterSets === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });

    setFilteredParameterSets(sortedParameterSets);
    setSortParameterSets(sortParameterSets === "asc" ? "desc" : "asc");
  };

  const createParameterSet = async (parameterSetData) => {
    let flatParameterSetData = {};
    Object.keys(parameterSetData).map((key) => flatParameterSetData[key] = parameterSetData[key].value);

    const formDataToSubmit = new FormData();
    formDataToSubmit.append("parameter_set_id", flatParameterSetData.parameter_set_id);
    formDataToSubmit.append("name", flatParameterSetData.name);
    formDataToSubmit.append("customer_id", flatParameterSetData.customer_id);
    formDataToSubmit.append("parameter_xml_1", flatParameterSetData.parameter_xml_1);
    formDataToSubmit.append("parameter_xml_2", flatParameterSetData.parameter_xml_2);
    formDataToSubmit.append("customer_name", flatParameterSetData.customer_name);

    try {
      const response = await axios.post("/php-uploader/index.php", formDataToSubmit);
      // showSnackbar(
      //   `Parametersatz mit ID ${response.id} wurde erfolgreich zu Kunde ${flatParameterSetData.customer_id} hinzugefügt.`
      // );
      fetchParameterSetsWithoutFile();
    } catch (error) {
      console.error("Error creating parameter set:", error);
    }
  };

  const verifyPassword = (inputPassword) => {
    // Add your password verification logic here
    // For simplicity, let's assume the correct password is 'password123'
    return inputPassword === 'password123';
  };

  const handlePasswordSubmit = () => {
    if (verifyPassword(password)) {
      if (action === 'update') {
        setOpenEdit(true);
      } else if (action === 'delete') {
        deleteParameterSet(currentParameterSet.parameter_set_id);
      }
      setPasswordDialogOpen(false);
      setPassword("");
      setCurrentParameterSet(null);
      setAction(null);
    } else {
      showSnackbar("Incorrect password. Please try again.");
    }
  };

  const updateParameterSet = async (parameterSetData) => {
    let flatParameterSetData = {};
    Object.keys(parameterSetData).map((key) => flatParameterSetData[key] = parameterSetData[key].value);

    const formDataToSubmit = new FormData();
    formDataToSubmit.append("parameter_set_id", flatParameterSetData.parameter_set_id);
    formDataToSubmit.append("name", flatParameterSetData.name);
    formDataToSubmit.append("customer_id", flatParameterSetData.customer_id);
    formDataToSubmit.append("parameter_xml_1", flatParameterSetData.parameter_xml_1);
    formDataToSubmit.append("parameter_xml_2", flatParameterSetData.parameter_xml_2);
    formDataToSubmit.append("customer_name", flatParameterSetData.customer_name);

    try {
      const response = await axios.post("/php-uploader/index.php", formDataToSubmit);

      await fetchParameterSetsWithoutFile();
      showSnackbar(
         `Parametersatz mit ID ${flatParameterSetData.parameter_set_id} wurde erfolgreich aktualisiert.`
      );
    } catch (error) {
      console.error("Error updating parameter set:", error);
    }
  };

  const deleteParameterSet = async (id) => {
    try {
      await axios.delete(`/api/parameterSets/${id}`);
      setFilteredParameterSets(filteredParameterSets.filter((ps) => ps.parameter_set_id !== id));
      showSnackbar(`Parametersatz mit ID ${id} wurde erfolgreich gelöscht.`);
      fetchParameterSetsWithoutFile();
    } catch (error) {
      console.log("Delete parameter set failed", error);
      showSnackbar(`Parametersatz mit ID ${id} konnte nicht gelöscht werden. ${error.message}`);
    }
  };

  const downloadXMLFile = async (id, xml1Or2) => {
    try {
      const response = await axios.get(`/api/parameterSets/${xml1Or2}/${id}`);
      const xmlFile = xml1Or2 === "xml1" ? Uint8Array.from(response.data.parameter_xml_1.data) : Uint8Array.from(response.data.parameter_xml_2.data)
      const url = window.URL.createObjectURL(new Blob([xmlFile.buffer], {type: "application/octet-stream"}));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${xml1Or2}_${id}.xml`);
      document.body.appendChild(link);
      link.click();
      showSnackbar(`${xml1Or2} für Parametersatz mit ID ${id} wurde erfolgreich heruntergeladen.`);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading ${xml1Or2}:", error);
      showSnackbar(`Fehler beim Herunterladen von ${xml1Or2} für Parametersatz mit ID ${id}.`);
    }
  };

  const handleDelete = (parameterSet) => {
    setCurrentParameterSet(parameterSet);
    setAction('delete');
    setPasswordDialogOpen(true);
  };

  const handleUpdate = async (parameterSet) => {
    setCurrentParameterSet(parameterSet);
    setAction('update');
    setPasswordDialogOpen(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openCheckboxPopup = () => {
    setCheckboxPopupOpen(true);
  };

  const closeCheckboxPopup = () => {
    setCheckboxPopupOpen(false);
  };

  return (
    <div>
      <Box px={2} pt={2} sx={{ paddingLeft: "240px", paddingTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Suchen"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Grid>

          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setOpenCreate(true)}
            style={{ marginTop: "20px", marginLeft: "20px" }}
          >
            Parametersatz hinzufügen
          </Button>
          <IconButton
            onClick={openCheckboxPopup}
            style={{ marginLeft: "10px" }}
          >
            <SettingsIcon />
          </IconButton>
        </Grid>
        <TableContainer component={Paper} style={{ padding: "30px", marginTop: "10px" }}>
          <Table>
            <TableHead>
              <TableRow>
                {visibleColumns.parameter_set_id && (
                  <TableCell onClick={() => handleSort("parameter_set_id")}>
                    ID
                  </TableCell>
                )}
                {visibleColumns.name && (
                  <TableCell onClick={() => handleSort("name")}>Name</TableCell>
                )}
                {visibleColumns.customer_id && (
                  <TableCell onClick={() => handleSort("customer_id")}>
                    Kunden ID
                  </TableCell>
                )}
                {visibleColumns.customer_name && (
                  <TableCell onClick={() => handleSort("customer_name")}>
                    Kundenname
                  </TableCell>
                )}
                {visibleColumns.parameter_xml_1 && (
                  <TableCell onClick={() => handleSort("paramter_xml_1")}>
                    XML 1
                  </TableCell>
                )}
                {visibleColumns.parameter_xml_2 && (
                  <TableCell onClick={() => handleSort("paramter_xml_2")}>
                    XML 2
                  </TableCell>
                )}
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredParameterSets
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((ps) => (
                  <TableRow key={ps.parameter_set_id}>
                    {visibleColumns.parameter_set_id && (
                      <TableCell>{ps.parameter_set_id}</TableCell>
                    )}
                    {visibleColumns.name && <TableCell>{ps.name}</TableCell>}
                    {visibleColumns.customer_id && (
                      <TableCell>{ps.customer_id}</TableCell>
                    )}
                    {visibleColumns.customer_name && (
                      <TableCell>{getCustomerName(ps.customer_id)}</TableCell>
                    )}
                    {visibleColumns.parameter_xml_1 && (
                      <TableCell>
                        <IconButton onClick={() => downloadXMLFile(ps.parameter_set_id, "xml1")}>
                          <DownloadIcon />
                        </IconButton>
                      </TableCell>
                    )}
                    {visibleColumns.parameter_xml_2 && (
                      <TableCell>
                        <IconButton onClick={() => downloadXMLFile(ps.parameter_set_id, "xml2")}>
                          <DownloadIcon />
                        </IconButton>
                      </TableCell>
                    )}
                    <TableCell>
                      <Button
                        onClick={async () => {
                          setSelectedParameterSetData(ps);
                          await fetchSelectedXML1(ps.parameter_set_id);
                          await fetchSelectedXML2(ps.parameter_set_id);
                          handleUpdate(ps);
                        }}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        onClick={() => {
                          handleDelete(ps);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filteredParameterSets.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Zeilen pro Seite:"
          rowsPerPageOptions={rowsOptions}
          SelectProps={{
            inputProps: { "aria-label": "Zeilen pro Seite" },
          }}
        />
        < ParameterSetForm
          isOpen={openCreate}
          onClose={handleCloseCreate}
          onSubmit={createParameterSet}
          title="Parametersatz erstellen"
        />
        {selectedParameterSetData && selectedParameterSetXML1 && selectedParameterSetXML2 && (
          <ParameterSetForm
            isOpen={openEdit}
            onClose={() => {
              setSelectedParameterSetData(null);
              setSelectedParameterSetXML1(null);
              setSelectedParameterSetXML2(null);
              handleCloseEdit();
            }}
            onSubmit={updateParameterSet}
            title="Parametersatz bearbeiten"
            parameterSet={selectedParameterSetData}
            xml1={selectedParameterSetXML1}
            xml2={selectedParameterSetXML2}
          />
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        />
      </Box>
      {/* Checkbox Popup */}
      <Dialog open={checkboxPopupOpen} onClose={closeCheckboxPopup}>
        <DialogTitle>Spalten auswählen</DialogTitle>
        <DialogContent>
          {Object.keys(visibleColumns).map((column) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleColumns[column]}
                  onChange={handleCheckboxChange}
                  name={column}
                />
              }
              label={columnNames[column]}
              key={column}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCheckboxPopup} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
      {/* Password Dialog */}
      <Dialog open={passwordDialogOpen} onClose={() => setPasswordDialogOpen(false)}>
        <DialogTitle>Enter Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPasswordDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePasswordSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ParameterSet;
