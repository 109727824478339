import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Avatar,
  Grid,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Input,
  Container,
} from "@mui/material";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

function Profile() {
  const [userData, setUserData] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/api/user/profile", {
          withCredentials: true,
        });
        setUserData(response.data);
      } catch (error) {
        console.log("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const getUserRole = (role) => {
    switch (role) {
      case 1:
        return "Tester";
      case 2:
        return "Moderator";
      case 3:
        return "Abteilungsleiter";
      case 4:
        return "Admin";
      default:
        return "Unbekannt";
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append("avatar", selectedFile);

        const response = await axios.post("/api/user/upload-avatar", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        });

        setUserData({
          ...userData,
          profile_picture: response.data.imageUrl,
        });

        handleClose();
      } catch (error) {
        console.log("Error uploading image:", error);
      }
    }
  };

  const handleAvatarChange = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("profile_picture", file);

    try {
      await axios.post("/api/user/update-profile-picture", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      window.location.reload();
    } catch (error) {
      console.log("Error uploading profile picture:", error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 4,
        }}
      >
        {userData && (
          <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
            <Box sx={{ textAlign: "center", mb: 4 }}>
              <Box sx={{ display: "inline-block" }}>
                <Avatar
                  alt={userData.firstname + " " + userData.lastname}
                  src={userData.profile_picture}
                  sx={{ width: 100, height: 100, mb: 2 }}
                />
              </Box>
              <Typography variant="h5" sx={{ mb: 1 }}>
                {userData.firstname} {userData.lastname}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                sx={{ mb: 2 }}
              >
                {getUserRole(userData.role)}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="subtitle2">E-Mail:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{userData.email}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Telefonnummer:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{userData.telnr}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Abteilung:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{userData.department}</Typography>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Box>
    </Container>
  );
}

export default Profile;
