import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Box,
  Grid,
  TextField,
  Paper,
  IconButton,
  DialogActions,
  InputAdornment,
  TableSortLabel,
  Button,
  TablePagination,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import VerifiedIcon from "@mui/icons-material/Verified";
import JobListForm from "./jobListForm";
import JobListInformation from "./jobListInformation";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

function JobsList() {
  const [jobs, setJobs] = useState([]);
  const [toggledJob, setToggledJob] = useState([]);
  const [toggled, setToggled] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState({
    job_id: true,
    tester: true,
    priority: true,
    due_date: true,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowsOptions = [5, 10, 15, 20];
  const [selectedTask, setSelectedTask] = useState(null);
  const [open, setOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [scannerTwoCaseId, setScannerTwoCaseId] = useState("");
  const [scannerTwoDeviceId, setScannerTwoDeviceId] = useState("");
  const [fullScreen, setFullScreen] = useState(true);
  const [jobListProps, setJobListProps] = useState(null);
  const scannerTwoRegexSN = new RegExp("^Shift\\\$Shift\\\$Shift\\\$([0-9]){6}$");
  const scannerTwoRegexCaseId = new RegExp("^Shift\\\$Shift\\\$Shift\\\$([0-9]){4}$");

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    handleFullScreenClick();
  }, []);

  /*
   * Listens to keydown events for 3000 ms and then checks, 
   * if the input matches the format of the second scanner
   */
  useEffect(() => {
    let result ="";

    const onKeyDown = (event) => {
      setTimeout(async () => {
        if(scannerTwoRegexSN.test(result) && result.length === 24 && !scannerTwoCaseId) {
          setScannerTwoDeviceId(result.slice(18));
        }
        if(scannerTwoRegexCaseId.test(result) && result.length === 22 && scannerTwoDeviceId) {
          const caseId = result.slice(18);
          if (!await caseIdCompatible(scannerTwoDeviceId, caseId)) {
            showSnackbar(`Case ID ${caseId} not compatible`);
            setScannerTwoDeviceId(null);
            setScannerTwoCaseId(null);
            return;
          };
          setScannerTwoCaseId(caseId);
        }
        if(scannerTwoRegexSN.test(result) 
          && result.length === 24
          && scannerTwoCaseId 
          && scannerTwoDeviceId === result.slice(18)) {
          updateDevice(scannerTwoDeviceId, scannerTwoCaseId);
          postScanResults2(scannerTwoDeviceId);
          setScannerTwoDeviceId(null);
          setScannerTwoCaseId(null);
        }
        result="";
      }, 1000);

      result = result + event.key;
    };

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    }
  });

  useEffect(() => {
    const filteredJobs = filterJobs(jobs);
    setFilteredJobs(filteredJobs);
  }, [jobs, searchTerm, toggled]);

  useEffect(() => {
    if(selectedTask && selectedTask.state === 0) {
      setOpen(true); //Open Scanvorgang
    } else if (selectedTask && selectedTask.state === 2) {
      setInfoOpen(true); //Open Task Info
    }
  }, [selectedTask]);

  const fetchTaskByDeviceId = async (deviceId) => {
    try {
      const response = await axios.get(`/api/tasks/device/${deviceId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  const fetchJobs = async () => {
    try {
      const jobsResponse = await axios.get("/api/jobs");
      const tasksResponse = await axios.get("/api/tasks");
      const usersResponse = await axios.get("/api/admin/users");
      const customersResponse = await axios.get("/api/customersWorkflow");
      const articleTypesResponse = await axios.get("/api/articleTypes");
  
      const jobsWithTasks = await Promise.all(
        jobsResponse.data.map(async (job) => {
          const tester = usersResponse.data.find(
            (user) => user.id === job.tester
          );
  
          const tasksWithAdditionalData = await Promise.all(
            tasksResponse.data
              .filter((task) => task.job_id === job.job_id)
              .map(async (task) => {
                const articleType = articleTypesResponse.data.find(
                  (article) =>
                    article.article_number === task.article_number &&
                    article.article_stand === task.article_stand
                );
                const customer = customersResponse.data.find((customer) => customer.id === task.customer_id);                
                const vertrieb = customersResponse.data.find((vert) => vert.customer_id === customer.distribution);

                return {
                  ...task,
                  name: articleType ? articleType.name : "",
                  power_plug: articleType ? articleType.power_plug : "",
                  kunde: customer ? customer.name : "",
                  vertrieb: vertrieb ? vertrieb.name : "",
                };
              })
          );
  
          return {
            ...job,
            tester: tester ? `${tester.firstname} ${tester.lastname}` : "",
            tasks: tasksWithAdditionalData,
            open: false,
          };
        })
      );
  
      setJobs(jobsWithTasks);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  const fetchOrderPosition = async (orderId, positionId) => {
    try {
      const response = await axios.get(`/api/orderPositions/order/${orderId}/position/${positionId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };
  const fetchTestRuns = async (sn) => {
    try {
      const response = await axios.get(`/api/testresults/serialnumber/${sn}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  const sortTestRunsByDate = (testruns) => {
    return testruns.sort(function(t1,t2){
      return t1.test_datetime - t2.test_datetime;
    });
  }

  const toggleJob = (job) => {
    setToggledJob((prevToggledJob) => {
      if (prevToggledJob && prevToggledJob !== job) prevToggledJob.open = false;
      job.open = !job.open;
      return job;
    })
    setToggled(!toggled);
  }

  const formatDate = (dateString) => {
    if (dateString === "NULL" || dateString === "01.01.1970") {
      return "";
    }

    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (field) => {
    if (field === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
  };

  const handleColumnSelect = (field) => {
    setSelectedColumns({
      ...selectedColumns,
      [field]: !selectedColumns[field],
    });
  };

  const filterJobs = (jobList) => {
    const filteredJobs = jobList.filter((job) => {
      const searchString = searchTerm.toLowerCase();

      return (
        job.job_id.toString().includes(searchString) ||
        job.tester.toLowerCase().includes(searchString) ||
        job.priority.toString().includes(searchString) ||
        (job.due_date && job.due_date.includes(searchString))
      );
    });

    return filteredJobs;
  };

  const sortJobs = (jobList) => {
    if(!sortBy) return initialSortJobs(jobList);

    const sortedJobs = [...jobList];

    sortedJobs.sort((a, b) => {
      const fieldA = a[sortBy];
      const fieldB = b[sortBy];

      if (fieldA < fieldB) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (fieldA > fieldB) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });

    return sortedJobs;
  };

  const initialSortJobs = (jobList) => {
    const sortedJobs = [...jobList];

    sortedJobs.sort((a, b) => (a.due_date.localeCompare(b.due_date) || a.priority- b.priority));

    return sortedJobs;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleCloseInfo = (e) => {
    setInfoOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const postScanResults1 = async (results) => {
    try {
      const response = await axios.post(`api/scanresults/scanResults1`, results);
      showSnackbar("Scanvorgang erfolgreich ageschlossen");
      fetchJobs();
    } catch(error)  {
          console.log(error);
    }
  };

  const postScanResults2 = async (deviceId) => {
    try {
      //Post scan result 2
      const reportId = await getLatestReportId(deviceId);
      const results = {
        device_id: parseInt(deviceId),
        report_id: reportId,
        new_result: 1
      }
      const scanResult2Response = await axios.post(`api/scanresults/scanResults2`, results);

      //Create pdf certificate if test run has passed
      const testruns = await fetchTestRuns(deviceId);
      const sortedTestruns = sortTestRunsByDate(testruns);
      const testrun = sortedTestruns[0];
      if(testrun.test_passed === 1) {
        const certificateResponse = await axios.get(`api/certificates/report_id/${testrun.id}`);
        showSnackbar(`PDF Zertifikat mit ID ${testrun.id} wurde für OBC ${deviceId} erstellt`);
      } else {
        showSnackbar(`Test mit ID ${testrun.id} nicht bestanden. Zertifikat kann nicht erstellt werden`);
      }

      fetchJobs();
    } catch(error)  {
          console.log(error);
    }
  };

  const getLatestReportId = async (deviceId) => {
    const testruns = await fetchTestRuns(deviceId);
    if (!testruns)  {
      showSnackbar(`Für OBC ${deviceId} wurden keine Tests gefunden.`);
      return;
    }

    //Only get the latest testrun ID
    return testruns[0].id;
  }

  const updateDevice = async (deviceId, caseId) => {
    try {
      await axios.put(`/api/devices/${deviceId}`, {case_id: caseId});
      showSnackbar(`Bei OBC ${deviceId} wurde Gehäuse ${caseId} hinzugefügt.`);
    } catch (error) {
      console.error("Error updating device:", error);
    }
  }

  const sortedJobs = sortJobs(filteredJobs);

  const jobsWithOpenTasks = sortedJobs.filter((job) => job.tasks.filter(task => task.state === 0).length !== 0);

  const displayedJobs = jobsWithOpenTasks.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const testDateNotNull = (tasks) => {
    let testDateNotNull = false;
    tasks.forEach((task) => {
      if (task.test_date !== null) testDateNotNull = true;
    });

    return testDateNotNull;
  }

  const caseIdCompatible = async (deviceId, caseId) => {
    const task = await fetchTaskByDeviceId(deviceId);
    const orderPosition = await fetchOrderPosition(task.order_nr, task.position_id);

    return orderPosition.case_type === caseId;
  }

  const handleFullScreenClick = () => {
    if(!fullScreen) {
      setJobListProps({
        position: "absolute",
        left: "0px",
        top: "0px",
        margin: "0px",
        padding: "0px",
        width: window.innerWidth,
        height: window.innerHeight,
        zIndex: 2
      })
      setFullScreen(true);
    } else {
      setJobListProps({ padding: "30px", marginTop: "10px" })
      setFullScreen(false)
    }
  }

  return (
    <div>
      <Box pt={2} sx={{ marginLeft: "240px", paddingTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Suchen"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
          </Grid>
        </Grid>
        <TableContainer component={Paper} style={jobListProps}>
        {!fullScreen && <IconButton>
          <FullscreenIcon onClick={handleFullScreenClick}></FullscreenIcon>
        </IconButton>}
        {fullScreen && <IconButton>
          <FullscreenExitIcon onClick={handleFullScreenClick} style={{  }}></FullscreenExitIcon>
        </IconButton>}
          <Table>
            <TableHead>
              <TableRow>
                {selectedColumns.job_id && (
                  <TableCell id="job_id">
                    <TableSortLabel
                      active={sortBy === "job_id"}
                      direction={sortOrder}
                      onClick={() => handleSort("job_id")}
                      onDoubleClick={()=>{
                        setSortBy(null);
                        initialSortJobs(jobs)
                      }}
                    >
                      Job-ID
                    </TableSortLabel>
                  </TableCell>
                )}
                {selectedColumns.tester && (
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === "tester"}
                      direction={sortOrder}
                      onClick={() => handleSort("tester")}
                      onDoubleClick={()=>{
                        setSortBy(null);
                        initialSortJobs(jobs)
                      }}                    >
                      Tester
                    </TableSortLabel>
                  </TableCell>
                )}
                {selectedColumns.priority && (
                  <TableCell id="priority">
                    <TableSortLabel
                      active={sortBy === "priority"}
                      direction={sortOrder}
                      onClick={() => handleSort("priority")}
                      onDoubleClick={()=>{
                        setSortBy(null);
                        initialSortJobs(jobs)
                      }}                    >
                      Priorität
                    </TableSortLabel>
                  </TableCell>
                )}
                {selectedColumns.due_date && (
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === "due_date"}
                      direction={sortOrder}
                      onClick={() => handleSort("due_date")}
                      onDoubleClick={()=>{
                        setSortBy(null);
                        initialSortJobs(jobs)
                      }}                    >
                      Fälligkeitsdatum
                    </TableSortLabel>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
  {displayedJobs.map((job) => (
    <React.Fragment key={job.job_id}>
      <TableRow>
        <TableCell align="left">
          <IconButton
            size="small"
            onClick={() => toggleJob(job)}
          >
            {job.open ? (
              <KeyboardArrowDown />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
          {job.job_id}
        </TableCell>
        <TableCell align="left">{job.tester}</TableCell>
        <TableCell align="left">{job.priority}</TableCell>
        <TableCell align="left">
          {formatDate(job.due_date)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={4}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >
          <Collapse in={job.open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Aufgaben-ID</TableCell>
                    <TableCell align="left">Bestellnummer</TableCell>
                    <TableCell align="left">Geräte-ID</TableCell>
                    <TableCell align="left">Artikelnummer</TableCell>
                    <TableCell align="left">Artikelstand</TableCell>
                    <TableCell align="left">Fälligkeitsdatum</TableCell>
                    <TableCell align="left">Prüfdatum</TableCell>
                    <TableCell align="left">OBC-Typ</TableCell>
                    <TableCell align="left">Steckertyp</TableCell>
                    <TableCell align="left">Kunde</TableCell>
                    <TableCell align="left">Vertrieb</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Aktion</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {job.tasks.map((task) => (
                    <TableRow key={task.tasks_id}>
                      <TableCell align="left">
                        {task.tasks_id}
                      </TableCell>
                      <TableCell align="left">
                        {task.order_nr}
                      </TableCell>
                      <TableCell align="left">
                        {task.device_id}
                      </TableCell>
                      <TableCell align="left">
                        {task.article_number}
                      </TableCell>
                      <TableCell align="left">
                        {task.article_stand}
                      </TableCell>
                      <TableCell align="left">
                        {formatDate(task.due_date)}
                      </TableCell>
                      <TableCell align="left">
                        {task.test_date ? formatDate(task.test_date) : ""}
                      </TableCell>
                      <TableCell align="left">
                        {task.name}
                      </TableCell>
                      <TableCell align="left">
                        {task.power_plug}
                      </TableCell>
                      <TableCell align="left">
                        {task.kunde}
                      </TableCell>
                      <TableCell align="left">
                        {task.vertrieb}
                      </TableCell>
                      <TableCell align="left">
                        {task.state === 0 ? (
                          <CloseIcon color="error" />
                        ) : task.state === 2 ? (
                          <VerifiedIcon style={{ color: "blue" }} />
                        ) : (
                          <CheckIcon color="primary" />
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {task.state === 0 ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              if (task === selectedTask) {
                                setOpen(true);
                              } else {
                                setSelectedTask(task);
                              }
                            }} 
                          >
                            Starten
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="info"
                            onClick={() => {
                              if (task === selectedTask) {
                                setInfoOpen(true);
                              } else {
                                setSelectedTask(task);
                              }
                            }} 
                          >
                            Info
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  ))}
</TableBody>

          </Table>
          <TablePagination
          rowsPerPageOptions={rowsOptions}
          component="div"
          count={jobsWithOpenTasks.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Zeilen pro Seite:"
          SelectProps={{
            inputProps: { "aria-label": "Zeilen pro Seite" },
          }}
        />
      </TableContainer>
      <Dialog open={open} maxWidth="lg" fullWidth>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={10}>
            <DialogTitle style={{paddingBottom: '5px'}}>Scanvorgang</DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <DialogActions style={{marginRight: '10px'}}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </DialogActions>
          </Grid>
        </Grid>
        <DialogContent style={{paddingBottom: '10px', paddingTop: '0px'}}>
          {selectedTask && (
            <JobListForm
              onClose={handleClose}
              onSubmit={postScanResults1}
              taskId={selectedTask.tasks_id}
            />
        )}
        </DialogContent>
        </Dialog>
        {selectedTask && (
        <JobListInformation
          key={selectedTask.tasks_id}
          open={infoOpen}
          onClose={handleCloseInfo}
          title={"Informationen für Task " + selectedTask.tasks_id}
          task={selectedTask}
        />
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        />
      </Box>
    </div>
  );
}

export default JobsList;
