import { useState, useEffect } from "react";
import axios from "axios";

const useAuth = (reload) => {
  // Fügen Sie reload als Abhängigkeit hinzu
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get(
          `api/auth/check-auth`,
          {
            withCredentials: true,
          }
        );
        if (response.status === 200) {
          setIsAuthenticated(true);
          console.log("User is authenticated");
          const userData = await fetchUserProfile();
          setUser(userData);
        } else {
          setIsAuthenticated(false);
          console.log("User is not authenticated");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setIsAuthenticated(false);
          console.log("User is not authenticated");
        } else {
          setIsAuthenticated(false);
          console.log("Error in authentication check:", error);
        }
      }
    };
    checkAuthStatus();
  }, [reload]);

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get(
        `api/user/profile`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        return fetchUserProfile();
      } else {
        console.error("Error fetching user profile", error);
        return null;
      }
    }
  };

  return isAuthenticated;
};

export default useAuth;
