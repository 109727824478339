import React, { Fragment, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/navbar/navbar";
import Sidebar from "./components/sidebar/sidebar";
import Homepage from "./components/home/home";
import Login from "./components/login/login";
import PrivateRoute from "./components/ProtectedRoute";
import useAuth from "./components/isAuthenticated";
import Profile from "./components/profile/profile";
import Admin from "./components/admin/admin";
import Customers from "./components/customers/customers";
import Report from "./components/report/report";
import Orders from "./components/order/orders";
import Article from "./components/article/article";
import ResetPassword from "./components/login/resetPassword";
import ConfirmResetPassword from "./components/login/confirmResetPassword";
import JobsList from "./components/job/jobList";
import TestResult from "./components/testResults/testResults";
import ThemeProvider from "./components/theme";
import Job from "./components/job/job.js";
import Device from "./components/devices/devices";
import Software from "./components/software/software";
import ParameterSet from "./components/parameterSet/parameterSet";
import ComponentScan from "./components/componentScan/componentScan";

function App() {
  const [reload, setReload] = useState(false);
  const isAuthenticated = useAuth(reload);

  const handleAuthenticated = () => {
    setReload(!reload);
  };

  return (
    <ThemeProvider>
      <Router>
        <Fragment>
          {isAuthenticated && <Navbar onLogout={() => setReload(!reload)} />}
          <div style={{ display: "flex" }}>
            {isAuthenticated && (
              <div>
                <Sidebar />
              </div>
            )}
            <div
              style={{
                flexGrow: 1,
              }}
            >
              <Routes>
                <Route
                  path="/"
                  element={
                    isAuthenticated ? (
                      <Homepage />
                    ) : (
                      <Login onAuthenticated={handleAuthenticated} />
                    )
                  }
                />
                <Route
                  path="/customers"
                  element={
                    isAuthenticated ? <Customers /> : <Navigate to="/" />
                  }
                />
                <Route
                  path="/orders"
                  element={isAuthenticated ? <Orders /> : <Navigate to="/" />}
                />
                <Route
                  path="/report"
                  element={isAuthenticated ? <Report /> : <Navigate to="/" />}
                />
                <Route
                  path="/profile"
                  element={isAuthenticated ? <Profile /> : <Navigate to="/" />}
                />
                <Route
                  path="/admin"
                  element={isAuthenticated ? <Admin /> : <Navigate to="/" />}
                />
                <Route
                  path="/article"
                  element={isAuthenticated ? <Article /> : <Navigate to="/" />}
                />
                <Route
                  path="/devices"
                  element={isAuthenticated ? <Device /> : <Navigate to="/" />}
                />
                <Route
                  path="/job"
                  element={isAuthenticated ? <Job /> : <Navigate to="/" />}
                />
                <Route
                  path="/job-list"
                  element={isAuthenticated ? <JobsList /> : <Navigate to="/" />}
                />
                <Route
                  path="/test-result"
                  element={
                    isAuthenticated ? <TestResult /> : <Navigate to="/" />
                  }
                />
                <Route
                  path="/software"
                  element={isAuthenticated ? <Software /> : <Navigate to="/" />}
                />
                <Route
                  path="/parameterSet"
                  element={
                    isAuthenticated ? <ParameterSet /> : <Navigate to="/" />
                  }
                />
                <Route
                  path="/componentScan"
                  element={
                    isAuthenticated ? <ComponentScan /> : <Navigate to="/" />
                  }
                />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route
                  path="/confirm-reset-password/:token"
                  element={<ConfirmResetPassword />}
                />
              </Routes>
            </div>
          </div>
        </Fragment>
      </Router>
    </ThemeProvider>
  );
}

export default App;
