import React, { useState, useEffect } from "react";
import axios from "axios";
import ReportForm from "./reportForm";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  TextField,
  Snackbar,
  Box,
  TablePagination,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  FormControl,
  IconButton,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import Grid from "@mui/material/Grid";
import SettingsIcon from "@mui/icons-material/Settings";
import ComponentSelector from "./componentSelector";

const Report = () => {
  const [report, setReport] = useState([]);
  const [filteredReport, setFilteredReport] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [sortReport, setSortReport] = useState("asc");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsOptions = [5, 10, 15, 20];
  const [selectedColumn, setSelectedColumn] = useState("SerialNr");
  const [checkboxPopupOpen, setCheckboxPopupOpen] = useState(false);
  const [searchColumn, setSearchColumn] = useState("SerialNr");
  const [selectedComponent, setSelectedComponent] = useState("sc19030");

  const SC19030 = "sc19030";
  const SC18025 = "sc18025";

  const [visibleColumns, setVisibleColumns] = useState({
    test_date_time: true,
    serial_number: true,
    tester: true,
    sw_version: true,
    hw_version: true,
    qr_code: true,
    item_number: true,
    test_result: true,
    test_report: true,
    comment: true,
    order_id: true,
  });

  const columnDisplayNames = {
    test_date_time: "Prüfdatum/zeit",
    serial_number: "Seriennummer",
    tester: "Tester/Prüfer",
    sw_version: "SW Version",
    hw_version: "HW Version",
    qr_code: "QR-Code",
    item_number: "Artikel-Nr.",
    test_result: "Test Ergebnis",
    test_report: "Prüfbericht",
    comment: "Kommentar",
    order_id: "Bestellungs Id",
  };

  useEffect(() => {
    fetchReport();
  }, [selectedComponent]);

  const handleComponentChange = (selectedComponent) => {
    console.log("Ausgewählte Komponente:", selectedComponent);
    setSelectedComponent(selectedComponent);
  };

  const fetchReport = async () => {
    try {
      const response = await axios.get(`/api/report/${selectedComponent}`);
      setReport(response.data);
      setFilteredReport(response.data);
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    setFilteredReport(
      report.filter((report) =>
        report[searchColumn].toString().toLowerCase().includes(searchValue)
      )
    );
  };

  const handleCheckboxChange = (event) => {
    setVisibleColumns({
      ...visibleColumns,
      [event.target.name]: event.target.checked,
    });
  };

  const handleColumnSelect = (event) => {
    setSearchColumn(event.target.value);
  };

  const handleSort = (field) => {
    const sortedReport = [...filteredReport].sort((a, b) => {
      if (sortReport === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });

    setFilteredReport(sortedReport);
    setSortReport(sortReport === "asc" ? "desc" : "asc");
  };

  const createReport = async (reportData) => {
    try {
      const response = await axios.post(
        `/api/report/${selectedComponent}`,
        reportData
      );
      fetchReport();
      showSnackbar(
        `Report mit SerialNr ${reportData.serial_number} wurde erfolgreich erstellt.`
      );
    } catch (error) {
      console.error("Error creating report:", error);
    }
  };

  const updateReport = async (reportData) => {
    try {
      await axios.put(
        `/api/report/${selectedComponent}/${reportData.id}`,
        reportData
      );
      fetchReport();
      showSnackbar(
        `Report mit SerialNr ${reportData.serial_number} wurde erfolgreich geupdatet.`
      );
    } catch (error) {
      console.error("Error updating report:", error);
    }
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/report/${selectedComponent}/${id}`);
      setFilteredReport(filteredReport.filter((report) => report.id !== id));
      showSnackbar(`Report mit Id ${id} wurde erfolgreich gelöscht.`);
    } catch (error) {
      console.log("Delete report failed", error);
    }
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openCheckboxPopup = () => {
    setCheckboxPopupOpen(true);
  };

  const closeCheckboxPopup = () => {
    setCheckboxPopupOpen(false);
  };

  return (
    <div>
      <Box pt={2} sx={{ paddingLeft: "240px", paddingTop: "20px" }}>
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
          style={{
            backgroundColor: "#fff",
            borderRadius: "4px",
            padding: "2rem",
            width: "100%",
          }}
        >
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="report-select-label">Bericht wählen</InputLabel>
              <Select
                labelId="report-select-label"
                value={selectedComponent}
                onChange={(event) => handleComponentChange(event.target.value)}
                label="Bericht wählen"
              >
                <MenuItem value="sc19030">SC19030</MenuItem>
                <MenuItem value="sc18025">SC18025</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="column-select">Suche in Spalte:</InputLabel>
              <Select
                labelId="column-select"
                value={searchColumn}
                onChange={handleColumnSelect}
                label="Suche in Spalte"
              >
                {Object.keys(visibleColumns).map((column) => (
                  <MenuItem key={column} value={column}>
                    {columnDisplayNames[column]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Suchen"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setOpenCreate(true)}
              style={{ marginLeft: "1rem" }}
            >
              Bericht hinzufügen
            </Button>
          </Grid>
          <Grid item>
            <IconButton
              onClick={openCheckboxPopup}
              style={{ marginLeft: "1rem" }}
            >
              <SettingsIcon />
            </IconButton>
          </Grid>
        </Grid>
        <TableContainer component={Paper} style={{ padding: "30px" }}>
          <Table>
            <TableHead>
              <TableRow>
                {visibleColumns.test_date_time && (
                  <TableCell onClick={() => handleSort("test_date_time")}>
                    Prüfdatum/zeit
                  </TableCell>
                )}
                {visibleColumns.serial_number && (
                  <TableCell onClick={() => handleSort("serial_number")}>
                    Seriennummer
                  </TableCell>
                )}
                {visibleColumns.tester && (
                  <TableCell onClick={() => handleSort("tester")}>
                    Tester/Prüfer
                  </TableCell>
                )}
                {visibleColumns.sw_version && (
                  <TableCell onClick={() => handleSort("sw_version")}>
                    SW Version
                  </TableCell>
                )}
                {visibleColumns.hw_version && (
                  <TableCell onClick={() => handleSort("hw_version")}>
                    HW Version
                  </TableCell>
                )}
                {visibleColumns.qr_code && (
                  <TableCell onClick={() => handleSort("qr_code")}>
                    QR-Code
                  </TableCell>
                )}
                {visibleColumns.item_number && (
                  <TableCell onClick={() => handleSort("item_number")}>
                    Artikel-Nr.
                  </TableCell>
                )}
                {visibleColumns.test_result && (
                  <TableCell onClick={() => handleSort("test_result")}>
                    Test Ergebnis
                  </TableCell>
                )}
                {visibleColumns.test_report && (
                  <TableCell onClick={() => handleSort("test_report")}>
                    Prüfbericht
                  </TableCell>
                )}
                {visibleColumns.comment && (
                  <TableCell onClick={() => handleSort("comment")}>
                    Kommentar
                  </TableCell>
                )}
                {visibleColumns.order_id && (
                  <TableCell onClick={() => handleSort("order_id")}>
                    Bestellungs Id
                  </TableCell>
                )}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredReport
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((report) => (
                  <TableRow key={report.id}>
                    {visibleColumns.test_date_time && (
                      <TableCell>{report.test_date_time}</TableCell>
                    )}
                    {visibleColumns.serial_number && (
                      <TableCell>{report.serial_number}</TableCell>
                    )}
                    {visibleColumns.tester && (
                      <TableCell>{report.tester}</TableCell>
                    )}
                    {visibleColumns.sw_version && (
                      <TableCell>{report.sw_version}</TableCell>
                    )}
                    {visibleColumns.hw_version && (
                      <TableCell>{report.hw_version}</TableCell>
                    )}
                    {visibleColumns.qr_code && (
                      <TableCell>{report.qr_code}</TableCell>
                    )}
                    {visibleColumns.item_number && (
                      <TableCell>{report.item_number}</TableCell>
                    )}
                    {visibleColumns.test_result && (
                      <TableCell>{report.test_result}</TableCell>
                    )}
                    {visibleColumns.test_report && (
                      <TableCell>{report.test_report}</TableCell>
                    )}
                    {visibleColumns.comment && (
                      <TableCell>{report.comment}</TableCell>
                    )}
                    {visibleColumns.order_id && (
                      <TableCell>{report.order_id}</TableCell>
                    )}
                    <TableCell>
                      <Button
                        onClick={() => {
                          setSelectedReport(report);
                          setOpenEdit(true);
                        }}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        onClick={() => {
                          handleDelete(report.id);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                      <Button
                        onClick={() => {
                          console.log("PDF drucken für Bestellung:", report.id);
                        }}
                      >
                        <PrintIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filteredReport.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Zeilen pro Seite:"
          rowsPerPageOptions={rowsOptions}
          SelectProps={{
            inputProps: { "aria-label": "Zeilen pro Seite" },
          }}
        />
        <ReportForm
          isOpen={openCreate}
          onClose={handleCloseCreate}
          onSubmit={createReport}
          title="Create Report"
        />
        {selectedReport && (
          <ReportForm
            isOpen={openEdit}
            onClose={() => {
              setSelectedReport(null);
              handleCloseEdit();
            }}
            onSubmit={updateReport}
            title="Edit Report"
            report={selectedReport}
          />
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        />
      </Box>
      {/* Checkbox Popup */}
      <Dialog open={checkboxPopupOpen} onClose={closeCheckboxPopup}>
        <DialogTitle>Spalten auswählen</DialogTitle>
        <DialogContent>
          {Object.keys(visibleColumns).map((column) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleColumns[column]}
                  onChange={handleCheckboxChange}
                  name={column}
                />
              }
              label={column}
              key={column}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCheckboxPopup} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Report;
