import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  TextField,
  IconButton,
  Box,
  TablePagination,
  Tabs,
  Tab,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import axios from "axios";

const OrderList = ({ orders, onOrderSelect, selectedOrder }) => {
  const [search, setSearch] = useState("");
  const [isDescending, setIsDescending] = useState(false);
  const [tab, setTab] = useState(0); // 0 for Open Orders, 1 for Finished Orders
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const rowsOptions = [5, 10, 15, 20];
  const [customers, setCustomers] = useState({});

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await axios.get("/api/customersWorkflow");
      const customersData = response.data.reduce((acc, customer) => {
        acc[customer.customer_id] = customer.name;
        return acc;
      }, {});
      setCustomers(customersData);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    setPage(0); // Reset pagination to first page when switching tabs
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortChange = () => {
    setIsDescending((prevValue) => !prevValue);
  };

  //Displays Orders based on the tab selected (Tab 0 = Open Orders, Tab 1 = Closed Orders)
  const filteredOrders = orders
  .filter(
    (order) =>
      (tab === 0 ? order.isDone === 0 : order.isDone === 1) &&
      (order.order_id.toString().includes(search.toString()) ||
        customers[order.customer_id]
          ?.toLowerCase()
          .includes(search.toLowerCase()))
  )
  .sort((a, b) =>
    isDescending ? b.order_id - a.order_id : a.order_id - b.order_id
  );

  return (
    <>
      <Tabs value={tab} onChange={handleChangeTab} aria-label="order tabs">
        <Tab label="Offene Bestellungen" />
        <Tab label="Abgeschlossene Bestellungen" />
      </Tabs>
      <Box display="flex" alignItems="center" marginTop={2}>
        <TextField
          label="Suche nach Bestell-ID oder Kundenname"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          size="small"
          sx={{ marginRight: 1 }}
        />
        <IconButton onClick={handleSortChange} size="small">
          {isDescending ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
        </IconButton>
      </Box>
      <List style={{ overflowX: "hidden", overflowY: "auto" }}>
        {filteredOrders
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((order) => (
            <ListItem
              button
              onClick={() => onOrderSelect(order)}
              key={order.order_id}
              sx={{
                marginBottom: 2,
                backgroundColor:
                  selectedOrder === order ? "#34bc34" : "white",
                color: selectedOrder === order ? "white" : "black",
              }} // Change background color if order is selected
            >
              <ListItemAvatar>
                <Avatar>
                  <ShoppingCartIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`Bestellung-${order.order_id}`}
                secondary={`Kunde: ${customers[order.customer_id]}`}
              />
            </ListItem>
          ))}
      </List>
      <TablePagination
        style={{ overflowX: "hidden", overflowY: "auto" }}
        component="div"
        count={filteredOrders.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Zeilen pro Seite:"
        rowsPerPageOptions={rowsOptions}
        SelectProps={{
          inputProps: { "aria-label": "Zeilen pro Seite" },
        }}
      />
    </>
  );
};

export default OrderList;
