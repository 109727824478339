import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Validation from "./loginValidation";
import { useNavigate } from "react-router-dom";
import useAuth from "../isAuthenticated";
import axios from "axios";
import Logo from "../../media/logoLogin";
import { Link as RouterLink } from "react-router-dom";
import { Snackbar } from "@mui/material";
import { green } from "@mui/material/colors";
import Alert from "@mui/material/Alert";

async function loginUser(credentials) {
  return axios
    .post(`api/auth/login`, credentials, {
      withCredentials: true,
    })
    .then((response) => {
      if (response.data.message === "Login successful") {
        return { success: true };
      } else {
        return { success: false, message: response.data.error };
      }
    })
    .catch((error) => {
      console.log("Login failed:", error);
      return { success: false, message: "Login failed" };
    });
}

export default function Login({ onAuthenticated }) {
  const auth = useAuth();
  const { isAuthenticated, setIsAuthenticated } = auth;
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const handleInput = (event) => {
    setValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleLoginSuccess = () => {
    onAuthenticated();
    setSuccess(true);
    navigate("/");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    loginUser({
      email: values.email,
      password: values.password,
    })
      .then((result) => {
        if (result.success) {
          handleLoginSuccess();
        } else {
          setErrors(Validation(values));
          console.error("Login failed:", result.message);
        }
      })
      .catch((error) => {
        console.error("Login failed:", error);
      });
  };
  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo />
        <Typography component="h1" variant="h5"></Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-Mail-Adresse"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleInput}
            error={errors.email !== undefined}
            helperText={errors.email}
            InputLabelProps={{
              style: {
                color: success ? green[500] : "",
              },
            }}
            InputProps={{
              style: {
                borderColor: success ? green[500] : "",
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Passwort"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleInput}
            error={errors.password !== undefined}
            helperText={errors.password}
            InputLabelProps={{
              style: {
                color: success ? green[500] : "",
              },
            }}
            InputProps={{
              style: {
                borderColor: success ? green[500] : "",
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="login-button"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to="/reset-password" variant="body2">
                Passwort vergessen?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
