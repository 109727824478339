import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const OrderPositionList = ({ order }) => {
  const [orderPositions, setOrderPositions] = useState([]);

  useEffect(() => {
    fetchOrderPositions();
  }, [order]);

  const fetchOrderPositions = async () => {
    try {
      const response = await axios.get(`/api/orderPositions/${order.order_id}`);
      const positions = response.data;

      const updatedPositions = await Promise.all(
        positions.map(async (position) => {
          const articleType = await fetchArticleType(position.article_number, position.article_stand);
          const articleTypeName = articleType ? articleType.name : "";
          const case_type = articleType ? articleType.case_type : "";
          return { ...position, articleTypeName, case_type};
        })
      );

      setOrderPositions(updatedPositions);
    } catch (error) {
      console.error("Error fetching order positions:", error);
    }
  };

  const handleDeletePosition = async (positionId, orderId) => {
    try {
      await axios.delete(`/api/orderPositions/position/${positionId}/order/${orderId}`);
      setOrderPositions((prevPositions) =>
        prevPositions.filter((position) => !(position.position_id === positionId && position.order_id === orderId))
      );
    } catch (error) {
      console.error("Error deleting order position:", error);
    }
  };

  const fetchArticleType = async (articleNumber, articleStand) => {
    try {
      const response = await axios.get(`/api/articleTypes/${articleNumber}/${articleStand}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching article type:", error);
    }
  };

  return (
    <>
      <Typography variant="h6">Bestellpositionen</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Positionsnummer</TableCell>
              <TableCell>Bestellnummer</TableCell>
              <TableCell>Artikelnummer</TableCell>
              <TableCell>Artikelstand</TableCell>
              <TableCell>Standard Software</TableCell>
              <TableCell>Kunden Software</TableCell>
              <TableCell>EPLC Software</TableCell>
              <TableCell>Parametersatz</TableCell>
              <TableCell>Deckeltyp</TableCell>
              <TableCell>Etikett Typ</TableCell>
              <TableCell>Menge</TableCell>
              <TableCell>Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderPositions.map((position) => (
              <TableRow
                key={position.position_id}
                style={{
                  display: position.delete === 1 ? "none" : "table-row",
                }}
              >
                <TableCell>{position.position_id}</TableCell>
                <TableCell>{position.order_id}</TableCell>
                <TableCell>{position.article_number}</TableCell>
                <TableCell>{position.article_stand}</TableCell>
                <TableCell>{position.standard_software_id}</TableCell>
                <TableCell>{position.customer_software_id}</TableCell>
                <TableCell>{position.eplc_software_id}</TableCell>
                <TableCell>{position.parameter_set_id}</TableCell>
                <TableCell>{position.case_type}</TableCell>
                <TableCell>{position.label_type}</TableCell>
                <TableCell>{position.amount}</TableCell>
                <TableCell>
                  {position.delete !== 1 && (
                    <IconButton
                      onClick={() => handleDeletePosition(position.position_id, position.order_id)}
                      aria-label="Delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OrderPositionList;
