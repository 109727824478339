import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Paper,
  Grid,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Typography,
  ToggleButtonGroup,
  ToggleButton

} from "@mui/material";
import Draggable from "react-draggable";

const DraggableDialog = (props) => {
  const handleDrag = (event) => {
    event.preventDefault();
  };

  return (
    <Draggable handle=".drag-handle" onDrag={handleDrag}>
      <Paper {...props} />
    </Draggable>
  );
};

const ArticleForm = ({ isOpen, onClose, onSubmit, title, article }) => {
  const formatRegex = new RegExp("^SC[0-9]{5}.[0-9]{1}-[0-9]{1}$");
  const voltageRegex = new RegExp("^[0-9]+(( |)-( |)[0-9]+|)$");
  const emptyErrorMessage = "Dies ist ein Pflichtfeld"
  const formatErrorMessage = "Ungültiges Format";

  const [formData, setFormData] = useState({
    article_number: {
      value: article?.article_number || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    article_stand: {
      value: article?.article_stand || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    name: {
      value: article?.name || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    modul_number: {
      value: article?.modul_number || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    reseller_article_number: {
      value: article?.reseller_article_number || ""
    },
    voltage: {
      value: article?.voltage?.toString() || "",
      emptyError: false,
      formatError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatErrorMessage: formatErrorMessage
    },
    voltage_input: {
      value: article?.voltage_input || "",
      emptyError: false,
      formatError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatErrorMessage: formatErrorMessage
    },
    voltage_output: {
      value: article?.voltage_output || "",
      emptyError: false,
      formatError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatErrorMessage: formatErrorMessage
    },
    power: {
      value: article?.power.toString() || "",
      emptyError: false,
      formatError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatErrorMessage: formatErrorMessage
    },
    current_input: {
      value: article?.current_input.toString() || "",
      emptyError: false,
      formatError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatErrorMessage: formatErrorMessage
    },
    current_output: {
      value: article?.current_output?.toString() || "",
      emptyError: false,
      formatError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatErrorMessage: formatErrorMessage
    },
    compatible_power_stage_sn: {
      value: article?.compatible_power_stage_sn || "",
      emptyError: false,
      formatError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatErrorMessage: formatErrorMessage
    },
    compatible_power_stage_stand: {
      value: article?.compatible_power_stage_stand || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    compatible_control_board_sn: {
      value: article?.compatible_control_board_sn || "",
      emptyError: false,
      formatError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatErrorMessage: formatErrorMessage
    },
    compatible_control_board_stand: {
      value: article?.compatible_control_board_stand || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    compatible_spike_supression_board_sn: {
      value: article?.compatible_spike_supression_board_sn || "",
      emptyError: false,
      formatError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatErrorMessage: formatErrorMessage
    },
    compatible_spike_supression_board_stand: {
      value: article?.compatible_spike_supression_board_stand || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    compatible_emv_board_sn: {
      value: article?.compatible_emv_board_sn || "",
      emptyError: false,
      formatError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatErrorMessage: formatErrorMessage
    },
    compatible_emv_board_stand: {
      value: article?.compatible_emv_board_stand || "",
      emptyErrorMessage: false,
      emptyErrorMessage: emptyErrorMessage
    },
    compatible_signalboard_sn: {
      value: article?.compatible_signalboard_sn || "",
      emptyError: false,
      formatError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatErrorMessage: formatErrorMessage
    },
    compatible_signalboard_stand: {
      value: article?.compatible_signalboard_stand || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    compatible_emi_shield_sn: {
      value: article?.compatible_emi_shield_sn || "",
      formatError: false,
      formatErrorMessage: formatErrorMessage
    },
    compatible_eplc_board_sn: {
      value: article?.compatible_eplc_board_sn || "",
      formatError: false,
      formatErrorMessage: formatErrorMessage
    },
    case_type: {
      value: article?.case_type || ""
    },
    power_plug: {
      value: article?.power_plug || "EU"

    }
  });


  const handleChange = async (event) => {
    const { name, value } = event.target;

    if(formData[name.toString()].emptyErrorMessage) {
      checkNoEmptyInput(name, value);
    }

    if(formData[name.toString()].formatErrorMessage) {
      checkValidSNFormat(name, value);
    }

    if(name.toString() === "voltage_input" || name.toString() === "voltage_output") {
      checkValidVoltageFormat(name, value);
    }

    if(name.toString() === "voltage"  || name.toString() === "current_input"
    || name.toString() === "current_output" || name.toString() === "power") {
      checkIntegerFormat(name, value);
    }

    if(!(formData[name.toString()].emptyErrorMessage || formData[name.toString()].formatErrorMessage)) {
      setFormData({
        ...formData,
        [name]:{
          ...formData[name],
          value
        }
      })
    }
  };

  const handleSubmit = () => {
    if (article) {
      formData.article_number.value = article.article_number;
      formData.article_stand.value = article.article_stand;
    }

    if (!validInputFields()) return;

    onSubmit(formData);
    onClose();
  };

  const handleClose = () => {
    const formFields = Object.keys(formData);
    let newFormData = {...formData}

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      if(formData[currentField.toString()].emptyErrorMessage){

        newFormData = {
          ...newFormData,
          [currentField]:{
            ...newFormData[currentField],
            emptyError:false
          }
        }
      }
    setFormData(newFormData);

    onClose();
  };
}

  const validInputFields = () => {
    let isValid = true;
    const formFields = Object.keys(formData);

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formData[currentField].value;

      if(formData[currentField.toString()].emptyErrorMessage && currentValue === ""){
        isValid = false;
        checkNoEmptyInput(currentField, currentValue);
      }

      if(formData[currentField.toString()].formatErrorMessage && formData[currentField.toString()].formatError) {
        isValid = false;
      }

  }

  return isValid;

}

  const checkValidSNFormat = async (fieldName, fieldValue) => {
    let newFormData = {...formData};

    const isValid = fieldValue.match(formatRegex) || fieldValue === "";

      newFormData = {
        ...newFormData,
        [fieldName]:{
          ...newFormData[fieldName],
          value: fieldValue,
          formatError:!isValid
        }
      }
      setFormData(newFormData);
  }

  const checkValidVoltageFormat = async (fieldName, fieldValue) => {
    let newFormData = {...formData};

    const isValid = fieldValue.match(voltageRegex) || fieldValue === "";

      newFormData = {
        ...newFormData,
        [fieldName]:{
          ...newFormData[fieldName],
          value: fieldValue,
          formatError:!isValid
        }
      }
      setFormData(newFormData);
  }

  const checkIntegerFormat = async (fieldName, fieldValue) => {
    let newFormData = {...formData};

    const isValid = !(isNaN(Number(fieldValue))) || fieldValue === "";

      newFormData = {
        ...newFormData,
        [fieldName]:{
          ...newFormData[fieldName],
          value: fieldValue,
          formatError:!isValid
        }
      }
      setFormData(newFormData);
  }

  const checkNoEmptyInput = async (fieldName, fieldValue) => {
    const isValid = !(fieldValue === "");

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]:{
        ...prevFormData[fieldName],
        value: fieldValue,
        emptyError:!isValid
      }
    }));

    return isValid;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      PaperComponent={DraggableDialog}
    >
      <DialogTitle className="drag-handle">{title}</DialogTitle>
      <DialogContent>
        <TextField
          required
          label="Artikelnummer"
          name="article_number"
          value={formData.article_number.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("article_number", formData.article_number.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.article_number.emptyError}
          helperText={formData.article_number.emptyError?emptyErrorMessage:""}
        />
        <TextField
          required
          label="Artikelstand"
          name="article_stand"
          value={formData.article_stand.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("article_stand", formData.article_stand.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.article_stand.emptyError}
          helperText={formData.article_stand.emptyError?emptyErrorMessage:""}
        />
        <TextField
          required
          label="Name"
          name="name"
          value={formData.name.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("name", formData.name.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.name.emptyError}
          helperText={formData.name.emptyError?emptyErrorMessage:""}
        />
        <TextField
          required
          label="Modulnummer"
          name="modul_number"
          value={formData.modul_number.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("modul_number", formData.modul_number.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.modul_number.emptyError}
          helperText={formData.modul_number.emptyError?emptyErrorMessage:""}
        />
        <TextField
          label="Deutz Artikelnummer"
          name="reseller_article_number"
          value={formData.reseller_article_number.value}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5 }}
        />
        <TextField
          required
          label="Spannung"
          name="voltage"
          value={formData.voltage.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("voltage", formData.voltage.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.voltage.emptyError || formData.voltage.formatError}
          helperText={formData.voltage.emptyError?emptyErrorMessage:formData.voltage.formatError?formatErrorMessage:""}
        />
        <TextField
          required
          label="Spannung Input"
          name="voltage_input"
          value={formData.voltage_input.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("voltage_input", formData.voltage_input.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.voltage_input.emptyError || formData.voltage_input.formatError}
          helperText={formData.voltage_input.emptyError?emptyErrorMessage:formData.voltage_input.formatError?formatErrorMessage:""}
        />
        <TextField
          required
          label="Spannung Output"
          name="voltage_output"
          value={formData.voltage_output.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("voltage_output", formData.voltage_output.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.voltage_output.emptyError || formData.voltage_output.formatError}
          helperText={formData.voltage_output.emptyError?emptyErrorMessage:formData.voltage_output.formatError?formatErrorMessage:""}
        />
        <TextField
          required
          label="Leistung"
          name="power"
          value={formData.power.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("power", formData.power.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.power.emptyError || formData.power.formatError}
          helperText={formData.power.emptyError?emptyErrorMessage:formData.power.formatError?formatErrorMessage:""}
        />
        <TextField
          required
          label="Stromstärke Input"
          name="current_input"
          value={formData.current_input.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("current_input", formData.current_input.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.current_input.emptyError || formData.current_input.formatError}
          helperText={formData.current_input.emptyError?emptyErrorMessage:formData.current_input.formatError?formatErrorMessage:""}
        />
        <TextField
          required
          label="Stromstärke Output"
          name="current_output"
          value={formData.current_output.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("current_output", formData.current_output.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.current_output.emptyError || formData.current_output.formatError}
          helperText={formData.current_output.emptyError?emptyErrorMessage:formData.current_output.formatError?formatErrorMessage:""}
        />
        <Grid container="true">
        <TextField
          required
          label="SN der kompatiblen Power Stage"
          name="compatible_power_stage_sn"
          value={formData.compatible_power_stage_sn.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("compatible_power_stage_sn", formData.compatible_power_stage_sn.value)}
          sx={{ marginBottom: 5, width: 350 }}
          error={formData.compatible_power_stage_sn.emptyError || formData.compatible_power_stage_sn.formatError}
          helperText={formData.compatible_power_stage_sn.emptyError?emptyErrorMessage:formData.compatible_power_stage_sn.formatError
            ?formatErrorMessage:""}
        />
        <TextField
          required
          label="Stand"
          name="compatible_power_stage_stand"
          value={formData.compatible_power_stage_stand.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("compatible_power_stage_stand", formData.compatible_power_stage_stand.value)}
          sx={{ marginBottom: 5, marginLeft: 5, width: 100 }}
          error={formData.compatible_power_stage_stand.emptyError}
          helperText={formData.compatible_power_stage_stand.emptyError?emptyErrorMessage:""}
        /></Grid>
        <Grid container="true">
         <TextField
          required
          label="SN des kompatiblen Control Boards"
          name="compatible_control_board_sn"
          value={formData.compatible_control_board_sn.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("compatible_control_board_sn", formData.compatible_control_board_sn.value)}
          sx={{ marginBottom: 5, width: 350 }}
          error={formData.compatible_control_board_sn.emptyError || formData.compatible_control_board_sn.formatError}
          helperText={formData.compatible_control_board_sn.emptyError?emptyErrorMessage:formData.compatible_control_board_sn.formatError
            ?formatErrorMessage:""}
        />
         <TextField
          required
          label="Stand"
          name="compatible_control_board_stand"
          value={formData.compatible_control_board_stand.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("compatible_control_board_stand", formData.compatible_control_board_stand.value)}
          sx={{ marginBottom: 5, marginLeft: 5, width: 100 }}
          error={formData.compatible_control_board_stand.emptyError}
          helperText={formData.compatible_control_board_stand.emptyError?emptyErrorMessage:""}
        /></Grid>
        <Grid container="true">
         <TextField
          required
          label="SN des kompatiblen Spike Supression Boards"
          name="compatible_spike_supression_board_sn"
          value={formData.compatible_spike_supression_board_sn.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("compatible_spike_supression_board_sn", formData.compatible_spike_supression_board_sn.value)}
          sx={{ marginBottom: 5, width: 350 }}
          error={formData.compatible_spike_supression_board_sn.emptyError || formData.compatible_spike_supression_board_sn.formatError}
          helperText={formData.compatible_spike_supression_board_sn.emptyError?emptyErrorMessage:formData.compatible_spike_supression_board_sn.formatError
            ?formatErrorMessage:""}
        />
        <TextField
          required
          label="Stand"
          name="compatible_spike_supression_board_stand"
          value={formData.compatible_spike_supression_board_stand.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("compatible_spike_supression_board_stand", formData.compatible_spike_supression_board_stand.value)}
          sx={{ marginBottom: 5, marginLeft: 5, width: 100 }}
          error={formData.compatible_spike_supression_board_stand.emptyError}
          helperText={formData.compatible_spike_supression_board_stand.emptyError?emptyErrorMessage:""}
        /></Grid>
        <Grid container="true">
         <TextField
          required
          label="SN des kompatiblen EMV Boards"
          name="compatible_emv_board_sn"
          value={formData.compatible_emv_board_sn.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("compatible_emv_board_sn", formData.compatible_emv_board_sn.value)}
          sx={{ marginBottom: 5, width: 350}}
          error={formData.compatible_emv_board_sn.emptyError || formData.compatible_emv_board_sn.formatError}
          helperText={formData.compatible_emv_board_sn.emptyError?emptyErrorMessage:formData.compatible_emv_board_sn.formatError
            ?formatErrorMessage:""}
        />
        <TextField
          required
          label="Stand"
          name="compatible_emv_board_stand"
          value={formData.compatible_emv_board_stand.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("compatible_emv_board_stand", formData.compatible_emv_board_stand.value)}
          sx={{ marginBottom: 5, marginLeft: 5, width: 100  }}
          error={formData.compatible_emv_board_stand.emptyError}
          helperText={formData.compatible_emv_board_stand.emptyError?emptyErrorMessage:""}
        /></Grid>
        <Grid container="true">
         <TextField
          required
          label="SN des kompatiblen Signalboards"
          name="compatible_signalboard_sn"
          value={formData.compatible_signalboard_sn.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("compatible_signalboard_sn", formData.compatible_signalboard_sn.value)}
          sx={{ marginBottom: 5, width: 350 }}
          error={formData.compatible_signalboard_sn.emptyError || formData.compatible_signalboard_sn.formatError}
          helperText={formData.compatible_signalboard_sn.emptyError?emptyErrorMessage:formData.compatible_signalboard_sn.formatError
            ?formatErrorMessage:""}
        />
        <TextField
          required
          label="Stand"
          name="compatible_signalboard_stand"
          value={formData.compatible_signalboard_stand.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("compatible_signalboard_stand", formData.compatible_signalboard_stand.value)}
          sx={{ marginBottom: 5, marginLeft: 5, width: 100 }}
          error={formData.compatible_signalboard_stand.emptyError}
          helperText={formData.compatible_signalboard_stand.emptyError?emptyErrorMessage:""}
        />
        </Grid>
        <TextField
          label="SN des kompatiblen EMI Shields"
          name="compatible_emi_shield_sn"
          value={formData.compatible_emi_shield_sn.value}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.compatible_emi_shield_sn.formatError}
          helperText={formData.compatible_emi_shield_sn.formatError?formatErrorMessage:""}
        />
        <TextField
          label="SN des kompatiblen EPLC Boards"
          name="compatible_eplc_board_sn"
          value={formData.compatible_eplc_board_sn.value}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.compatible_eplc_board_sn.formatError}
          helperText={formData.compatible_eplc_board_sn.formatError?formatErrorMessage:""}
        />
        <FormControl required fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="case_type-lbl">Deckeltyp</InputLabel>
              <Select
                fullWidth
                labelId="case_type-lbl"
                id="case_type"
                label="Deckeltyp"
                name="case_type"
                value={formData.case_type.value}
                onChange={handleChange}
              >
                  <MenuItem value={"0001 - Stercom"}>0001 - Stercom</MenuItem>
                  <MenuItem value={"0002 - Refu"}>0002 - Refu</MenuItem>
                  <MenuItem value={"0003 - Blanco"}>0003 - Blanco</MenuItem>
                  <MenuItem value={"0004 - Deutz"}>0004 - Deutz</MenuItem>
              </Select>
        </FormControl>
        
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="body1">
              Steckertyp
            </Typography>
          </Grid>
          <Grid item>
            <ToggleButtonGroup 
              color="primary" 
              value={formData.power_plug.value} 
              exclusive 
              onChange={(event, newValue) => {handleChange({target: {name: "power_plug",value: newValue}});}} 
              aria-label="power_plug" 
              name="power_plug" 
              id="power_plug">
              <ToggleButton value="EU">EU</ToggleButton>
              <ToggleButton value="US">US</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Abbrechen
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {article ? "Aktualisieren" : "Erstellen"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArticleForm;
