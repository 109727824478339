import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Grid, TextField, Button, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link as RouterLink } from "react-router-dom";
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ConfirmResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [open, setOpen] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  console.log("Token:", token);

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Überprüfen, ob das neue Passwort und das bestätigte Passwort übereinstimmen
    if (newPassword !== confirmPassword) {
      alert("Die eingegebenen Passwörter stimmen nicht überein.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/auth/confirm-reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, newPassword }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (response.status === 200) {
        setOpen(true);
      } else {
        alert(`Fehler: ${data.error}`);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.");
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ position: "absolute", left: 2, top: 2 }}>
          <Button
            component={RouterLink}
            to="/"
            startIcon={<ArrowBackIosIcon />}
          >
            Zurück
          </Button>
        </Box>
        <Typography component="h1" variant="h5">
          Passwort zurücksetzen
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ width: "100%", mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="newPassword"
            label="Neues Passwort"
            name="newPassword"
            type="password"
            autoComplete="new-password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="confirmPassword"
            label="Passwort bestätigen"
            name="confirmPassword"
            type="password"
            autoComplete="new-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Passwort ändern
          </Button>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="success-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="success-dialog-title" sx={{ position: "relative" }}>
          <CheckCircleIcon
            sx={{
              color: "green",
              fontSize: "3rem",
              verticalAlign: "middle",
              marginRight: 1,
            }}
          />
          Passwort erfolgreich geändert
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      </Dialog>
    </Container>
  );
};

export default ConfirmResetPassword;
