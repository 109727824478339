import React, { useEffect, useState } from "react";
import {
  Snackbar
 } from "@mui/material";
import axios from "axios";
import ScanForm from "../scanForm/scanForm";

const snDeviceFormat = new RegExp("^#[0-9]{6}$");
const snPowerStageFormat = new RegExp("^SC[0-9]{3}29.[0-9]{1}-[0-9]{1}\/#[0-9]{3,}$");
const snControlBoardFormat = new RegExp("^SC[0-9]{3}30.[0-9]{1}-[0-9]{1}\/#[0-9]{3,}$");
const snSpikeSupressionBoardFormat = new RegExp("^SC[0-9]{3}4(1|3){1}.[0-9]{1}-[0-9]{1}\/#[0-9]{3,}$");
const snEmvBoardFormat = new RegExp("^SC[0-9]{3}40.[0-9]{1}-[0-9]{1}\/#[0-9]{3,}$");
const snSignalboardFormat = new RegExp("^SC[0-9]{3}31.[0-9]{1}-[0-9]{1}\/#[0-9]{3,}$");
const snEmiShieldFormat = new RegExp("^SC[0-9]{3}74.[0-9]{1}-[0-9]{1}\/#[0-9]{3,}$");
const snEplcBoardFormat = new RegExp("^SC[0-9]{3}39.[0-9]{1}-[0-9]{1}\/#[0-9]{3,}$");
const bmkNumberFormat = new RegExp("^[0-9]{10}$");
const componentIdFormat = new RegExp("^#[0-9]{4}$");

function JobListForm({ onClose, onSubmit, taskId }) {

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [notCompatibleWithArticleMessage, setNotCompatibleWithArticleMessage] = useState("");

  const [isMounted, setIsMounted] = useState(true);
  const [newDeviceCreated, setNewDeviceCreated] = useState(false);
  const [snInput, setSNInput] = useState("");
  const [snFieldValue, setSNFieldValue] = useState("");
  const [standInput, setStandInput] = useState("");
  const [articleType, setArticleType] = useState(null);
  const [orderPosition, setOrderPosition] = useState(null);
  const [standardSoftware, setStandardSoftware] = useState(null);
  const [customerSoftware, setCustomerSoftware] = useState(null);

  const [deviceId, setDeviceId] = useState("");
  const [componentId, setComponentId] = useState("");
  const [powerStageSN, setPowerStageSN] = useState(null);
  const [powerStageStand, setPowerStageStand] = useState(null);
  const [controlBoardSN, setControlBoardSN] = useState(null);
  const [controlBoardStand, setControlBoardStand] = useState(null);
  const [spikeSupressionBoardSN, setSpikeSupressionBoardSN] = useState(null);
  const [spikeSupressionBoardStand, setSpikeSupressionBoardStand] = useState(null);
  const [emvBoardSN, setEmvBoardSN] = useState(null);
  const [emvBoardStand, setEmvBoardStand] = useState(null);
  const [signalboardSN, setSignalboardSN] = useState(null);
  const [signalboardStand, setSignalboardStand] = useState(null);
  const [emiShieldSN, setEmiShieldSN] = useState(null);
  const [eplcBoardSN, setEplcBoardSN] = useState(null);
  const [previousSNFormat, setPreviousSNFormat] = useState(null);
  const [allChecked, setAllChecked] = useState(false);

  const [loadingDevice, setLoadingDevice] = useState(true);
  const [loadingPowerStage, setLoadingPowerStage] = useState(true);
  const [loadingControlBoard, setLoadingControlBoard] = useState(true);
  const [loadingSpikeSupressionBoard, setLoadingSpikeSupressionBoard] = useState(true);
  const [loadingEmvBoard, setLoadingEmvBoard] = useState(true);
  const [loadingSignalboard, setLoadingSignalboard] = useState(true);
  const [loadingEmiShield, setLoadingEmiShield] = useState(true);
  const [loadingEplcBoard, setLoadingEplcBoard] = useState(true);

  const [deviceChecked, setDeviceChecked] = useState(false);
  const [powerStageChecked, setPowerStageChecked] = useState(false);
  const [controlBoardChecked, setControlBoardChecked] = useState(false);
  const [spikeSupressionBoardChecked, setSpikeSupressionBoardChecked] = useState(false);
  const [emvBoardChecked, setEmvBoardChecked] = useState(false);
  const [signalboardChecked, setSignalboardChecked] = useState(false);
  const [emiShieldChecked, setEmiShieldChecked] = useState(false);
  const [eplcBoardChecked, setEplcBoardChecked] = useState(false);

  const [bmkNumber, setBmkNumber] = useState(null);
  const [bmkPowerStage, setBmkPowerStage] = useState(null);
  const [bmkControlBoard, setBmkControlBoard] = useState(null);
  const [bmkSpikeSupressionBoard, setBmkSpikeSupressionBoard] = useState(null);
  const [bmkEmvBoard, setBmkEmvBoard] = useState(null);
  const [bmkSignalboard, setBmkSignalboard] = useState(null);
  const [bmkEmiShield, setBmkEmiShield] = useState(null);
  const [bmkEplcBoard, setBmkEplcBoard] = useState(null);
  const inputRef = React.useRef();

  useEffect(() => {
    //Focus the SN input field when opening the dialog
    inputRef.current.focus();

    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/tasks/${taskId}`);
        const taskData = response.data;
        fetchOrderPosition(taskData.order_nr, taskData.position_id);
        fetchArticleTypeByPositionAndOrder(taskData.position_id, taskData.order_nr);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [taskId]);

  useEffect(() => {
    if(orderPosition) {
      fetchStandardSoftware(orderPosition.standard_software_id);
      fetchCustomerSoftware(orderPosition.customer_software_id);
    }
  }, [orderPosition])

  useEffect(() => {
    if (powerStageChecked && controlBoardChecked
       && spikeSupressionBoardChecked && emvBoardChecked && signalboardChecked 
       && ((emiShieldSN && emiShieldChecked) || !loadingEmiShield)
        && ((eplcBoardSN && eplcBoardChecked) || !loadingEplcBoard)) {
            setAllChecked(true);
          } else {
            setAllChecked(false);
    }
  }, [powerStageChecked, controlBoardChecked, spikeSupressionBoardChecked, emvBoardChecked,
    signalboardChecked, emiShieldChecked, emiShieldSN, eplcBoardSN, eplcBoardChecked]);

  useEffect(() => {
    if (allChecked && bmkPowerStage && bmkControlBoard && bmkSpikeSupressionBoard && bmkEmvBoard && bmkSignalboard
        && (bmkEmiShield || (!loadingEmiShield && !emiShieldSN))
        && (bmkEplcBoard || !loadingEplcBoard && !eplcBoardSN)) {
            handleSubmit();
    }
    }, [allChecked, bmkPowerStage, bmkControlBoard, bmkSpikeSupressionBoard, bmkEmvBoard,
      bmkSignalboard, bmkEmiShield, bmkEplcBoard, eplcBoardSN, eplcBoardChecked]);

  //Hanlde input of first scan when device ID is set
  useEffect(() => {
    if(newDeviceCreated) {
      handleSNInput(standInput);
    }
  }, [deviceId])

  const fetchArticleTypeByPositionAndOrder = async (positionId, orderId) => {
    try {
      const response = await axios.get(
        `/api/articleTypes/position/${positionId}/order/${orderId}`
      );
      setLoadingEplcBoard(response.data.compatible_eplc_board_sn && response.data.compatible_eplc_board_sn !== "");
      setLoadingEmiShield(response.data.compatible_emi_shield_sn && response.data.compatible_emi_shield_sn!== "");
      setNotCompatibleWithArticleMessage(`nicht mit Artikeltyp ${response.data.article_number} Stand ${response.data.article_stand} kompatibel.`);
      setArticleType(response.data);

    } catch (error) {
      console.error("Error fetching order positions:", error);
      return [];
    }
  };

  const fetchOrderPosition = async (orderId, positionId) => {
    try {

      const response = await axios.get(`/api/orderPositions/${orderId}`);
      const responseData = response.data;

      const op = responseData.filter(p => p.position_id === positionId)

      setOrderPosition(op[0]);

    } catch (error) {
      console.error("Error fetching order position:", error);
      return [];
    }
  };

  const fetchStandardSoftware = async (softwareId) => {
    try {
      const customerSoftwareResponse = await axios.get(
        `/api/software/data/${softwareId}`
      );
      setStandardSoftware(customerSoftwareResponse.data);

    } catch (error) {
      console.error("Error fetching standard software:", error);
      return [];
    }
  };

  const fetchCustomerSoftware = async (softwareId) => {
    try {
      const customerSoftwareResponse = await axios.get(
        `/api/software/data/${softwareId}`
      );
      setCustomerSoftware(customerSoftwareResponse.data);

    } catch (error) {
      console.error("Error fetching customer software:", error);
      return [];
    }
  };

  const fetchDevice = async (deviceId) => {
    try {
      const response = await axios.get(
        `/api/devices/${deviceId}`
      );

      return response.data;

    } catch (error) {
      console.error("Error fetching device:", error);
      return [];
    }
  };

  const updateDevice = async (deviceId, deviceData) => {
    try {
      const existingDevice = await fetchDevice(deviceId);
      await axios.put(`/api/devices/${deviceId}`, deviceData);
      if (newDeviceCreated) {
        showSnackbar(`Seriennummer wurde geprüft und zu Device ${deviceId} hinzugefügt.`);
      } else {
        const newSN = Object.values(deviceData)[0];
        const newStand = Object.values(deviceData)[1];
        const existingSN = existingDevice[Object.keys(deviceData)[0]];
        const existingStand = existingDevice[Object.keys(deviceData)[1]];
        const overWrittenSN = newSN === existingSN ? "" : existingSN;
        const overWrittenStand = newStand === existingStand ? "" : existingStand;
        overWrittenSN + overWrittenStand === "" ? showSnackbar(`Seriennummer wurde geprüft und zu Device ${deviceId} hinzugefügt.`)
        : overWrittenStand === "" ? showSnackbar(`Seriennummer wurde geprüft. \n
        Bei Device ${deviceId} wurde ${overWrittenSN} mit ${newSN} überschrieben.`)
        : showSnackbar(`Seriennummer wurde geprüft. \n
        Bei Device ${deviceId} wurde ${overWrittenSN} Stand ${overWrittenStand}  mit ${overWrittenSN ? newSN : ""} Stand ${newStand} überschrieben.`)
      }
    } catch (error) {
      console.error("Error updating device:", error);
    }
  }
  
  const getCurrentYear = () => {
    return new Date().toLocaleDateString('de', {year: '2-digit'});
  }
  
  const getLatestDeviceId = async () => {
    const responseLatestDeviceId = await axios.get(`api/devices/latest/deviceId`);
  
    const latestDeviceId = responseLatestDeviceId.data.max;

    if((latestDeviceId && !latestDeviceId.toString().startsWith(getCurrentYear()))
    || !latestDeviceId) {
      return parseInt(getCurrentYear() + "0000");
    }

    return latestDeviceId;
  }

  const getLatestComponentId = async () => {
    const responseLatestComponentId = await axios.get("/api/devices/latest/componentId");

    const latestComponentId = responseLatestComponentId.data.max;

    if((latestComponentId && !latestComponentId.toString().startsWith(getCurrentYear()))
    || !latestComponentId) {
      return parseInt(getCurrentYear() + "00");
    }

    return latestComponentId;
  }


  const handleSNInputChange = (event) => {
    const sn = event.target.value;
    const focusedElement = document.activeElement;
    if(focusedElement.name === "sn-field") {
      setSNFieldValue(sn);
    }
    setSNInput(sn);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && (snInput.match(snEmiShieldFormat) || 
    (snInput.match(snEplcBoardFormat) || snInput.match(snEmiShieldFormat) || snInput.match(componentIdFormat) || snInput.match(snDeviceFormat) || snInput.match(bmkNumberFormat)))) {
      handleSNInput("");
    }
  }

  const handleSNInput = async (standInput) => {
    setStandInput(standInput);

    /* DEVICE ID */
    if(isMounted)  {
      setIsMounted(false);
      let deviceData = false;
      if(snInput.match(componentIdFormat)) {
        const cpId = snInput.slice(1);
        deviceData = await getDeviceInformationByComponentId(cpId);
      }
      else {
        const sn = snInput.match(snDeviceFormat) ? snInput.slice(1) :  snInput;
        deviceData = await getDeviceInformation(sn, standInput);
      }

      if (deviceData.device_id) {
        const existingTask = await deviceIdExistsInTask(deviceData.device_id);

        if (existingTask?.device_id == deviceData.device_id) {
          showSnackbar(`Gerät wurde bereits in Task ${existingTask.tasks_id} gescannt.`)
          return;
        }
        setDeviceInformation(deviceData);
      }
      else if (deviceData.component_id){
        const newDeviceId = deviceId ? deviceId : await getLatestDeviceId() + 1;
        setDeviceInformation(deviceData);
        setDeviceId(newDeviceId);
        setComponentId(deviceData.component_id);
        await axios.put(`/api/devices/component/${deviceData.component_id}`, { device_id: newDeviceId });
      } else if (snInput.match(snDeviceFormat)) {
        await createNewDevice(snInput.slice(1));
      } else {
        await createNewDevice();
      }
    }

    /* POWER STAGE */
    else if (snInput.match(snPowerStageFormat)) {
      if(snInput.startsWith(articleType.compatible_power_stage_sn) && articleType.compatible_power_stage_stand === standInput) 
      {
        setPowerStageChecked(true);
        await updateDevice(deviceId, {sn_power_stage: snInput, sn_power_stage_stand: standInput});
      }
        else {
          snInput.startsWith(articleType.compatible_power_stage_sn) ? showSnackbar("Power Stage Stand " + notCompatibleWithArticleMessage)
          : showSnackbar("Power Stage SN " + notCompatibleWithArticleMessage);
          setPowerStageChecked(false);
        }
        setPowerStageSN(snInput);
        setPreviousSNFormat("sn-power-stage");
        setPowerStageStand(standInput);
        setLoadingPowerStage(false);
    }

    /* CONTROL BOARD */
    else if (snInput.match(snControlBoardFormat)) {
      if(snInput.startsWith(articleType.compatible_control_board_sn) && articleType.compatible_control_board_stand === standInput) {
        setControlBoardChecked(true);
        await updateDevice(deviceId, {sn_control_board: snInput, sn_control_board_stand: standInput});
      } else {
        snInput.startsWith(articleType.compatible_control_board_sn) ? showSnackbar("Control Board Stand " + notCompatibleWithArticleMessage)
        : showSnackbar("Control Board Stand " + notCompatibleWithArticleMessage);
        setControlBoardChecked(false);
      }
      setControlBoardSN(snInput);
      setPreviousSNFormat("sn-control-board");
      setControlBoardStand(standInput);
      setLoadingControlBoard(false);
    }

    /* SPIKE SUPRESSION BOARD */
    else if (snInput.match(snSpikeSupressionBoardFormat)) {
      if(snInput.startsWith(articleType.compatible_spike_supression_board_sn) && articleType.compatible_spike_supression_board_stand === standInput) {
        setSpikeSupressionBoardChecked(true);
        await updateDevice(deviceId, {sn_spike_supression_board: snInput, sn_spike_supression_board_stand: standInput});

      } else {
        snInput.startsWith(articleType.compatible_spike_supression_board_sn) ? showSnackbar("Spike Supression Board Stand " + notCompatibleWithArticleMessage)
        : showSnackbar("Spike Supression Board SN " + notCompatibleWithArticleMessage);
        setSpikeSupressionBoardChecked(false);
      }
      setSpikeSupressionBoardSN(snInput);
      setPreviousSNFormat("sn-spike-supression-board");
      setSpikeSupressionBoardStand(standInput);
      setLoadingSpikeSupressionBoard(false);
    }

    /* EMV BOARD */
    else if (snInput.match(snEmvBoardFormat)) {
      if(snInput.startsWith(articleType.compatible_emv_board_sn) && articleType.compatible_emv_board_stand === standInput) {
        setEmvBoardChecked(true);
        await updateDevice(deviceId, {sn_emv_board: snInput, sn_emv_board_stand: standInput});
      } else {
        snInput.startsWith(articleType.compatible_emv_board_sn) ? showSnackbar("EMV Board Stand " + notCompatibleWithArticleMessage)
        :  showSnackbar("EMV Board SN " + notCompatibleWithArticleMessage);
        setEmvBoardChecked(false);
      }
      setEmvBoardSN(snInput);
      setPreviousSNFormat("sn-emv-board");
      setEmvBoardStand(standInput);
      setLoadingEmvBoard(false);
    }

    /* SIGNALBOARD */
    else if (snInput.match(snSignalboardFormat)) {
      if(snInput.startsWith(articleType.compatible_signalboard_sn) && articleType.compatible_signalboard_stand === standInput) {
        setSignalboardChecked(true);
        await updateDevice(deviceId, {sn_signalboard: snInput, sn_signalboard_stand: standInput});
      } else {
        snInput.startsWith(articleType.compatible_signalboard_sn) ? showSnackbar("Signalboard Stand " + notCompatibleWithArticleMessage)
        : showSnackbar("Signalboard SN " + notCompatibleWithArticleMessage);
        setSignalboardChecked(false);
      }
      setSignalboardSN(snInput);
      setPreviousSNFormat("sn-signalboard");
      setSignalboardStand(standInput);
      setLoadingSignalboard(false);
    }

    /* EMI SHIELD */
    else if (snInput.match(snEmiShieldFormat) && articleType.compatible_emi_shield_sn) {
      if(snInput.startsWith(articleType.compatible_emi_shield_sn)) {
        setEmiShieldChecked(true);
        await updateDevice(deviceId, {sn_emi_shield: snInput});
      } else {
        showSnackbar("EMI Shield SN " + notCompatibleWithArticleMessage);
        setEmiShieldChecked(false);
      }
      setEmiShieldSN(snInput);
      setPreviousSNFormat("sn-emi-shield");
      setLoadingEmiShield(false);
    }

    /* EPLC BOARD */
    else if (snInput.match(snEplcBoardFormat) && articleType.compatible_eplc_board_sn) {
      if(snInput.startsWith(articleType.compatible_eplc_board_sn)) {
        setEplcBoardChecked(true);
        await updateDevice(deviceId, {sn_eplc_board: snInput});
      } else {
        showSnackbar("EPLC Board SN " + notCompatibleWithArticleMessage);
        setEplcBoardChecked(false);
      }
      setEplcBoardSN(snInput);
      setPreviousSNFormat("sn-eplc-board");
      setLoadingEplcBoard(false);
    }

    /* BMK NUMBER as first input */
    else if (snInput.match(bmkNumberFormat) && !previousSNFormat && document.activeElement.name === "sn-field") {
      setBmkNumber(snInput);
      await updateDevice(deviceId, {bmk_number: snInput});
    }

    /* BMK NUMBER in bmk input field */
    else if (snInput.match(bmkNumberFormat) && (document.activeElement.name.startsWith("bmk-"))) {
      switch (document.activeElement.name) {
        case "bmk-power-stage":
          setBmkPowerStage(snInput);
          await updateDevice(deviceId, {bmk_power_stage: snInput});
          break;
        case "bmk-control-board":
          setBmkControlBoard(snInput);
          await updateDevice(deviceId, {bmk_control_board: snInput});
          break;
        case "bmk-spike-supression-board":
          setBmkSpikeSupressionBoard(snInput);
          await updateDevice(deviceId, {bmk_spike_supression_board: snInput});
          break;
        case "bmk-emv-board":
          setBmkEmvBoard(snInput);
          await updateDevice(deviceId, {bmk_emv_board: snInput});
          break;
        case "bmk-signalboard":
          setBmkSignalboard(snInput);
          await updateDevice(deviceId, {bmk_signalboard: snInput});
          break;
        case "bmk-emi-shield":
          setBmkEmiShield(snInput);
          await updateDevice(deviceId, {bmk_emi_shield: snInput});
          break;
        case "bmk-eplc-board":
          setBmkEplcBoard(snInput);
          await updateDevice(deviceId, {bmk_eplc_board: snInput});
          break;
      }
    }

    /* BMK NUMBER in main input field depending on previous SN */
    else if (snInput.match(bmkNumberFormat) && previousSNFormat && document.activeElement.name === "sn-field") {
      switch (previousSNFormat) {
        case "sn-power-stage":
          setBmkPowerStage(snInput);
          await updateDevice(deviceId, {bmk_power_stage: snInput});
          break;
        case "sn-control-board":
          setBmkControlBoard(snInput);
          await updateDevice(deviceId, {bmk_control_board: snInput});
          break;
        case "sn-spike-supression-board":
          setBmkSpikeSupressionBoard(snInput);
          await updateDevice(deviceId, {bmk_spike_supression_board: snInput});
          break;
        case "sn-emv-board":
          setBmkEmvBoard(snInput);
          await updateDevice(deviceId, {bmk_emv_board: snInput});
          break;
        case "sn-signalboard":
          setBmkSignalboard(snInput);
          await updateDevice(deviceId, {bmk_signalboard: snInput});
          break;
        case "sn-emi-shield":
          setBmkEmiShield(snInput);
          await updateDevice(deviceId, {bmk_emi_shield: snInput});
          break;
        case "sn-eplc-board":
          setBmkEplcBoard(snInput);
          await updateDevice(deviceId, {bmk_eplc_board: snInput});
          break;
      }
    }

    //After SN check reset SN input field and Stand
    setSNInput("");
    setSNFieldValue("");
    setStandInput("");

    //Focus the SN input field again
    inputRef.current.focus();
  }

  const getDeviceInformation = async (sn, stand) => {
    try {
      const standOrEmptyString = stand ? stand === "" ? "''" : stand : "''";

      const response = await axios.get(`/api/devices/sn/${encodeURIComponent(sn)}/stand/${standOrEmptyString}`);
      return response.data;
    } catch(error) {
      console.log(error);
    }
  }

  const getDeviceInformationByComponentId = async (cpId) => {
    try {
      const response = await axios.get(`/api/devices/component_id/${cpId}`);
      return response.data;
    } catch(error) {
      console.log(error);
    }
  }

  const deviceIdExistsInTask = async (id) => {
    try {
      if (!id) return [];

      const response = await axios.get(`/api/tasks/device/${id}`);

      return response.data;
    } catch(error) {
      console.log(error);
    }
  }

  const setDeviceInformation = (deviceData) => {

      /* DEVICE ID */
      if(deviceData.device_id) {
        setDeviceId(deviceData.device_id);
        setComponentId(deviceData.component_id);
        setLoadingDevice(false);
        setDeviceChecked(true);
      }

      /* POWER STAGE */
      if (deviceData.sn_power_stage && deviceData.sn_power_stage.match(snPowerStageFormat)) {
        if(deviceData.sn_power_stage.startsWith(articleType.compatible_power_stage_sn) && articleType.compatible_power_stage_stand === deviceData.sn_power_stage_stand) 
        {
          setPowerStageChecked(true);
        }
          else {
            setPowerStageChecked(false);
          }
          setPowerStageSN(deviceData.sn_power_stage);
          setPowerStageStand(deviceData.sn_power_stage_stand);
          setLoadingPowerStage(false);
      }

      /* CONTROL BOARD */
      if (deviceData.sn_control_board && deviceData.sn_control_board.match(snControlBoardFormat)) {
        if(deviceData.sn_control_board.startsWith(articleType.compatible_control_board_sn) && articleType.compatible_control_board_stand === deviceData.sn_control_board_stand) {
          setControlBoardChecked(true);
        } else {
          setControlBoardChecked(false);
        }
        setControlBoardSN(deviceData.sn_control_board);
        setControlBoardStand(deviceData.sn_control_board_stand);
        setLoadingControlBoard(false);
      }

      /* SPIKE SUPRESSION BOARD */
      if (deviceData.sn_spike_supression_board && deviceData.sn_spike_supression_board.match(snSpikeSupressionBoardFormat)) {
        if(deviceData.sn_spike_supression_board.startsWith(articleType.compatible_spike_supression_board_sn) && articleType.compatible_spike_supression_board_stand === deviceData.sn_spike_supression_board_stand) {
          setSpikeSupressionBoardChecked(true);
        } else {
          setSpikeSupressionBoardChecked(false);
        }
        setSpikeSupressionBoardSN(deviceData.sn_spike_supression_board);
        setSpikeSupressionBoardStand(deviceData.sn_spike_supression_board_stand);
        setLoadingSpikeSupressionBoard(false);
      }

      /* EMV BOARD */
      if (deviceData.sn_emv_board && deviceData.sn_emv_board.match(snEmvBoardFormat)) {
        if(deviceData.sn_emv_board.startsWith(articleType.compatible_emv_board_sn) && articleType.compatible_emv_board_stand === deviceData.sn_emv_board_stand) {
          setEmvBoardChecked(true);
        } else {
          setEmvBoardChecked(false);
        }
        setEmvBoardSN(deviceData.sn_emv_board);
        setEmvBoardStand(deviceData.sn_emv_board_stand);
        setLoadingEmvBoard(false);
      }

      /* SIGNALBOARD */
      if (deviceData.sn_signalboard && deviceData.sn_signalboard.match(snSignalboardFormat)) {
        if(deviceData.sn_signalboard.startsWith(articleType.compatible_signalboard_sn) && articleType.compatible_signalboard_stand === deviceData.sn_signalboard_stand) {
          setSignalboardChecked(true);
        } else {
          setSignalboardChecked(false);
        }
        setSignalboardSN(deviceData.sn_signalboard);
        setSignalboardStand(deviceData.sn_signalboard_stand);
        setLoadingSignalboard(false);
      }

      /* EMI SHIELD */
      if (deviceData.sn_emi_shield && deviceData.sn_emi_shield.match(snEmiShieldFormat) && articleType.compatible_emi_shield_sn) {
        if(deviceData.sn_emi_shield.startsWith(articleType.compatible_emi_shield_sn)) {
          setEmiShieldSN(deviceData.sn_emi_shield);
          setEmiShieldChecked(true);
        } else {
          setEmiShieldSN(deviceData.sn_emi_shield);
          setEmiShieldChecked(false);
        }
        setLoadingEmiShield(false);
      }

      /* EPLC BOARD */
      if (deviceData.sn_eplc_board && deviceData.sn_eplc_board.match(snEplcBoardFormat) && articleType.compatible_eplc_board_sn) {
        if(deviceData.sn_eplc_board.startsWith(articleType.compatible_eplc_board_sn)) {
          setEplcBoardSN(deviceData.sn_eplc_board);
          setEplcBoardChecked(true);
        } else {
          setEplcBoardSN(deviceData.sn_eplc_board);
          setEplcBoardChecked(false);
        }
        setLoadingEplcBoard(false);
      }

      /* BMK NUMBER */
      if (deviceData.bmk_number && deviceData.bmk_number.toString().match(bmkNumberFormat)) {
      setBmkNumber(deviceData.bmk_number);
      }

      /* BMK POWER STAGE */
        if (deviceData.bmk_power_stage && deviceData.bmk_power_stage.toString().match(bmkNumberFormat)) {
          setBmkPowerStage(deviceData.bmk_power_stage);
      }

      /* BMK CONTROL BOARD */
      if (deviceData.bmk_control_board && deviceData.bmk_control_board.toString().match(bmkNumberFormat)) {
        setBmkControlBoard(deviceData.bmk_control_board);
      }
      /* BMK SPIKE SUPPRESSION BOARD */
      if (deviceData.bmk_spike_supression_board && deviceData.bmk_spike_supression_board.toString().match(bmkNumberFormat)) {
        setBmkSpikeSupressionBoard(deviceData.bmk_spike_supression_board);
      }

      /* BMK EMV BOARD */
      if (deviceData.bmk_emv_board && deviceData.bmk_emv_board.toString().match(bmkNumberFormat)) {
        setBmkEmvBoard(deviceData.bmk_emv_board);
      }

      /* BMK SIGNALBOARD */
      if (deviceData.bmk_signalboard && deviceData.bmk_signalboard.toString().match(bmkNumberFormat)) {
        setBmkSignalboard(deviceData.bmk_signalboard);
      }

      /* BMK EMI SHIELD */
      if (deviceData.bmk_emi_shield && deviceData.bmk_emi_shield.toString().match(bmkNumberFormat)) {
        setBmkEmiShield(deviceData.bmk_emi_shield);
      }

      /* BMK EPLC BOARD */
      if (deviceData.bmk_eplc_board && deviceData.bmk_eplc_board.toString().match(bmkNumberFormat)) {
        setBmkEplcBoard(deviceData.bmk_eplc_board);
      }


    //After setting device information reset SN input field and Stand
    setSNInput("");
    setSNFieldValue("");
    setStandInput("");

    //Focus the SN input field again
    inputRef.current.focus();
  }

  const createNewDevice = async (deviceId) => {
    try {
      const newDeviceId = deviceId ? deviceId : await getLatestDeviceId() + 1;
      const newComponentId = await getLatestComponentId() + 1;

      const response = await axios.post(`/api/devices`, {component_id: newComponentId, device_id: newDeviceId})
      setNewDeviceCreated(true);
      setDeviceChecked(true);
      setDeviceId(response.data.deviceId);
      setLoadingDevice(false);
      showSnackbar(`OBC mit Seriennummer ${response.data.deviceId} wurde erstellt`);
    } catch(error) {
      console.log(error);
    }
  }

  const handleSubmit = async () => {
    const timestamp = new Date().toISOString();

    try {
      const response = await axios.put(`/api/tasks/${taskId}`, {
        test_date: timestamp,
        state: 2,
        device_id: deviceId,
      })
    } catch (error) {
      console.error(error);
    }

    const results = {
      ScanDate: timestamp,
      device_id : deviceId,
      article_voltage : articleType.voltage,
      article_power : articleType.power,
      article_current : articleType.current_output,
      article_modul_number : articleType.modul_number,
      eplc_software_id : orderPosition.eplc_software_id,
      standard_software_version : standardSoftware.version,
      standard_software_id : standardSoftware.software_id,
      customer_software_version : customerSoftware.version,
      customer_software_id : customerSoftware.software_id,
      parameter_set_id : orderPosition.parameter_set_id,
      case_type : orderPosition.case_type,
      new_result: 1
    }

    onSubmit(results);
    onClose();
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
              {ScanForm({
                  componentScan: false,
                  loadingDevice,
                  loadingPowerStage,
                  loadingControlBoard,
                  loadingSpikeSupressionBoard,
                  loadingEmvBoard,
                  loadingSignalboard,
                  loadingEmiShield,
                  loadingEplcBoard,
                  deviceChecked,
                  powerStageChecked,
                  controlBoardChecked,
                  spikeSupressionBoardChecked,
                  emvBoardChecked,
                  signalboardChecked,
                  emiShieldChecked,
                  eplcBoardChecked,
                  deviceId,
                  componentId,
                  powerStageSN,
                  controlBoardSN,
                  spikeSupressionBoardSN,
                  emvBoardSN,
                  signalboardSN,
                  emiShieldSN,
                  eplcBoardSN,
                  powerStageStand,
                  controlBoardStand,
                  spikeSupressionBoardStand,
                  emvBoardStand,
                  signalboardStand,
                  bmkPowerStage,
                  bmkControlBoard,
                  bmkSpikeSupressionBoard,
                  bmkEmvBoard,
                  bmkSignalboard,
                  bmkEmiShield,
                  bmkEplcBoard,
                  bmkNumber,
                  snInput,
                  snFieldValue,
                  inputRef,
                  allChecked,
                  handleSNInputChange,
                  handleKeyDown,
                  handleSNInput,
                  handleSubmit
              })
            }
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
}

export default JobListForm;
