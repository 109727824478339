import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Draggable from "react-draggable";
import moment from "moment-timezone";
import axios from "axios";

const DraggableDialog = (props) => {
  const handleDrag = (event) => {
    event.preventDefault();
  };

  return (
    <Draggable handle=".drag-handle" onDrag={handleDrag}>
      <Paper {...props} />
    </Draggable>
  );
};

const OrderEditForm = ({ isOpen, onClose, onSubmit, title, order }) => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(
    order?.customer_id || ""
  );
  const [selectedOrderType, setSelectedOrderType] = useState(
    order?.order_type || ""
  );
  const [orderTypes, setOrderTypes] = useState([]);
  const [formData, setFormData] = useState({
    order_id: order?.order_id || "",
    customer_id: order?.customer_id || "",
    order_date: order?.order_date || moment().format("YYYY-MM-DD"),
    order_type: order?.order_type || "",
  });

  useEffect(() => {
    fetchCustomers();
    fetchOrderTypes();
  }, []);

  useEffect(() => {
    setFormData({
      order_id: order?.order_id || "",
      customer_id: order?.customer_id || "",
      order_date: order?.order_date || moment().format("YYYY-MM-DD"),
      order_type: order?.order_type || "",
    });
    setSelectedCustomer(order?.customer_id || "");
    setSelectedOrderType(order?.order_type || "");
  }, [order]);

  async function fetchOrderTypes() {
    try {
      const response = await axios.get("/api/ordersWorkflow/orderTypes");
      console.log(response.data);
      setOrderTypes(response.data);
    } catch (error) {
      console.error("Error fetching order types:", error);
      console.log("Response status:", error.response.status);
      console.log("Response data:", error.response.data);
    }
  }

  async function fetchCustomers() {
    try {
      const response = await axios.get("/api/customersWorkflow");
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    // Handle selected order type
    if (name === "order_type") {
      setSelectedOrderType(value);
    }
  };

  const handleDateChange = (event, fieldName) => {
    const convertedDate = moment
      .tz(event.target.value, "YYYY-MM-DD", "Europe/Berlin")
      .format("YYYY-MM-DD");
    setFormData({ ...formData, [fieldName]: convertedDate });
  };

  const handleCustomerChange = (event) => {
    setSelectedCustomer(event.target.value);
    setFormData({ ...formData, customer_id: event.target.value });
  };

  const handleSubmit = () => {
    onSubmit(formData);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      PaperComponent={DraggableDialog}
    >
      <DialogTitle className="drag-handle">{title}</DialogTitle>
      <DialogContent>
        <TextField
          label="Bestellnummer"
          name="order_id"
          value={formData.order_id}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5, marginTop: 5 }}
        />
        <FormControl fullWidth variant="outlined" sx={{ marginBottom: 5 }}>
          <InputLabel id="customer-label">Kunde</InputLabel>
          <Select
            labelId="customer-label"
            id="customer"
            value={selectedCustomer}
            onChange={handleCustomerChange}
            label="Kunde"
          >
            <MenuItem value="">
              <em>Wählen Sie einen Kunden</em>
            </MenuItem>
            {customers.map((customer) => (
              <MenuItem key={customer.customer_id} value={customer.customer_id}>
                {customer.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Bestelldatum"
          name="order_date"
          type="date"
          value={formData.order_date}
          onChange={(event) => handleDateChange(event, "order_date")}
          fullWidth
          sx={{ marginBottom: 5 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl fullWidth variant="outlined" sx={{ marginBottom: 5 }}>
          <InputLabel id="order-type-label">Bestellart</InputLabel>
          <Select
            labelId="order-type-label"
            id="order_type"
            name="order_type"
            value={selectedOrderType}
            onChange={handleChange}
            label="Bestellart"
          >
            {orderTypes.map((orderType) => (
              <MenuItem key={orderType} value={orderType}>
                {orderType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Abbrechen
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {order ? "Aktualisieren" : "Erstellen"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderEditForm;
