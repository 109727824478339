import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Paper,
} from "@mui/material";
import Draggable from "react-draggable";
import moment from "moment-timezone";

const DraggableDialog = (props) => {
  const handleDrag = (event) => {
    event.preventDefault();
  };

  return (
    <Draggable handle=".drag-handle" onDrag={handleDrag}>
      <Paper {...props} />
    </Draggable>
  );
};
const ReportForm = ({ isOpen, onClose, onSubmit, title, report }) => {
  const [formData, setFormData] = useState({
    test_date_time: report?.test_date_time || moment().format("YYYY-MM-DD"),
    serial_number: report?.serial_number || "",
    tester: report?.tester || "",
    sw_version: report?.sw_version || "",
    hw_version: report?.hw_version || "",
    qr_code: report?.qr_code || "",
    item_number: report?.item_number || "",
    test_result: report?.test_result || "",
    test_report: report?.test_report || "",
    comment: report?.comment || "",
    order_id: report?.order_id || "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (event, fieldName) => {
    const convertedDate = moment
      .tz(event.target.value, "YYYY-MM-DD", "Europe/Berlin")
      .format("YYYY-MM-DD");
    setFormData({ ...formData, [fieldName]: convertedDate });
  };
  const handleSubmit = () => {
    if (report) {
      formData.id = report.id;
    }
    onSubmit(formData);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      PaperComponent={DraggableDialog}
    >
      <DialogTitle className="drag-handle">{title}</DialogTitle>
      <DialogContent>
        <TextField
          label="Datum"
          name="test_date_time"
          type="date"
          value={formData.test_date_time}
          onChange={(event) => handleDateChange(event, "test_date_time")}
          fullWidth
          sx={{ marginBottom: 5 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Seriennummer"
          name="serial_number"
          value={formData.serial_number}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5 }}
        />
        <TextField
          label="Tester/Prüfer"
          name="tester"
          value={formData.tester}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5 }}
        />
        <TextField
          label="SW Version"
          name="sw_version"
          value={formData.sw_version}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5 }}
        />
        <TextField
          label="HW Version"
          name="hw_version"
          value={formData.hw_version}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5 }}
        />
        <TextField
          label="QR-Code"
          name="qr_code"
          value={formData.qr_code}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5 }}
        />
        <TextField
          label="Artikel-Nr."
          name="item_number"
          value={formData.item_number}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5 }}
        />
        <TextField
          label="Test Ergebnis"
          name="test_result"
          value={formData.test_result}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5 }}
        />
        <TextField
          label="test_report"
          name="test_report"
          value={formData.test_report}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5 }}
        />
        <TextField
          label="Kommentar"
          name="comment"
          value={formData.comment}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5 }}
        />
        <TextField
          label="Bestellungs ID"
          name="order_id"
          value={formData.order_id}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {report ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportForm;
