import React, { useState } from "react";
import axios from "axios";
import { Grid, TextField, Button, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Link as RouterLink } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("/api/auth/forgot-password", { email });
      setMessage(response.data.message);
      if (response.status === 200) {
        setOpen(true);
      }
    } catch (error) {
      setMessage(error.response.data.error);
    }
  };
  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {" "}
        <Box sx={{ position: "absolute", left: 2, top: 2 }}>
          <Button
            component={RouterLink}
            to="/"
            startIcon={<ArrowBackIosIcon />}
          >
            Zurück
          </Button>
        </Box>
        <Typography component="h1" variant="h5">
          Passwort zurücksetzen
        </Typography>
        <Box
          component="form"
          onSubmit={handleResetPassword}
          noValidate
          sx={{ width: "100%", mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Passwort zurücksetzen
          </Button>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <DialogTitle id="success-dialog-title">
          <CheckCircleIcon
            sx={{
              color: "green",
              fontSize: "3rem",
              verticalAlign: "middle",
              marginRight: 1,
            }}
          />
          Erfolg
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" id="success-dialog-description">
            Eine E-Mail zum Zurücksetzen des Passworts wurde erfolgreich
            versendet.
          </Typography>
        </DialogContent>
      </Dialog>
    </Container>
  );
};
export default ResetPassword;
