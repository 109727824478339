import React, { useState, useEffect } from "react";
import axios from "axios";
import OrderForm from "./orderForm";
import OrderList from "./orderList";
import OrderEditForm from "./orderEditForm";
import OrderPositionForm from "../orderPosition/orderPositionForm";
import OrderPositionList from "../orderPosition/orderPositionList";
import { Container, Grid, Paper } from "@mui/material";

const SIDEBAR_OFFSET = "150px";

const Order = () => {
  const [orders, setOrders] = useState([]);
  const [orderPositions, setOrderPositions] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await axios.get("/api/ordersWorkflow");
      setOrders(response.data);
      setSelectedOrder(selectedOrder); // Does nothing, but triggers re-rendering of the Order List everytime orders are fetched.
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const handleOrderSubmit = async (orderData) => {
    try {
      const response = await axios.post("/api/ordersWorkflow", orderData);
      fetchOrders();
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  const handleOrderSelect = (order) => {
    setSelectedOrder(order);
  };

  return (
    <Container maxWidth="lg" sx={{ paddingTop: 4, paddingBottom: 4, marginLeft: SIDEBAR_OFFSET}}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ height: "100%" }}>
                <OrderForm
                  onSubmit={handleOrderSubmit}
                  fetchOrders={fetchOrders}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ height: "100%" }}>
                <OrderList
                  orders={orders}
                  onOrderSelect={handleOrderSelect}
                  selectedOrder={selectedOrder}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ height: "100%" }}>
                {selectedOrder && (
                  <OrderPositionForm
                    order={selectedOrder}
                    fetchOrders={fetchOrders}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ height: "100%" }}>
                {selectedOrder && <OrderPositionList order={selectedOrder} />}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Order;
