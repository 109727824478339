import React, { useState, useEffect } from "react";
import axios from "axios";
import SoftwareForm from "./softwareForm";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  TextField,
  Snackbar,
  Box,
  TablePagination,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from '@mui/icons-material/Download';

const Software = () => {
  const [software, setSoftware] = useState([]);
  const [filteredSoftware, setFilteredSoftware] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedSoftwareData, setSelectedSoftwareData] = useState(null);
  const [selectedSoftwareFile, setSelectedSoftwareFile] = useState(null);
  const [sortSoftware, setSortSoftware] = useState("asc");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsOptions = [5, 10, 15, 20];
  const [selectedColumn, setSelectedColumn] = useState("");
  const [checkboxPopupOpen, setCheckboxPopupOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState({
    software_id: true,
    name: true,
    version: true,
    software: true,
    software_type: true
  });

  const columnNames = {
    software_id: "ID",
    name: "Name",
    version: "Version",
    software: "Software",
    software_type: "Softwaretyp"
  };

  useEffect(() => {
    fetchSoftwareWithoutFile();
  }, []);

  const fetchSoftwareWithoutFile = async () => {
    try {
      const response = await axios.get("/api/software");
      setSoftware(response.data);
      setFilteredSoftware(response.data);
    } catch (error) {
      console.error("Error fetching software:", error);
    }
  };

  const fetchSelectedSoftwareFile = async (id) => {
    try {
      const response = await axios.get(`/api/software/file/${id}`);
      setSelectedSoftwareFile(response.data.software);
      return response.data.software;
    } catch (error) {
      console.error("Error fetching software file:", error);
    }
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const searchColumns = Object.keys(visibleColumns);
    setFilteredSoftware(
      software.filter((sw) =>
        searchColumns.some((column) =>
          sw[column].toString().toLowerCase().includes(searchValue)
        )
      )
    );
  };

  const handleCheckboxChange = (event) => {
    setVisibleColumns({
      ...visibleColumns,
      [event.target.name]: event.target.checked,
    });
  };

  const handleColumnSelect = (event) => {
    setSelectedColumn(event.target.value);
  };

  const handleSort = (field) => {
    const sortedSoftware = [...filteredSoftware].sort((a, b) => {
      if (sortSoftware === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });

    setFilteredSoftware(sortedSoftware);
    setSortSoftware(sortSoftware === "asc" ? "desc" : "asc");
  };

  const createSoftware = async (softwareData) => {
    let flatSoftwareData = {};
    Object.keys(softwareData).map((key) => flatSoftwareData[key] = softwareData[key].value);

    try {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("name", flatSoftwareData.name);
      formDataToSubmit.append("version", flatSoftwareData.version);
      formDataToSubmit.append("software_type", flatSoftwareData.software_type);
      formDataToSubmit.append("software", flatSoftwareData.software_file);

      const response = await axios.post("/php-uploader/index.php", formDataToSubmit);

      await fetchSoftwareWithoutFile();
    // showSnackbar(
    //   `Software mit ID ${response.data.software_id} wurde erfolgreich erstellt.`
    // );
    } catch (error) {
      console.error("Error creating software:", error);
    }
  };

  const updateSoftware = async (softwareData) => {
    let flatSoftwareData = {};
    Object.keys(softwareData).map((key) => flatSoftwareData[key] = softwareData[key].value);

    try {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("software_id", flatSoftwareData.software_id);
      formDataToSubmit.append("name", flatSoftwareData.name);
      formDataToSubmit.append("version", flatSoftwareData.version);
      formDataToSubmit.append("software", flatSoftwareData.software_file);
      formDataToSubmit.append("software_type", flatSoftwareData.software_type);

      const response = await axios.post("/php-uploader/index.php", formDataToSubmit);

      fetchSoftwareWithoutFile();
      // showSnackbar(
      //   `Software mit ID ${response.data.software_id} wurde erfolgreich aktualisiert.`
      // );
    } catch (error) {
      console.error("Error updating software:", error);
    }
  };

  const downloadSoftwareFile = async (id) => {
    try {
      const softwareFile = await fetchSelectedSoftwareFile(id);
      const uintArray = Uint8Array.from(softwareFile.data);
      const url = window.URL.createObjectURL(new Blob([uintArray.buffer], {type: "application/octet-stream"}));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Software_${id}.bin`);
      document.body.appendChild(link);
      link.click();
      showSnackbar(`Software mit ID ${id} wurde erfolgreich heruntergeladen.`);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the software file:", error);
      showSnackbar(`Fehler beim Herunterladen der Software mit ID ${id}.`);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/software/${id}`);
      setFilteredSoftware(filteredSoftware.filter((sw) => sw.id !== id));
      showSnackbar(`Software mit ID ${id} wurde erfolgreich gelöscht.`);
    } catch (error) {
      console.log("Delete software failed", error);
    }
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openCheckboxPopup = () => {
    setCheckboxPopupOpen(true);
  };

  const closeCheckboxPopup = () => {
    setCheckboxPopupOpen(false);
  };

  return (
    <div>
      <Box px={2} pt={2} sx={{ paddingLeft: "240px", paddingTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Suchen"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Grid>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setOpenCreate(true)}
            style={{ marginTop: "20px", marginLeft: "20px" }}
          >
            Software hinzufügen
          </Button>
          <IconButton
            onClick={openCheckboxPopup}
            style={{ marginLeft: "10px" }}
          >
            <SettingsIcon />
          </IconButton>
        </Grid>
        <TableContainer component={Paper} style={{ padding: "30px", marginTop: "10px" }}>
          <Table>
            <TableHead>
              <TableRow>
                {visibleColumns.software_id && (
                  <TableCell onClick={() => handleSort("software_id")}>
                    ID
                  </TableCell>
                )}
                {visibleColumns.name && (
                  <TableCell onClick={() => handleSort("name")}>Name</TableCell>
                )}
                {visibleColumns.version && (
                  <TableCell onClick={() => handleSort("version")}>
                    Version
                  </TableCell>
                )}
                {visibleColumns.software_type && (
                  <TableCell onClick={() => handleSort("software_type")}>
                    Softwaretyp
                  </TableCell>
                )}
                {visibleColumns.software && (
                  <TableCell onClick={() => handleSort("software")}>
                    Software
                  </TableCell>
                )}
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSoftware
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((sw) => (
                  <TableRow key={sw.software_id}>
                    {visibleColumns.software_id && (
                      <TableCell>{sw.software_id}</TableCell>
                    )}
                    {visibleColumns.name && <TableCell>{sw.name}</TableCell>}
                    {visibleColumns.version && (
                      <TableCell>{sw.version}</TableCell>
                    )}
                    {visibleColumns.software_type && (
                      <TableCell>{sw.software_type}</TableCell>
                    )}
                    {visibleColumns.software && (
                      <TableCell>
                        <IconButton onClick={async () => {await downloadSoftwareFile(sw.software_id)}}>
                          <DownloadIcon />
                        </IconButton>
                      </TableCell>
                    )}
                    <TableCell>
                      <Button
                        onClick={async () => {
                          setSelectedSoftwareData(sw);
                          await fetchSelectedSoftwareFile(sw.software_id);
                          setOpenEdit(true);
                        }}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        onClick={() => {
                          handleDelete(sw.software_id);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filteredSoftware.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Zeilen pro Seite:"
          rowsPerPageOptions={rowsOptions}
          SelectProps={{
            inputProps: { "aria-label": "Zeilen pro Seite" },
          }}
        />
        <SoftwareForm
          isOpen={openCreate}
          onClose={handleCloseCreate}
          onSubmit={createSoftware}
          title="Software erstellen"
        />
        {selectedSoftwareData && selectedSoftwareFile && (
          <SoftwareForm
            isOpen={openEdit}
            onClose={() => {
              setSelectedSoftwareData(null);
              setSelectedSoftwareFile(null);
              handleCloseEdit();
            }}
            onSubmit={updateSoftware}
            title="Software bearbeiten"
            software={selectedSoftwareData}
            softwareFile={selectedSoftwareFile}
          />
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        />
      </Box>
      {/* Checkbox Popup */}
      <Dialog open={checkboxPopupOpen} onClose={closeCheckboxPopup}>
        <DialogTitle>Spalten auswählen</DialogTitle>
        <DialogContent>
          {Object.keys(visibleColumns).map((column) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleColumns[column]}
                  onChange={handleCheckboxChange}
                  name={column}
                />
              }
              label={columnNames[column]}
              key={column}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCheckboxPopup} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Software;
