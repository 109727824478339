import React, { useState, useEffect } from "react";
import axios from "axios";
import ArticleForm from "./articleForm";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  TextField,
  Snackbar,
  Box,
  TablePagination,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import SettingsIcon from "@mui/icons-material/Settings";

const Article = () => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [sortArticle, setSortArticle] = useState("asc");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsOptions = [5, 10, 15, 20];
  const [selectedColumn, setSelectedColumn] = useState("");
  const [checkboxPopupOpen, setCheckboxPopupOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState({
    article_number: true,
    article_stand: true,
    name: true,
    modul_number: true,
    reseller_article_number: true,
    voltage: true,
    voltage_input: true,
    voltage_output: true,
    power: true,
    current_input: true,
    current_output: true,
    compatible_power_stage_sn: true,
    compatible_power_stage_stand: true,
    compatible_control_board_sn: true,
    compatible_control_board_stand: true,
    compatible_spike_supression_board_sn: true,
    compatible_spike_supression_board_stand: true,
    compatible_emv_board_sn: true,
    compatible_emv_board_stand: true,
    compatible_signalboard_sn: true,
    compatible_signalboard_stand: true,
    compatible_emi_shield_sn: true,
    compatible_eplc_board_sn: true,
    case_type: true,
    power_plug: true

  });

  const columnLabels = {
    article_number: "Article Number",
    article_stand: "Article Stand",
    name: "Name",
    modul_number: "Modulnummer",
    reseller_article_number: "Deutz Artikelnummer",
    voltage: "Spannung",
    voltage_input: "Spannung Input",
    voltage_output: "Spannung Output",
    power: "Leistung",
    current_input: "Stromstärke Input",
    current_output: "Stromstärke Output",
    compatible_power_stage_sn: "Compatible Power Stage SN",
    compatible_power_stage_stand: "Compatible Power Stage Stand",
    compatible_control_board_sn: "Compatible Control Board SN",
    compatible_control_board_stand: "Compatible Control Board Stand",
    compatible_spike_supression_board_sn: "Compatible Spike Supression Board SN",
    compatible_spike_supression_board_stand: "Compatible Spike Supression Board Stand",
    compatible_emv_board_sn: "Compatible EMV Board SN",
    compatible_emv_board_stand: "Compatible EMV Board Stand",
    compatible_signalboard_sn: "Compatible Signalboard SN",
    compatible_signalboard_stand: "Compatible Signalboard Stand",
    compatible_emi_shield_sn: "Compatible EMI Shield SN",
    compatible_eplc_board_sn: "Compatible EPLC Board SN",
    case_type: "Deckeltyp",
    power_plug: "Steckertyp"

  };

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    try {
      const response = await axios.get("/api/articleTypes");
      setArticles(response.data);
      setFilteredArticles(response.data);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  const fetchOrderPositionByArticle = async (articleNumber, articleStand) => {
    try {
      const response = await axios.get(`/api/orderPositions/article_number/${articleNumber}/article_stand/${articleStand}`);

      return response.data;
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const searchColumns = Object.keys(visibleColumns);
    setFilteredArticles(
      articles.filter((article) =>
        searchColumns.some((column) =>
          article[column].toString().toLowerCase().includes(searchValue)
        )
      )
    );
  };

  const handleCheckboxChange = (event) => {
    setVisibleColumns({
      ...visibleColumns,
      [event.target.name]: event.target.checked,
    });
  };

  const handleColumnSelect = (event) => {
    setSelectedColumn(event.target.value);
  };

  const handleSort = (field) => {
    const sortedArticles = [...filteredArticles].sort((a, b) => {
      if (sortArticle === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });

    setFilteredArticles(sortedArticles);
    setSortArticle(sortArticle === "asc" ? "desc" : "asc");
  };

  const createArticle = async (articleData) => {
    let flatArticleData = {};
    Object.keys(articleData).map((key) => flatArticleData[key] = articleData[key].value);
    console.log("Artikel wird übermittelt:"+ JSON.stringify(flatArticleData)) //Debug line
    try {
      const response = await axios.post("/api/articleTypes", flatArticleData);
      fetchArticles();
      showSnackbar(
        `Artikel mit Artikelnummer ${flatArticleData.article_number} wurde erfolgreich erstellt.`
      );
    } catch (error) {
      console.error("Error creating article:", error);
    }
  };

  const updateArticle = async (articleData) => {
    let flatArticleData = {};
    Object.keys(articleData).map((key) => flatArticleData[key] = articleData[key].value);
    try {
      await axios.put(
        `/api/articleTypes/${flatArticleData.article_number}/${flatArticleData.article_stand}`,
        flatArticleData
      );
      fetchArticles();
      showSnackbar(
        `Artikel mit Artikelnummer ${flatArticleData.article_number} wurde erfolgreich aktualisiert.`
      );
    } catch (error) {
      console.error("Error updating article:", error);
    }
  };

  const handleDelete = async (articleNumber, articleStand) => {
    try {
      const orderPositions = await fetchOrderPositionByArticle(articleNumber, articleStand);
      if (orderPositions.length > 0) {
        const orderIds = orderPositions.map(op => op.order_id);
        showSnackbar(`Artikel mit Nummer ${articleNumber} und Stand ${articleStand} kann nicht gelöscht werden,\n da er in`
        + (orderIds.length === 1 ?  ` Bestellung ${orderIds.toString()} ` : ` den Bestellungen ${orderIds.toString()} `) +
         `hinterlegt ist.`);
        return;
      }
      await axios.delete(`/api/articleTypes/${articleNumber}/${articleStand}`);
      setFilteredArticles(
        filteredArticles.filter((article) => !(article.article_number === articleNumber && article.article_stand === articleStand))
      );
      showSnackbar(`Artikel mit Nummer ${articleNumber} und Stand ${articleStand} wurde erfolgreich gelöscht.`);
    } catch (error) {
      console.log("Delete article failed", error);
    }
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openCheckboxPopup = () => {
    setCheckboxPopupOpen(true);
  };

  const closeCheckboxPopup = () => {
    setCheckboxPopupOpen(false);
  };

  return (
    <div>
      <Box pt={2} sx={{ paddingLeft: "240px", paddingTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Suchen"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearch}
              sx={{ width:"600px", zIndex: -1}}
            />

          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setOpenCreate(true)}
            style={{ height: "100%", marginLeft: "10px"}}
          >
            Artikel hinzufügen
          </Button>
          <IconButton
            onClick={openCheckboxPopup}
            style={{ marginLeft: "10px"}}
          >
            <SettingsIcon />
          </IconButton>
          </Grid>
        </Grid>
        <TableContainer component={Paper} style={{ padding: "30px", marginTop: "10px"  }}>
          <Table>
            <TableHead>
              <TableRow>
                {visibleColumns.article_number && (
                  <TableCell onClick={() => handleSort("article_number")}>
                    Artikelnummer
                  </TableCell>
                )}
                 {visibleColumns.article_stand && (
                  <TableCell onClick={() => handleSort("article_stand")}>
                    Artikelstand
                  </TableCell>
                )}
                {visibleColumns.name && (
                  <TableCell onClick={() => handleSort("name")}>Name</TableCell>
                )}
                {visibleColumns.modul_number && (
                  <TableCell onClick={() => handleSort("modul_number")}>Modulnummer</TableCell>
                )}
                {visibleColumns.reseller_article_number && (
                  <TableCell onClick={() => handleSort("reseller_article_number")}>Deutz Artikelnummer</TableCell>
                )}
                {visibleColumns.voltage && (
                  <TableCell onClick={() => handleSort("voltage")}>Spannung</TableCell>
                )}
                {visibleColumns.voltage_input && (
                  <TableCell onClick={() => handleSort("voltage_input")}>Spannung Input</TableCell>
                )}
                {visibleColumns.voltage_output && (
                  <TableCell onClick={() => handleSort("voltage_output")}>Spannung Output</TableCell>
                )}
                {visibleColumns.power && (
                  <TableCell onClick={() => handleSort("power")}>Leistung</TableCell>
                )}
                {visibleColumns.current_input && (
                  <TableCell onClick={() => handleSort("current_input")}>Stromstärke Input</TableCell>
                )}
                {visibleColumns.current_output && (
                  <TableCell onClick={() => handleSort("current_output")}>Stromstärke Output</TableCell>
                )}
                {visibleColumns.compatible_power_stage_sn && (
                  <TableCell  style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("compatible_power_stage_sn")
                    }
                  >
                    SN der kompatiblen Power Stage
                  </TableCell>
                )}
                {visibleColumns.compatible_power_stage_stand && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("compatible_power_stage_stand")
                    }
                  >
                    Stand der kompatiblen Power Stage
                  </TableCell>
                )}
                {visibleColumns.compatible_control_board_sn && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("compatible_control_board_sn")
                    }
                  >
                    SN des kompatiblen Control Boards
                  </TableCell>
                )}
                {visibleColumns.compatible_control_board_stand && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("compatible_control_board_stand")
                    }
                  >
                    Stand des kompatiblen Control Boards
                  </TableCell>
                )}
                {visibleColumns.compatible_spike_supression_board_sn && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("compatible_spike_supression_board_sn")
                    }
                  >
                    SN des kompatiblen Spike Supression Boards
                  </TableCell>
                )}
                {visibleColumns.compatible_spike_supression_board_stand && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("compatible_spike_supression_board_stand")
                    }
                  >
                    Stand des kompatiblen Spike Supression Boards
                  </TableCell>
                )}
                {visibleColumns.compatible_emv_board_sn && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("compatible_emv_board_sn")
                    }
                  >
                    SN des kompatiblen EMV Boards
                  </TableCell>
                )}
                {visibleColumns.compatible_emv_board_stand && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("compatible_emv_board_stand")
                    }
                  >
                    Stand des kompatiblen EMV Boards
                  </TableCell>
                )}
                 {visibleColumns.compatible_signalboard_sn && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("compatible_signalboard_sn")
                    }
                  >
                    SN des kompatiblen Signalboards
                  </TableCell>
                )}
                {visibleColumns.compatible_signalboard_stand && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("compatible_signalboard_stand")
                    }
                  >
                    Stand des kompatiblen Signalboards
                  </TableCell>
                )}
                {visibleColumns.compatible_signlaboard_sn && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("compatible_signalboard_sn")
                    }
                  >
                    SN des kompatiblen Signalboards
                  </TableCell>
                )}
                {visibleColumns.compatible_signlaboard_stand && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("compatible_signalboard_stand")
                    }
                  >
                    Stand des kompatiblen Signalboards
                  </TableCell>
                )}
                {visibleColumns.compatible_emi_shield_sn && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("compatible_emi_shield_sn")
                    }
                  >
                    SN des kompatiblen EMI Shields
                  </TableCell>
                )}
                {visibleColumns.compatible_eplc_board_sn && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("compatible_eplc_board_sn")
                    }
                  >
                    SN des kompatiblen EPLC Boards
                  </TableCell>
                )}
                {visibleColumns.case_type && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("case_type")
                    }
                  >
                    Deckeltyp
                  </TableCell>
                )}
                {visibleColumns.power_plug && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("power_plug")
                    }
                  >
                    Steckertyp
                  </TableCell>
                )}
                {visibleColumns.case_type && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("case_type")
                    }
                  >
                    Deckeltyp
                  </TableCell>
                )}
                {visibleColumns.power_plug && (
                  <TableCell style={{minWidth:"200px"}}
                    onClick={() =>
                      handleSort("power_plug")
                    }
                  >
                    Steckertyp
                  </TableCell>
                )}
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredArticles
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((article) => (
                  <TableRow key={article.article_number}>
                    {visibleColumns.article_number && (
                      <TableCell style={{minWidth:"200px"}}>{article.article_number}</TableCell>
                    )}
                    {visibleColumns.article_stand && (
                      <TableCell style={{minWidth:"200px"}}>{article.article_stand}</TableCell>
                    )}
                    {visibleColumns.name && (
                      <TableCell style={{minWidth:"200px"}}>{article.name}</TableCell>
                    )}
                    {visibleColumns.modul_number && (
                      <TableCell style={{minWidth:"200px"}}>{article.modul_number}</TableCell>
                    )}
                    {visibleColumns.reseller_article_number && (
                      <TableCell style={{minWidth:"200px"}}>{article.reseller_article_number}</TableCell>
                    )}
                    {visibleColumns.voltage && (
                      <TableCell style={{minWidth:"200px"}}>{article.voltage}</TableCell>
                    )}
                    {visibleColumns.voltage_input && (
                      <TableCell style={{minWidth:"200px"}}>{article.voltage_input}</TableCell>
                    )}
                    {visibleColumns.voltage_output && (
                      <TableCell style={{minWidth:"200px"}}>{article.voltage_output}</TableCell>
                    )}
                    {visibleColumns.power && (
                      <TableCell style={{minWidth:"200px"}}>{article.power}</TableCell>
                    )}
                    {visibleColumns.current_input && (
                      <TableCell style={{minWidth:"200px"}}>{article.current_input}</TableCell>
                    )}
                    {visibleColumns.current_output && (
                      <TableCell style={{minWidth:"200px"}}>{article.current_output}</TableCell>
                    )}
                    {visibleColumns.compatible_power_stage_sn && (
                      <TableCell style={{minWidth:"200px"}}>
                        {article.compatible_power_stage_sn}
                      </TableCell>
                    )}
                    {visibleColumns.compatible_power_stage_stand && (
                      <TableCell style={{minWidth:"200px"}}>
                        {article.compatible_power_stage_stand}
                      </TableCell>
                    )}
                    {visibleColumns.compatible_control_board_sn && (
                      <TableCell style={{minWidth:"200px"}}>
                        {article.compatible_control_board_sn}
                      </TableCell>
                    )}
                    {visibleColumns.compatible_control_board_stand && (
                      <TableCell style={{minWidth:"200px"}}>
                        {article.compatible_control_board_stand}
                      </TableCell>
                    )}
                    {visibleColumns.compatible_spike_supression_board_sn && (
                      <TableCell style={{minWidth:"200px"}}>
                        {article.compatible_spike_supression_board_sn}
                      </TableCell>
                    )}
                    {visibleColumns.compatible_spike_supression_board_stand && (
                      <TableCell style={{minWidth:"200px"}}>
                        {article.compatible_spike_supression_board_stand}
                      </TableCell>
                    )}
                    {visibleColumns.compatible_emv_board_sn && (
                      <TableCell style={{minWidth:"200px"}}>
                        {article.compatible_emv_board_sn}
                      </TableCell>
                    )}
                    {visibleColumns.compatible_emv_board_stand && (
                      <TableCell style={{minWidth:"200px"}}>
                        {article.compatible_emv_board_stand}
                      </TableCell>
                    )}
                    {visibleColumns.compatible_signalboard_sn && (
                      <TableCell style={{minWidth:"200px"}}>
                        {article.compatible_signalboard_sn}
                      </TableCell>
                    )}
                    {visibleColumns.compatible_signalboard_stand && (
                      <TableCell style={{minWidth:"200px"}}>
                        {article.compatible_signalboard_stand}
                      </TableCell>
                    )}
                    {visibleColumns.compatible_emi_shield_sn && (
                      <TableCell style={{minWidth:"200px"}}>
                        {article.compatible_emi_shield_sn}
                      </TableCell>
                    )}
                    {visibleColumns.compatible_eplc_board_sn && (
                      <TableCell style={{minWidth:"200px"}}>
                        {article.compatible_eplc_board_sn}
                      </TableCell>
                    )}
                    {visibleColumns.case_type && (
                      <TableCell style={{minWidth:"200px"}}>
                        {article.case_type}
                      </TableCell>
                    )}
                    {visibleColumns.power_plug && (
                      <TableCell style={{minWidth:"200px"}}>
                        {article.power_plug}
                      </TableCell>
                    )}
                    <TableCell>
                      <Button
                        onClick={() => {
                          setSelectedArticle(article);
                          setOpenEdit(true);
                        }}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        onClick={() => {
                          handleDelete(article.article_number, article.article_stand);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filteredArticles.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Zeilen pro Seite:"
          rowsPerPageOptions={rowsOptions}
          SelectProps={{
            inputProps: { "aria-label": "Zeilen pro Seite" },
          }}
        />
        <ArticleForm
          isOpen={openCreate}
          onClose={handleCloseCreate}
          onSubmit={createArticle}
          title="Artikel erstellen"
        />
        {selectedArticle && (
          <ArticleForm
            isOpen={openEdit}
            onClose={() => {
              setSelectedArticle(null);
              handleCloseEdit();
            }}
            onSubmit={updateArticle}
            title="Artikel bearbeiten"
            article={selectedArticle}
          />
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          style={{whiteSpace: "pre-wrap"}}
        />
      </Box>
      {/* Checkbox Popup */}
      <Dialog open={checkboxPopupOpen} onClose={closeCheckboxPopup}>
        <DialogTitle>Spalten auswählen</DialogTitle>
        <DialogContent>
          {Object.keys(visibleColumns).map((column) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleColumns[column]}
                  onChange={handleCheckboxChange}
                  name={column}
                />
              }
              label={columnLabels[column]}
              key={column}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCheckboxPopup} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Article;
