import React, { useEffect, useState } from "react";
import ScanForm from "../scanForm/scanForm";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  IconButton,
  DialogTitle,
  DialogContent,
  Grid,
  Snackbar
} from "@mui/material";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

const snComponentFormat = new RegExp("^#[0-9]{4}$");
const snPowerStageFormat = new RegExp("^SC[0-9]{3}29.[0-9]{1}-[0-9]{1}\/#[0-9]{3,}$");
const snControlBoardFormat = new RegExp("^SC[0-9]{3}30.[0-9]{1}-[0-9]{1}\/#[0-9]{3,}$");
const snSpikeSupressionBoardFormat = new RegExp("^SC[0-9]{3}4(1|3){1}.[0-9]{1}-[0-9]{1}\/#[0-9]{3,}$");
const snEmvBoardFormat = new RegExp("^SC[0-9]{3}40.[0-9]{1}-[0-9]{1}\/#[0-9]{3,}$");
const snSignalboardFormat = new RegExp("^SC[0-9]{3}31.[0-9]{1}-[0-9]{1}\/#[0-9]{3,}$");
const snEmiShieldFormat = new RegExp("^SC[0-9]{3}74.[0-9]{1}-[0-9]{1}\/#[0-9]{3,}$");
const snEplcBoardFormat = new RegExp("^SC[0-9]{3}39.[0-9]{1}-[0-9]{1}\/#[0-9]{3,}$");
const bmkNumberFormat = new RegExp("^[0-9]{10}$");

const ComponentScan = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [open, setOpen] = useState(false);

  const [snInput, setSNInput] = useState("");
  const [previousSNFormat, setPreviousSNFormat] = useState(null);
  const [snFieldValue, setSNFieldValue] = useState("");

  const [componentId, setComponentId] = useState("");
  const [powerStageSN, setPowerStageSN] = useState(null);
  const [powerStageStand, setPowerStageStand] = useState(null);
  const [controlBoardSN, setControlBoardSN] = useState(null);
  const [controlBoardStand, setControlBoardStand] = useState(null);
  const [spikeSupressionBoardSN, setSpikeSupressionBoardSN] = useState(null);
  const [spikeSupressionBoardStand, setSpikeSupressionBoardStand] = useState(null);
  const [emvBoardSN, setEmvBoardSN] = useState(null);
  const [emvBoardStand, setEmvBoardStand] = useState(null);
  const [signalboardSN, setSignalboardSN] = useState(null);
  const [signalboardStand, setSignalboardStand] = useState(null);
  const [emiShieldSN, setEmiShieldSN] = useState(null);
  const [eplcBoardSN, setEplcBoardSN] = useState(null);

  const [loadingComponent, setLoadingComponent] = useState(true);
  const [loadingPowerStage, setLoadingPowerStage] = useState(true);
  const [loadingControlBoard, setLoadingControlBoard] = useState(true);
  const [loadingSpikeSupressionBoard, setLoadingSpikeSupressionBoard] = useState(true);
  const [loadingEmvBoard, setLoadingEmvBoard] = useState(true);
  const [loadingSignalboard, setLoadingSignalboard] = useState(true);
  const [loadingEmiShield, setLoadingEmiShield] = useState(true);
  const [loadingEplcBoard, setLoadingEplcBoard] = useState(true);

  const [componentChecked, setComponentChecked] = useState(false);
  const [powerStageChecked, setPowerStageChecked] = useState(false);
  const [controlBoardChecked, setControlBoardChecked] = useState(false);
  const [spikeSupressionBoardChecked, setSpikeSupressionBoardChecked] = useState(false);
  const [emvBoardChecked, setEmvBoardChecked] = useState(false);
  const [signalboardChecked, setSignalboardChecked] = useState(false);
  const [emiShieldChecked, setEmiShieldChecked] = useState(false);
  const [eplcBoardChecked, setEplcBoardChecked] = useState(false);

  const [bmkNumber, setBmkNumber] = useState(null);
  const [bmkPowerStage, setBmkPowerStage] = useState(null);
  const [bmkControlBoard, setBmkControlBoard] = useState(null);
  const [bmkSpikeSupressionBoard, setBmkSpikeSupressionBoard] = useState(null);
  const [bmkEmvBoard, setBmkEmvBoard] = useState(null);
  const [bmkSignalboard, setBmkSignalboard] = useState(null);
  const [bmkEmiShield, setBmkEmiShield] = useState(null);
  const [bmkEplcBoard, setBmkEplcBoard] = useState(null);
  const inputRef = React.useRef();

  useEffect(() => {
    //Focus the SN input field when opening the dialog
    setTimeout(() => {
      inputRef.current?.focus();
    }, 400);
  }, [open]);

  const handleSNInputChange = (event) => {
    const sn = event.target.value;
    const focusedElement = document.activeElement;
    if(focusedElement.name === "sn-field") {
      setSNFieldValue(sn);
    }
    setSNInput(sn);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && (snInput.match(snEmiShieldFormat) ||
    (snInput.match(snEplcBoardFormat) || snInput.match(snEmiShieldFormat) || snInput.match(snComponentFormat) || snInput.match(bmkNumberFormat)))) {
      handleSNInput("");
    }
  }

  const handleSNInput = async (standInput) => {

    /* COMPONENT ID */
    if (snInput.match(snComponentFormat)) {
      const id = snInput.slice(1);
      const existingDevice = await getDeviceByComponentId(id);
      if (existingDevice) {
        showSnackbar(`Device mit Komponenten ID ${id} existiert bereits.`);
        return;
      }
      setComponentChecked(true);
      setComponentId(id);
      setLoadingComponent(false);
    }

    /* POWER STAGE */
    if (snInput.match(snPowerStageFormat)) {
        setPowerStageChecked(true);
        setPowerStageSN(snInput);
        setPowerStageStand(standInput);
        setPreviousSNFormat("sn-power-stage");
        setLoadingPowerStage(false);
    }

    /* CONTROL BOARD */
    else if (snInput.match(snControlBoardFormat)) {
      setControlBoardChecked(true);
      setControlBoardSN(snInput);
      setPreviousSNFormat("sn-control-board");
      setControlBoardStand(standInput);
      setLoadingControlBoard(false);
    }

    /* SPIKE SUPRESSION BOARD */
    else if (snInput.match(snSpikeSupressionBoardFormat)) {
      setSpikeSupressionBoardChecked(true);
      setSpikeSupressionBoardSN(snInput);
      setSpikeSupressionBoardStand(standInput);
      setPreviousSNFormat("sn-spike-supression-board");
      setLoadingSpikeSupressionBoard(false);
    }

    /* EMV BOARD */
    else if (snInput.match(snEmvBoardFormat)) {
      setEmvBoardChecked(true);
      setEmvBoardSN(snInput);
      setEmvBoardStand(standInput);
      setPreviousSNFormat("sn-emv-board");
      setLoadingEmvBoard(false);
    }

    /* SIGNALBOARD */
    else if (snInput.match(snSignalboardFormat)) {
      setSignalboardChecked(true);
      setSignalboardSN(snInput);
      setSignalboardStand(standInput);
      setPreviousSNFormat("sn-signalboard");
      setLoadingSignalboard(false);
    }

    /* EMI SHIELD */
    else if (snInput.match(snEmiShieldFormat)) {
      setEmiShieldChecked(true);
      setEmiShieldSN(snInput);
      setPreviousSNFormat("sn-emi-shield");
      setLoadingEmiShield(false);
    }

    /* EPLC BOARD */
    else if (snInput.match(snEplcBoardFormat)) {
      setEplcBoardChecked(true);
      setEplcBoardSN(snInput);
      setPreviousSNFormat("sn-eplc-board");
      setLoadingEplcBoard(false);
    }

    /* BMK NUMBER as first input */
    else if (snInput.match(bmkNumberFormat) && !previousSNFormat && document.activeElement.name === "sn-field") {
      setBmkNumber(snInput);
    }

    /* BMK NUMBER in bmk input field */
    else if (snInput.match(bmkNumberFormat) && (document.activeElement.name.startsWith("bmk-"))) {
      switch (document.activeElement.name) {
        case "bmk-power-stage":
          setBmkPowerStage(snInput);
          break;
        case "bmk-control-board":
          setBmkControlBoard(snInput);
          break;
        case "bmk-spike-supression-board":
          setBmkSpikeSupressionBoard(snInput);
          break;
        case "bmk-emv-board":
          setBmkEmvBoard(snInput);
          break;
        case "bmk-signalboard":
          setBmkSignalboard(snInput);
          break;
        case "bmk-emi-shield":
          setBmkEmiShield(snInput);
          break;
        case "bmk-eplc-board":
          setBmkEplcBoard(snInput);
          break;
      }
    }

    /* BMK NUMBER in main input field depending on previous SN */
    else if (snInput.match(bmkNumberFormat) && previousSNFormat && document.activeElement.name === "sn-field") {
      switch (previousSNFormat) {
        case "sn-power-stage":
          setBmkPowerStage(snInput);
          break;
        case "sn-control-board":
          setBmkControlBoard(snInput);
          break;
        case "sn-spike-supression-board":
          setBmkSpikeSupressionBoard(snInput);
          break;
        case "sn-emv-board":
          setBmkEmvBoard(snInput);
          break;
        case "sn-signalboard":
          setBmkSignalboard(snInput);
          break;
        case "sn-emi-shield":
          setBmkEmiShield(snInput);
          break;
        case "sn-eplc-board":
          setBmkEplcBoard(snInput);
          break;
      }
    }

    //After SN check reset SN input field and Stand
    setSNInput("");
    setSNFieldValue("");

    //Focus the SN input field again
    inputRef.current.focus();
  }

  const getCurrentYear = () => {
    return new Date().toLocaleDateString('de', {year: '2-digit'});
  }

  const getLatestComponentId = async () => {
    const responseLatestComponentId = await axios.get("/api/devices/latest/componentId");

    const latestComponentId = responseLatestComponentId.data.max;

    if((latestComponentId && !latestComponentId.toString().startsWith(getCurrentYear()))
    || !latestComponentId) {
      return parseInt(getCurrentYear() + "00");
    }

    return latestComponentId;
  }

  const getDeviceByComponentId = async (id) => {
    const response = await axios.get(`/api/devices/component_id/${id}`);
    if(response.data.length === 0) {
      return null;
    }

    return response.data;
  }

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleSubmit = async () => {
      try {

        const newComponentId = componentId ? componentId : await getLatestComponentId() + 1;

        const deviceData = {
          component_id: newComponentId,
          sn_power_stage: powerStageSN,
          sn_power_stage_stand: powerStageStand,
          sn_control_board: controlBoardSN,
          sn_control_board_stand: controlBoardStand,
          sn_spike_supression_board: spikeSupressionBoardSN,
          sn_spike_supression_board_stand: spikeSupressionBoardStand,
          sn_emv_board: emvBoardSN,
          sn_emv_board_stand: emvBoardStand,
          sn_signalboard: signalboardSN,
          sn_signalboard_stand: signalboardStand,
          sn_emi_shield: emiShieldSN,
          sn_eplc_board: eplcBoardSN,
          bmk_number: bmkNumber,
          bmk_power_stage: bmkPowerStage,
          bmk_control_board: bmkControlBoard,
          bmk_spike_supression_board: bmkSpikeSupressionBoard,
          bmk_emv_board: bmkEmvBoard,
          bmk_signalboard: bmkSignalboard,
          bmkEmiShield: bmkEmiShield,
          bmkEplcBoard: bmkEplcBoard
        }
  
        const response = await axios.post("/api/devices", deviceData);
  
        showSnackbar(
          `Device mit Component ID ${newComponentId} wurde erfolgreich erstellt.`
        );

        setOpen(false);
      } catch (error) {
        console.error("Error creating device:", error);
      }
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
      >
            Scanvorgang Starten
      </Button>
      <Dialog open={open} maxWidth="lg" fullWidth>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10}>
          <DialogTitle style={{paddingBottom: '5px'}}>Scanvorgang</DialogTitle>
        </Grid>
        <Grid item xs={12} sm={2}>
          <DialogActions style={{marginRight: '10px'}}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
            <CloseIcon />
            </IconButton>
          </DialogActions>
        </Grid>
      </Grid>
        <DialogContent style={{paddingBottom: '10px', paddingTop: '0px'}}>
            {ScanForm({
                componentScan: true,
                loadingComponent,
                loadingPowerStage,
                loadingControlBoard,
                loadingSpikeSupressionBoard,
                loadingEmvBoard,
                loadingSignalboard,
                loadingEmiShield,
                loadingEplcBoard,
                componentChecked,
                powerStageChecked,
                controlBoardChecked,
                spikeSupressionBoardChecked,
                emvBoardChecked,
                signalboardChecked,
                emiShieldChecked,
                eplcBoardChecked,
                componentId,
                powerStageSN,
                controlBoardSN,
                spikeSupressionBoardSN,
                emvBoardSN,
                signalboardSN,
                emiShieldSN,
                eplcBoardSN,
                powerStageStand,
                controlBoardStand,
                spikeSupressionBoardStand,
                emvBoardStand,
                signalboardStand,
                bmkPowerStage,
                bmkControlBoard,
                bmkSpikeSupressionBoard,
                bmkEmvBoard,
                bmkSignalboard,
                bmkEmiShield,
                bmkEplcBoard,
                bmkNumber,
                snInput,
                snFieldValue,
                inputRef,
                handleSNInputChange,
                handleKeyDown,
                handleSNInput,
                handleSubmit
            })
        }
        </DialogContent>
      </Dialog>
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
      message={snackbarMessage}
    />
    </Container>
    );
}

export default ComponentScan;
