import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./customCalender.css";
import "moment/locale/de";
import Sidebar from "../sidebar/sidebar";
import axios from "axios";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import ListIcon from "@mui/icons-material/List";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { Chart, LinearScale, BarElement, CategoryScale } from "chart.js";
import { Bar } from "react-chartjs-2";

const SIDEBAR_OFFSET = "150px";

const LandingPage = () => {
  const navigate = useNavigate();
  const localizer = momentLocalizer(moment);
  const [scheduledJobs, setScheduledJobs] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalTasks, setTotalTasks] = useState(0);
  const [totalArticles, setTotalArticles] = useState(0);
  const [orderData, setOrderData] = useState([]);

  const handleNavigationClick = (route) => {
    navigate(route);
  };

  Chart.register(LinearScale, BarElement, CategoryScale);

  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = "#52b202"; // Farbe für den Hintergrund des Events
    const borderColor = "#52b202"; // Farbe für den Rand des Events

    return {
      style: {
        backgroundColor,
        borderColor,
      },
    };
  };

  const fetchJobs = async () => {
    try {
      const response = await axios.get("/api/jobs");
      const jobs = response.data.map((job) => ({
        id: job.id,
        title: `Job ${job.job_id}`,
        start: moment(job.performed_date).toDate(),
        end: moment(job.due_date).toDate(),
      }));
      setScheduledJobs(jobs);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  const fetchTotalCustomers = async () => {
    try {
      const response = await axios.get("/api/customersWorkflow/");
      setTotalCustomers(response.data.length);
    } catch (error) {
      console.error("Error fetching total customers:", error);
    }
  };

  const fetchTotalOrders = async () => {
    try {
      const response = await axios.get("/api/ordersWorkflow");
      setTotalOrders(response.data.length);
    } catch (error) {
      console.error("Error fetching total orders:", error);
    }
  };

  const fetchTotalTasks = async () => {
    try {
      const response = await axios.get("/api/tasks");
      setTotalTasks(response.data.length);
    } catch (error) {
      console.error("Error fetching total tasks:", error);
    }
  };

  const fetchTotalArticles = async () => {
    try {
      const response = await axios.get("/api/articleTypes");
      setTotalArticles(response.data.length);
    } catch (error) {
      console.error("Error fetching total articles:", error);
    }
  };

  const fetchOrderData = async () => {
    try {
      const response = await axios.get("/api/ordersWorkflow");
      setOrderData(response.data);
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  useEffect(() => {
    fetchJobs();
    fetchTotalCustomers();
    fetchTotalOrders();
    fetchTotalArticles();
    fetchTotalTasks();
    fetchOrderData();
  }, []);

  const orderChartData = {
    labels: orderData.map((data) => data.week),
    datasets: [
      {
        label: "Anzahl der Bestellungen",
        data: orderData.map((data) => data.orderCount),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
    options: {
      scales: {
        x: {
          type: "category",
          display: true,
          scaleLabel: {
            display: true,
            labelString: "Woche",
          },
        },
        y: {
          display: true,
          scaleLabel: {
            display: true,
            labelString: "Anzahl der Bestellungen",
          },
          ticks: {
            beginAtZero: true,
            precision: 0,
          },
        },
      },
      plugins: {
        title: {
          display: true,
          text: "Anzahl der Bestellungen pro Woche",
          font: {
            size: 16,
          },
        },
        legend: {
          display: false,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  };

  return (
    <Container maxWidth="lg" sx={{marginLeft: SIDEBAR_OFFSET}}>
      <Grid container spacing={6}>
        <Grid item>
          <Sidebar handleNavigationClick={handleNavigationClick} />
        </Grid>
        <Grid item xs={12} ml={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography variant="h6">Bestellungen</Typography>
                  <Typography variant="h4">{totalOrders}</Typography>
                </div>
                <ShoppingCartIcon fontSize="large" color="primary" />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography variant="h6">Kunden</Typography>
                  <Typography variant="h4">{totalCustomers}</Typography>
                </div>
                <PeopleIcon fontSize="large" color="primary" />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography variant="h6">Artikel</Typography>
                  <Typography variant="h4">{totalArticles}</Typography>
                </div>
                <ListIcon fontSize="large" color="primary" />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography variant="h6">Aufgaben</Typography>
                  <Typography variant="h4">{totalTasks}</Typography>
                </div>
                <AddTaskIcon fontSize="large" color="primary" />
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Paper sx={{ p: 2 }}>
                <Bar data={orderChartData} />
              </Paper>
            </Grid>
          </Grid>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Kalender</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper sx={{ p: 2 }}>
                <Calendar
                  localizer={localizer}
                  culture="de"
                  events={scheduledJobs}
                  views={[Views.MONTH, Views.WEEK, Views.DAY]}
                  startAccessor="start"
                  endAccessor="end"
                  eventPropGetter={eventStyleGetter}
                  style={{ height: 500, width: "100%" }}
                  messages={{
                    next: "Nächstes",
                    previous: "Vorheriges",
                    today: "Heute",
                    month: "Monat",
                    week: "Woche",
                    day: "Tag",
                  }}
                />
              </Paper>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LandingPage;
