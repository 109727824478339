import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
} from "@mui/material";
import Draggable from "react-draggable";

const DraggableDialog = (props) => {
    const handleDrag = (event) => {
      event.preventDefault();
    };
  
    return (
      <Draggable handle=".drag-handle" onDrag={handleDrag}>
        <Paper {...props} />
      </Draggable>
    );
  };

const JobListInformation = ({open, onClose, title, task }) => {
    const [device, setDevice] = useState(null);

    useEffect(() => {
      fetchDevice(task.device_id);
    }, [])

    const fetchDevice = async (deviceId) => {
      try {
        const response = await axios.get(`/api/devices/${deviceId}`);
        setDevice(response.data);
      } catch (error) {
        console.error("Error fetching devices:", error);
      }
    };

    return (
        <Dialog
          open={open}
          onClose={onClose}
          fullWidth
          PaperComponent={DraggableDialog}
        >
            <DialogTitle className="drag-handle">{title}</DialogTitle>
            {device && <DialogContent>
            <p>Power Stage SN: {device.sn_power_stage}</p>
            <p>Power Stage Stand: {device.sn_power_stage_stand}</p>
            <p>Controlboard SN: {device.sn_control_board}</p>
            <p>Controlboard Stand: {device.sn_control_board_stand}</p>
            <p>Spike Supression Board SN: {device.sn_spike_supression_board}</p>
            <p>Spike Supression Board Stand: {device.sn_spike_supression_board_stand}</p>
            <p>EMV Board SN: {device.sn_emv_board}</p>
            <p>EMV Board Stand: {device.sn_emv_board}</p>
            <p>Signalboard SN: {device.sn_signalboard}</p>
            <p>Signalboard Stand: {device.sn_signalboard_stand}</p>
            {device.emi_shield_sn && <p>EMI Shield SN: {device.sn_emi_shield}</p>}
            {device.eplc_board_sn && <p>EPLC Board SN: {device.sn_eplc_board}</p>}
            </DialogContent>}
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default JobListInformation;