import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Paper,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from "@mui/material";
import Draggable from "react-draggable";
import CheckIcon from "@mui/icons-material/Check";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";

const DraggableDialog = (props) => {
  const handleDrag = (event) => {
    event.preventDefault();
  };

  return (
    <Draggable handle=".drag-handle" onDrag={handleDrag}>
      <Paper {...props} />
    </Draggable>
  );
};

const ParameterForm = ({ isOpen, onClose, onSubmit, title, parameterSet, xml1, xml2 }) => {
  const emptyErrorMessage = "Dies ist ein Pflichtfeld"
  const validFileTypeXML = ".xml";
  const fileTypeErrorMessage = `Ungültiger Dateityp. Gültige Dateitypen: ${validFileTypeXML}`

  const [customers, setCustomers] = useState([]);
  const [formData, setFormData] = useState({
    parameter_set_id: {
      value: parameterSet ? parameterSet.parameter_set_id : 0
    },
    name: {
      value: parameterSet?.name || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    customer_id: {
      value: parameterSet?.customer_id || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    customer_name: {
      value: parameterSet?.customer_name || ""
    },
    parameter_xml_1: {
      value: xml1 ? xml1 : null,
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage,
      fileTypeError: false,
      fileTypeErrorMessage: fileTypeErrorMessage
    },
    parameter_xml_2: {
      value: xml2 ? xml2 : null,
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage,
      fileTypeError: false,
      fileTypeErrorMessage: fileTypeErrorMessage
    },
    uploading_xml_1 : {
      value: false
    },
    uploading_xml_2 : {
      value: false
    }
  });

  useEffect(() => {
    fetchCustomers();
  },[])

  const fetchCustomers = async () => {
    try {
      const response = await axios.get("api/customersWorkflow");
      setCustomers(response.data);
    } catch(error) {
      console.error("Error fetching customers:", error);
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    if(formData[name.toString()].emptyErrorMessage) {
      checkNoEmptyInput(name, value);
    } else {
      setFormData({
        ...formData,
        [name]:{
          ...formData[name],
          value
        }
      })
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const name = event.target.name;

    if (!file.name.endsWith(validFileTypeXML)) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: {
          ...prevData[name],
          fileTypeError: true,
          emptyError: false,
          value: null
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: {
          ...prevData[name],
          emptyError: false,
          fileTypeError: false,
          value: file
        },
      }));
    }
  };

  const handleXML1Remove = () => {
    setFormData((prevData) => ({
      ...prevData,
      parameter_xml_1:{
        ...prevData["parameter_xml_1"],
        fileTypeError: false,
        emptyError: true,
        value: null
      }
    }));
  };

  const handleXML2Remove = () => {
    setFormData((prevData) => ({
      ...prevData,
      parameter_xml_2:{
        ...prevData["parameter_xml_2"],
        fileTypeError: false,
        emptyError: true,
        value: null
      }
    }));
  };

  const handleSubmit = () => {
    if (formData.uploading_xml_1.value || formData.uploading_xml_2.value) return;

    if (!validInputFields()) return;

    onSubmit(formData);
    onClose();
  };

  const handleClose = () => {
    const formFields = Object.keys(formData);
    let newFormData = {...formData}

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      if(formData[currentField.toString()].emptyErrorMessage){

        newFormData = {
          ...newFormData,
          [currentField]:{
            ...newFormData[currentField],
            emptyError:false
          }
        }
      }
    setFormData(newFormData);

    onClose();
    }
  }

  const checkNoEmptyInput = (fieldName, fieldValue) => {

    const isValid =!(fieldValue === "" || !fieldValue);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]:{
        ...prevFormData[fieldName],
        value: fieldValue,
        emptyError:!isValid
      }
    }));

    return isValid;
  }

  const validInputFields = () => {
    let isValid = true;
    const formFields = Object.keys(formData);

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formData[currentField].value;

      if(formData[currentField.toString()].emptyErrorMessage && (currentValue === "" || !currentValue)){
        isValid = false;
        checkNoEmptyInput(currentField, currentValue)
      }

      if(formData[currentField.toString()].fileTypeErrorMessage && formData[currentField.toString()].fileTypeError) {
        isValid = false;
      }
    }
    
    return isValid;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      PaperComponent={DraggableDialog}
    >
      <DialogTitle className="drag-handle">{title}</DialogTitle>
      <DialogContent>
      <TextField
          label="Name"
          name="name"
          value={formData.name.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("name", formData.name.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.name.emptyError}
          helperText={formData.name.emptyError?emptyErrorMessage:""}
        />
        <FormControl
          fullWidth
          error={formData.customer_id.emptyError}
          sx={{ marginBottom: 5 }}
        >
        <InputLabel id="customer-id" >Kunde</InputLabel>
          <Select
            labelId="customer-id"
            label="Kunde"
            name="customer_id"
            value={formData.customer_id.value}
            onChange={handleChange}
            onBlur={() => checkNoEmptyInput("customer_id", formData.customer_id.value)}
          >
            {customers && customers.map((customer) => (
              <MenuItem key={customer.customer_id} value={customer.customer_id}>
                {customer.customer_id + " - " + customer.name}
              </MenuItem>
            ))}
          </Select>
        <FormHelperText>{formData.customer_id.emptyError?emptyErrorMessage:""}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          error={formData.parameter_xml_1.emptyError || formData.parameter_xml_1.fileTypeError}
          sx={{ marginBottom: 2 }}
        >
          {formData.parameter_xml_1.value ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={handleXML1Remove}
                color="primary"
                disabled={formData.uploading_xml_1.value}
              >
                <DeleteIcon />
              </IconButton>
              {formData.uploading_xml_1.value ? (
                <CircularProgress size={24} sx={{ marginRight: 1 }} />
              ) : (
                <CheckIcon color="primary" sx={{ marginRight: 1 }} />
              )}
              {formData.uploading_xml_1.value
                ? "XML 1  wird hochgeladen..."
                : formData.parameter_xml_1.value === xml1 ? "XML 1" : `Datei: ${formData.parameter_xml_1.value.name}`}
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                id="xml-1-input"
                name="parameter_xml_1"
                type="file"
                accept={validFileTypeXML}
                style={{ display: "none" }}
                onChange={handleFileUpload}
                disabled={formData.uploading_xml_1.value}
              />
              <IconButton sx={{ marginRight: 1 }}>
                <label htmlFor="xml-1-input">
                  <CloudUploadIcon 
                    color="primary"
                    disabled={formData.uploading}
                    sx={{"&:hover":{cursor: "pointer"}}}
                  />
                </label>
              </IconButton>
              {formData.uploading_xml_1.value ? "XML 1 wird hochgeladen..." : "XML 1 hochladen"}
            </div>
          )}
        <FormHelperText>{formData.parameter_xml_1.emptyError?emptyErrorMessage:formData.parameter_xml_1.fileTypeError?fileTypeErrorMessage:""}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          error={formData.parameter_xml_2.emptyError || formData.parameter_xml_2.fileTypeError}
          sx={{ marginBottom: 2 }}
        >
          {formData.parameter_xml_2.value ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={handleXML2Remove}
                color="primary"
                disabled={formData.uploading_xml_2.value}
              >
                <DeleteIcon />
              </IconButton>
              {formData.uploading_xml_2.value ? (
                <CircularProgress size={24} sx={{ marginRight: 1 }} />
              ) : (
                <CheckIcon color="primary" sx={{ marginRight: 1 }} />
              )}
              {formData.uploading_xml_2.value
                ? "XML 2 wird hochgeladen..."
                : formData.parameter_xml_2.value === xml2 ? "XML 2" :`Datei: ${formData.parameter_xml_2.value.name}`}
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                id="xml-2-input"
                name="parameter_xml_2"
                type="file"
                accept={validFileTypeXML}
                style={{ display: "none" }}
                onChange={handleFileUpload}
                disabled={formData.uploading_xml_2.value}
              />
              <IconButton sx={{ marginRight: 1 }}>
                <label htmlFor="xml-2-input">
                  <CloudUploadIcon 
                    color="primary"
                    disabled={formData.uploading}
                  />
                </label>
              </IconButton>
              {formData.uploading_xml_2.value ? "XML 2 wird hochgeladen..." : "XML 2 hochladen"}
            </div>
          )}
        <FormHelperText>{formData.parameter_xml_2.emptyError?emptyErrorMessage:formData.parameter_xml_2.fileTypeError?fileTypeErrorMessage:""}</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Abbrechen
        </Button>
        <Button onClick={handleSubmit} color="primary">
        {parameterSet ? "Aktualisieren" : "Erstellen"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ParameterForm;
