import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Paper,
  Grid
} from "@mui/material";
import Draggable from "react-draggable";
import { isValid } from "date-fns";

const DraggableDialog = (props) => {
  const handleDrag = (event) => {
    event.preventDefault();
  };

  return (
    <Draggable handle=".drag-handle" onDrag={handleDrag}>
      <Paper {...props} />
    </Draggable>
  );
};

const DeviceForm = ({ isOpen, onClose, onSubmit, title, device }) => {
  const formatRegex = new RegExp("^SC[0-9]{5}.[0-9]{1}-1\/#[0-9]{3,4}$");
  const emptyErrorMessage = "Dies ist ein Pflichtfeld"
  const formatErrorMessage = "Ungültiges SN Format";

  const [formData, setFormData] = useState({
    sn_power_stage: {
      value: device?.sn_power_stage || "",
      emptyError: false,
      formatError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatErrorMessage: formatErrorMessage
    },
    sn_power_stage_stand: {
      value: device?.sn_power_stage_stand || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    sn_control_board: {
      value: device?.sn_control_board || "",
      emptyError: false,
      formatError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatErrorMessage: formatErrorMessage
    },
    sn_control_board_stand: {
      value: device?.sn_control_board_stand || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    sn_spike_supression_board: {
      value: device?.sn_spike_supression_board || "",
      emptyError: false,
      formatError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatErrorMessage: formatErrorMessage
    },
    sn_spike_supression_board_stand: {
      value: device?.sn_spike_supression_board_stand || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    sn_emv_board: {
      value: device?.sn_emv_board || "",
      emptyError: false,
      formatError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatErrorMessage: formatErrorMessage
    },
    sn_emv_board_stand: {
      value: device?.sn_emv_board_stand || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    sn_signalboard: {
      value: device?.sn_signalboard || "",
      emptyError: false,
      formatError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatErrorMessage: formatErrorMessage
    },
    sn_signalboard_stand: {
      value: device?.sn_signalboard_stand || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    sn_emi_shield: {
      value: device?.sn_emi_shield || "",
      formatError: false,
      formatErrorMessage: formatErrorMessage
    },
    sn_eplc_board: {
      value: device?.sn_eplc_board || "",
      formatError: false,
      formatErrorMessage: formatErrorMessage
    },
    case_id: {
      value: device?.case_id || 0
    },
    bmk_number: {
      value: device?.bmk_number || 0
    }
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    if(formData[name.toString()].emptyErrorMessage) {
      checkNoEmptyInput(name, value);
    }

    if(formData[name.toString()].formatErrorMessage) {
      checkValidSNFormat(name, value);
    }

    if(!(formData[name.toString()].emptyErrorMessage || formData[name.toString()].formatErrorMessage)) {
      setFormData({
        ...formData,
        [name]:{
          ...formData[name],
          value
        }
      })
    }
  };

  const handleSubmit = () => {

    if (!validInputFields()) return;

    onSubmit(formData);
    onClose();
  };

  const handleClose = () => {
    const formFields = Object.keys(formData);
    let newFormData = {...formData}

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      if(formData[currentField.toString()].emptyErrorMessage){

        newFormData = {
          ...newFormData,
          [currentField]:{
            ...newFormData[currentField],
            emptyError:false
          }
        }
      }
    setFormData(newFormData);

    onClose();
  };
}

  const validInputFields = () => {
    let isValid = true;
    const formFields = Object.keys(formData);

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formData[currentField].value;

      if(formData[currentField.toString()].emptyErrorMessage && currentValue === ""){
        isValid = false;
        checkNoEmptyInput(currentField, currentValue);
      }

      if(formData[currentField.toString()].formatErrorMessage && formData[currentField.toString()].formatError) {
        isValid = false;
      }

  }

  return isValid;

}

  const checkValidSNFormat = async (fieldName, fieldValue) => {
    let newFormData = {...formData};

    const isValid = fieldValue.match(formatRegex) || fieldValue === "";

      newFormData = {
        ...newFormData,
        [fieldName]:{
          ...newFormData[fieldName],
          value: fieldValue,
          formatError:!isValid
        }
      }
      setFormData(newFormData);
  }

  const checkNoEmptyInput = async (fieldName, fieldValue) => {
    const isValid = !(fieldValue === "");

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]:{
        ...prevFormData[fieldName],
        value: fieldValue,
        emptyError:!isValid
      }
    }));

    return isValid;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      PaperComponent={DraggableDialog}
    >
      <DialogTitle className="drag-handle">{title}</DialogTitle>
      <DialogContent>
        <Grid container="true">
        <TextField
          required
          label="SN PowerStage"
          name="sn_power_stage"
          value={formData.sn_power_stage.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("sn_power_stage", formData.sn_power_stage.value)}
          sx={{ marginBottom: 5 }}
          error={formData.sn_power_stage.emptyError || formData.sn_power_stage.formatError}
          helperText={formData.sn_power_stage.emptyError?emptyErrorMessage:formData.sn_power_stage.formatError
            ?formatErrorMessage:""}
        />
        <TextField
          required
          label="Stand"
          name="sn_power_stage_stand"
          value={formData.sn_power_stage_stand.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("sn_power_stage_stand", formData.sn_power_stage_stand.value)}
          sx={{ marginBottom: 5, marginLeft: 5 }}
          error={formData.sn_power_stage_stand.emptyError}
          helperText={formData.sn_power_stage_stand.emptyError?emptyErrorMessage:""}
        />
        </Grid>
        <Grid container="true">
        <TextField
          required
          label="SN Controlboard"
          name="sn_control_board"
          value={formData.sn_control_board.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("sn_control_board", formData.sn_control_board.value)}
          sx={{ marginBottom: 5 }}
          error={formData.sn_control_board.emptyError || formData.sn_control_board.formatError}
          helperText={formData.sn_control_board.emptyError?emptyErrorMessage:formData.sn_control_board.formatError
            ?formatErrorMessage:""}
        />
        <TextField
          required
          label="Stand"
          name="sn_control_board_stand"
          value={formData.sn_control_board_stand.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("sn_control_board_stand", formData.sn_control_board_stand.value)}
          sx={{ marginBottom: 5, marginLeft: 5 }}
          error={formData.sn_control_board_stand.emptyError}
          helperText={formData.sn_control_board_stand.emptyError?emptyErrorMessage:""}
        />
        </Grid>
        <Grid container="true">
        <TextField
          required
          label="SN Spike Supression Board"
          name="sn_spike_supression_board"
          value={formData.sn_spike_supression_board.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("sn_spike_supression_board", formData.sn_spike_supression_board.value)}
          sx={{ marginBottom: 5 }}
          error={formData.sn_spike_supression_board.emptyError || formData.sn_spike_supression_board.formatError}
          helperText={formData.sn_spike_supression_board.emptyError?emptyErrorMessage:formData.sn_spike_supression_board.formatError
            ?formatErrorMessage:""}
        />
        <TextField
          required
          label="Stand"
          name="sn_spike_supression_board_stand"
          value={formData.sn_spike_supression_board_stand.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("sn_spike_supression_board_stand", formData.sn_spike_supression_board_stand.value)}
          sx={{ marginBottom: 5, marginLeft: 5 }}
          error={formData.sn_spike_supression_board_stand.emptyError}
          helperText={formData.sn_spike_supression_board_stand.emptyError?emptyErrorMessage:""}
        />
        </Grid>
        <Grid container="true">
        <TextField
          required
          label="SN EMV Board"
          name="sn_emv_board"
          value={formData.sn_emv_board.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("sn_emv_board", formData.sn_emv_board.value)}
          sx={{ marginBottom: 5 }}
          error={formData.sn_emv_board.emptyError || formData.sn_emv_board.formatError}
          helperText={formData.sn_emv_board.emptyError?emptyErrorMessage:formData.sn_emv_board.formatError
            ?formatErrorMessage:""}
        />
        <TextField
          required
          label="Stand"
          name="sn_emv_board_stand"
          value={formData.sn_emv_board_stand.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("sn_emv_board_stand", formData.sn_emv_board_stand.value)}
          sx={{ marginBottom: 5, marginLeft: 5 }}
          error={formData.sn_emv_board_stand.emptyError}
          helperText={formData.sn_emv_board_stand.emptyError?emptyErrorMessage:""}
        />
        </Grid>
        <Grid container="true">
        <TextField
          required
          label="SN Signalboard"
          name="sn_signalboard"
          value={formData.sn_signalboard.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("sn_signalboard", formData.sn_signalboard.value)}
          sx={{ marginBottom: 5 }}
          error={formData.sn_signalboard.emptyError || formData.sn_signalboard.formatError}
          helperText={formData.sn_signalboard.emptyError?emptyErrorMessage:formData.sn_signalboard.formatError
            ?formatErrorMessage:""}
        />
        <TextField
          required
          label="Stand"
          name="sn_signalboard_stand"
          value={formData.sn_signalboard_stand.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("sn_signalboard_stand", formData.sn_signalboard_stand.value)}
          sx={{ marginBottom: 5, marginLeft: 5 }}
          error={formData.sn_signalboard_stand.emptyError}
          helperText={formData.sn_signalboard_stand.emptyError?emptyErrorMessage:""}
        />
        </Grid>
        <TextField
          label="SN EMI Shield"
          name="sn_emi_shield"
          value={formData.sn_emi_shield.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("sn_emi_shield", formData.sn_emi_shield.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.sn_emi_shield.formatError}
          helperText={formData.sn_emi_shield.formatError?formatErrorMessage:""}
        />
        <TextField
          label="SN EPLC Board"
          name="sn_eplc_board"
          value={formData.sn_eplc_board.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("sn_eplc_board", formData.sn_eplc_board.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.sn_eplc_board.formatError}
          helperText={formData.sn_eplc_board.formatError?formatErrorMessage:""}
        />
        <TextField
          label="Deckel ID"
          name="case_id"
          value={formData.case_id.value}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5 }}
        />
        <TextField
          label="BMK Nummber"
          name="bmk_number"
          value={formData.bmk_number.value}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Abbrechen
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {device ? "Aktualisieren" : "Erstellen"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceForm;
