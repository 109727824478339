import React from 'react';
import Dashboard from '../dashboard/dashboard'
function Homepage() {
    return (
        <div>
            <Dashboard/>
        </div>
    );
}

export default Homepage;