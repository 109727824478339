import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  TextField,
  Snackbar,
  Box,
  TablePagination,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from "@mui/icons-material/Download";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import TestResultsTemplateForm from "./testResultsTemplateForm";

const visibleColumnsInitial = {
  id: true,
  test_datetime: true,
  tester: true,
  serialno: true,
  sw_version_app: true,
  sw_version_test: true,
  hw_version: true,
  test_passed: true,
  module: true,
  comment: true,
  qrcode1: true,
  qrcode2: true,
};

const columnNames = {
  id: "ID",
  test_datetime: "Test Datum",
  tester: "Tester",
  serialno: "Seriennummer",
  sw_version_app: "SW Version App",
  sw_version_test: "SW Version Test",
  hw_version: "HW Version",
  test_passed: "Test Bestanden",
  module: "Modul",
  comment: "Kommentar",
  qrcode1: "QR Code 1",
  qrcode2: "QR Code 2",
};

function TestResult() {
  const [testRuns, setTestRuns] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(visibleColumnsInitial);
  const [checkboxPopupOpen, setCheckboxPopupOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [availableModules, setAvailableModules] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const rowsOptions = [5, 10, 15, 20];

  useEffect(() => {
    fetchTestResults();
  }, []);

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const formatDate = (dateString) => {
    if (dateString === "NULL" || dateString === "01.01.1970") {
      return "";
    }

    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const fetchTestResults = async () => {
    try {
      const response = await axios.get("/api/testresults/list");
      setTestRuns(response.data);

      const modules = [
        ...new Set(response.data.map((testRun) => testRun.module)),
      ];
      setAvailableModules(modules);
    } catch (error) {
      console.error("Error fetching test results:", error);
    }
  };

  const downloadReport = async (id) => {
    try {
      const response = await axios.get(`/api/testresults/${id}/report`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `report_${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      showSnackbar(`Bericht mit ID ${id} wurde erfolgreich heruntergeladen.`);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the report:", error);
      showSnackbar(`Fehler beim Herunterladen des Berichts mit ID ${id}.`);
    }
  };

  const deleteTestRun = async (id) => {
    try {
      const response = await axios.delete(`/api/testresults/${id}`);
      if (response.status === 200) {
        const newTestRuns = testRuns.filter((testRun) => testRun.id !== id);
        setTestRuns(newTestRuns);
      }
      showSnackbar(`Bericht mit ID ${id} wurde erfolgreich gelöscht.`);
    } catch (error) {
      console.error("Error deleting the test run:", error);
      showSnackbar(`Fehler beim Löschen des Testlaufs mit ID ${id}.`);
    }
  };

  const filteredTestRuns = testRuns.filter((testRun) =>
    Object.values(testRun)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleModuleSelect = (event) => {
    setSelectedModule(event.target.value);
  };

  const openCheckboxPopup = () => {
    setCheckboxPopupOpen(true);
  };

  const closeCheckboxPopup = () => {
    setCheckboxPopupOpen(false);
  };

  const handleCheckboxChange = (event) => {
    setVisibleColumns({
      ...visibleColumns,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column) => {
    const order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    setSortColumn(column);

    const sortedData = [...testRuns].sort((a, b) => {
      const aValue = a[column] ?? '';
      const bValue = b[column] ?? '';
      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setTestRuns(sortedData);
  };

  const filteredTestRunsByModule = selectedModule
    ? filteredTestRuns.filter((testRun) => testRun.module === selectedModule)
    : filteredTestRuns;

  const handleClose = () => {
    setOpen(false);
  };

  const uploadTemplate = async (templateData) => {
    let flatTemplateData = {};
    Object.keys(templateData).map((key) => flatTemplateData[key] = templateData[key].value);

    try {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("template_type", flatTemplateData.template_type);
      formDataToSubmit.append("module_number", flatTemplateData.module_number);
      formDataToSubmit.append("template", flatTemplateData.template_file);

      const response = await axios.post("/php-uploader/index.php", formDataToSubmit);

      showSnackbar(
      `Vorlage wurde erfolgreich hochgeladen.`
      );
    } catch (error) {
      console.error("Error creating test result template:", error);
    }
  }

  return (
    <div>
      <Box px={2} pt={2} sx={{ paddingLeft: "240px", paddingTop: "20px" }}>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
            sx={{marginBottom: 2}}
          >
            Vorlage Hochladen
          </Button>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Suchen"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="module-select">Modul</InputLabel>
              <Select
                labelId="module-select"
                value={selectedModule}
                onChange={handleModuleSelect}
                label="Modul"
              >
                <MenuItem value="">Alle Module</MenuItem>
                {availableModules.map((module) => (
                  <MenuItem key={module} value={module}>
                    {module}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <IconButton
            onClick={openCheckboxPopup}
            style={{ marginLeft: "10px" }}
          >
            <SettingsIcon />
          </IconButton>
        </Grid>
        <TableContainer component={Paper} style={{ padding: "30px", marginTop: "10px" }}>
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(visibleColumns).map(
                  (column) =>
                    visibleColumns[column] && (
                      <TableCell
                        key={column}
                        onClick={() => handleSort(column)}
                        style={{ cursor: "pointer" }}
                      >
                        {columnNames[column]}
                      </TableCell>
                    )
                )}
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTestRunsByModule
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((testRun) => (
                  <TableRow key={testRun.id}>
                    {Object.keys(visibleColumns).map(
                      (column) =>
                        visibleColumns[column] && (
                          <TableCell key={column}>
                            {column === "test_passed" ? (
                              testRun[column] === 1 ? (
                                <CheckIcon style={{ color: "green" }} />
                              ) : (
                                <CloseIcon style={{ color: "red" }} />
                              )
                            ) : column === "test_datetime" ? (
                              formatDate(testRun[column])
                            ) : (
                              testRun[column]
                            )}
                          </TableCell>
                        )
                    )}
                    <TableCell>
                      <IconButton onClick={() => downloadReport(testRun.id)}>
                        <DownloadIcon />
                      </IconButton>
                      <IconButton onClick={() => deleteTestRun(testRun.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filteredTestRuns.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Zeilen pro Seite:"
          rowsPerPageOptions={rowsOptions}
          SelectProps={{
            inputProps: { "aria-label": "Zeilen pro Seite" },
          }}
        />
        <TestResultsTemplateForm
          isOpen={open}
          onClose={handleClose}
          onSubmit={uploadTemplate}
          title="Vorlage erstellen"
        />
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
      {/* Checkbox Popup */}
      <Dialog open={checkboxPopupOpen} onClose={closeCheckboxPopup}>
        <DialogTitle>Spalten auswählen</DialogTitle>
        <DialogContent>
          {Object.keys(visibleColumns).map((column) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleColumns[column]}
                  onChange={handleCheckboxChange}
                  name={column}
                />
              }
              label={columnNames[column]}
              key={column}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCheckboxPopup} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TestResult;
