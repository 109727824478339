import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import useAuth from "../isAuthenticated";
import axios from "axios";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LogoutIcon from "@mui/icons-material/Logout";

const pages = ["Dashboard"];

function ResponsiveAppBar() {
  const { isAuthenticated, setIsAuthenticated, fetchUserProfile } = useAuth();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [userData, setUserData] = useState(null);
  const settings = [
    { name: "Profil", icon: <AccountCircleIcon color="primary" /> },
    { name: "Admin", icon: <AdminPanelSettingsIcon color="primary" /> },
    { name: "Logout", icon: <LogoutIcon color="primary" /> },
  ];
  const [jobs, setJobs] = useState([]);

  const updateJobs = (newJob) => {
    setJobs((prevJobs) => [...prevJobs, newJob]);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  React.useEffect(() => {
    const getUserProfile = async () => {
      const userProfile = await fetchUserProfile();
      setUser(userProfile);
    };

    if (isAuthenticated) {
      getUserProfile();
    }
  }, [isAuthenticated, fetchUserProfile]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/api/user/profile", {
          withCredentials: true,
        });
        setUserData(response.data);
      } catch (error) {
        console.log("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get("/api/jobs");
        const allJobs = response.data;
        const userJobs = allJobs.filter((job) => job.tester === userData.id);
        setJobs(userJobs);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    if (userData) {
      fetchJobs();
    }
  }, [userData]);

  const handleLogout = async () => {
    try {
      await axios.post(
        `api/auth/logout`,
        {},
        { withCredentials: true }
      );
      window.location.reload();
      setIsAuthenticated(false);
    } catch (error) {
      console.log("Logout failed", error);
    }
  };

  const handleNavigation = (page) => {
    handleCloseUserMenu();

    switch (page) {
      case "Profil":
        navigate("/profile");
        break;
      case "Admin":
        navigate("/admin");
        break;
      case "Logout":
        handleLogout();
        break;
      default:
        break;
    }
  };

  return (
    <Toolbar>
      <Box flexGrow={1} />
      {userData && (
        <Box display="flex" alignItems="center">
          <Tooltip title="Benachrichtigungen öffnen">
            <IconButton onClick={handleOpenNavMenu} sx={{ marginRight: 1 }}>
              <Badge badgeContent={jobs.length} color="primary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu}>
              <Avatar
                alt={userData.firstname + " " + userData.lastname}
                src={userData.profile_picture}
              />
            </IconButton>
          </Tooltip>
          <Typography
            variant="body1"
            noWrap
            sx={{ marginLeft: 1, color: "black" }}
          >{`${userData.email}`}</Typography>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            {jobs.length > 0 ? (
              jobs.map((job) => (
                <MenuItem
                  key={job.job_id}
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate("/job-list");
                  }}
                >
                  <Typography variant="body2" textAlign="center">
                    {`Job ${job.job_id}`}
                    <br />
                    <span style={{ fontSize: "0.8rem" }}>
                      Erstellt: {formatDate(job.performed_date)}, Fällig:{" "}
                      {formatDate(job.due_date)}
                    </span>
                  </Typography>
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>
                <Typography textAlign="center">Keine Aufgaben</Typography>
              </MenuItem>
            )}
          </Menu>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem
                key={setting.name}
                onClick={() => {
                  if (setting.name === "Logout") {
                    handleLogout();
                  } else {
                    handleNavigation(setting.name);
                  }
                }}
              >
                {setting.icon}
                <Typography sx={{ marginLeft: 1 }} textAlign="center">
                  {setting.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </Toolbar>
  );
}

export default ResponsiveAppBar;
