import React, { useState, useEffect } from "react";
import DeviceForm from "./devicesForm";
import axios from "axios";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  TextField,
  Snackbar,
  Box,
  TablePagination,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";

const Device = () => {
  const [devices, setDevices] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [sortDevice, setSortDevice] = useState("asc");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsOptions = [5, 10, 15, 20];
  const [visibleColumns, setVisibleColumns] = useState({
    device_id: true,
    component_id: true,
    sn_power_stage: true,
    sn_power_stage_stand: true,
    bmk_power_stage: true,
    sn_control_board: true,
    sn_control_board_stand: true,
    bmk_control_board: true,
    sn_spike_supression_board: true,
    sn_spike_supression_board_stand: true,
    bmk_spike_supression_board: true,
    sn_emv_board: true,
    sn_emv_board_stand: true,
    bmk_emv_board: true,
    sn_signalboard: true,
    sn_signalboard_stand: true,
    bmk_signalboard: true,
    sn_emi_shield: true,
    bmk_emi_shield: true,
    sn_eplc_board: true,
    bmk_eplc_board: true,
    case_id: true,
    bmk_number: true
  });
  const [checkboxPopupOpen, setCheckboxPopupOpen] = useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [deviceToDelete, setDeviceToDelete] = useState(null);

  useEffect(() => {
    fetchDevices();
  }, []);

  const fetchDevices = async () => {
    try {
      const response = await axios.get("/api/devices");
      setDevices(response.data);
      setFilteredDevices(response.data);
    } catch (error) {
      console.error("Error fetching devices:", error);
    }
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    setFilteredDevices(
      devices.filter((device) =>
        Object.values(device).join(" ").toLowerCase().includes(searchValue)
      )
    );
  };

  const columnLabels = {
    component_id: "Komponenten ID",
    device_id: "SN OBC",
    sn_power_stage: "SN PowerStage",
    sn_power_stage_stand: "Stand",
    bmk_power_stage: "BMK Power Stage",
    sn_control_board: "SN Controlboard",
    sn_control_board_stand: "Stand",
    bmk_control_board: "BMK Controlboard",
    sn_spike_supression_board: "SN Spike Supression Board",
    sn_spike_supression_board_stand: "Stand",
    bmk_spike_supression_board: "BMK Spike Supression Board",
    sn_emv_board: "SN EMV Board",
    sn_emv_board_stand: "Stand",
    bmk_emv_board: "BMK EMV Board",
    sn_signalboard: "SN Signalboard",
    sn_signalboard_stand: "Stand",
    bmk_signalboard: "BMK Signalboard",
    sn_emi_shield: "SN EMI Shield",
    bmk_emi_shield: "BMK EMI Shield",
    sn_eplc_board: "SN EPLC Board",
    bmk_eplc_board: "BMK EPLC Board",
    case_id: "Deckel ID",
    bmk_number: "BMK Nummer",
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleCheckboxChange = (event) => {
    setVisibleColumns({
      ...visibleColumns,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSort = (field) => {
    const sortedDevices = [...filteredDevices].sort((a, b) => {
      if (sortDevice === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });

    setFilteredDevices(sortedDevices);
    setSortDevice(sortDevice === "asc" ? "desc" : "asc");
  };

  const createDevice = async (deviceData) => {
    let flatDeviceData = {};
    Object.keys(deviceData).map(
      (key) => (flatDeviceData[key] = deviceData[key].value)
    );
    try {
      const latestDeviceId = await getLatestDeviceId();
      flatDeviceData.device_id = latestDeviceId + 1;

      const latestComponentId = await getLatestComponentId();
      flatDeviceData.component_id = latestComponentId + 1;

      const response = await axios.post("/api/devices", flatDeviceData);

      await fetchDevices();
      showSnackbar(
        `Device mit Device ID ${flatDeviceData.device_id} wurde erfolgreich erstellt.`
      );
    } catch (error) {
      console.error("Error creating device:", error);
    }
  };

  const updateDevice = async (deviceData) => {
    let flatDeviceData = {};
    Object.keys(deviceData).map(
      (key) => (flatDeviceData[key] = deviceData[key].value)
    );
    try {
      flatDeviceData.device_id = selectedDevice.device_id;
      await axios.put(
        `/api/devices/${flatDeviceData.device_id}`,
        flatDeviceData
      );

      await fetchDevices();
      showSnackbar(
        `Device mit Device ID ${flatDeviceData.device_id} wurde erfolgreich aktualisiert.`
      );
    } catch (error) {
      console.error("Error updating device:", error);
    }
  };

  const handleDeleteClick = (deviceId) => {
    setDeviceToDelete(deviceId);
    setPasswordDialogOpen(true);
  };

  const handleDelete = async () => {
    if (password === "Admin") {
      try {
        await axios.delete(`/api/devices/${deviceToDelete}`);
        await fetchDevices();
        showSnackbar(
          `Device mit Component ID ${deviceToDelete} wurde erfolgreich gelöscht.`
        );
      } catch (error) {
        console.log("Delete device failed", error);
      }
    } else {
      showSnackbar("Incorrect password.");
    }
    setPasswordDialogOpen(false);
    setPassword("");
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openCheckboxPopup = () => {
    setCheckboxPopupOpen(true);
  };

  const closeCheckboxPopup = () => {
    setCheckboxPopupOpen(false);
  };

  const getCurrentYear = () => {
    return new Date().toLocaleDateString("de", { year: "2-digit" });
  };

  const getLatestDeviceId = async () => {
    const responseLatestDeviceId = await axios.get("/api/devices/latest/deviceId");

    const latestDeviceId = responseLatestDeviceId.data.max;

    if (
      (latestDeviceId && !latestDeviceId.toString().startsWith(getCurrentYear())) ||
      !latestDeviceId
    ) {
      return parseInt(getCurrentYear() + "0000");
    }

    return latestDeviceId;
  };

  const getLatestComponentId = async () => {
    const responseLatestComponentId = await axios.get("/api/devices/latest/componentId");

    const latestComponentId = responseLatestComponentId.data.max;

    if (
      (latestComponentId && !latestComponentId.toString().startsWith(getCurrentYear())) ||
      !latestComponentId
    ) {
      return parseInt(getCurrentYear() + "00");
    }

    return latestComponentId;
  };

  return (
    <div>
      <Box pt={2} sx={{ paddingLeft: "240px", paddingTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Suchen"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearch}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setOpenCreate(true)}
              style={{ marginTop: "20px", marginLeft: "20px" }}
            >
              Device hinzufügen
            </Button>
            <IconButton onClick={openCheckboxPopup} style={{ marginLeft: "10px" }}>
              <SettingsIcon />
            </IconButton>
          </Grid>
        </Grid>
        <TableContainer component={Paper} style={{ padding: "30px", marginTop: "10px" }}>
          <Table>
            <TableHead style={{ whiteSpace: "nowrap" }}>
              <TableRow>
                {Object.keys(visibleColumns).map(
                  (column) =>
                    visibleColumns[column] && (
                      <TableCell onClick={() => handleSort(column)}>
                        {columnLabels[column]}
                      </TableCell>
                    )
                )}
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDevices
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((device) => (
                  <TableRow key={device.id}>
                    {Object.keys(visibleColumns).map(
                      (column) =>
                        visibleColumns[column] && <TableCell>{device[column]}</TableCell>
                    )}
                    <TableCell>
                      <Button
                        onClick={() => {
                          setSelectedDevice(device);
                          setOpenEdit(true);
                        }}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        onClick={() => handleDeleteClick(device.component_id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filteredDevices.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Zeilen pro Seite:"
          rowsPerPageOptions={rowsOptions}
          SelectProps={{
            inputProps: { "aria-label": "Zeilen pro Seite" },
          }}
        />
        <DeviceForm
          isOpen={openCreate}
          onClose={handleCloseCreate}
          onSubmit={createDevice}
          title="Devices erstellen"
        />
        {selectedDevice && (
          <DeviceForm
            isOpen={openEdit}
            onClose={() => {
              setSelectedDevice(null);
              handleCloseEdit();
            }}
            onSubmit={updateDevice}
            title="Devices bearbeiten"
            device={selectedDevice}
          />
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        />
      </Box>
      {/* Password Confirmation Dialog */}
      <Dialog open={passwordDialogOpen} onClose={() => setPasswordDialogOpen(false)}>
        <DialogTitle>Passwort Bestätigung</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPasswordDialogOpen(false)} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleDelete} color="primary">
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
      {/* Checkbox Popup */}
      <Dialog open={checkboxPopupOpen} onClose={closeCheckboxPopup}>
        <DialogTitle>Spalten auswählen</DialogTitle>
        <DialogContent>
          {Object.keys(visibleColumns).map((column) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleColumns[column]}
                  onChange={handleCheckboxChange}
                  name={column}
                />
              }
              label={columnLabels[column]}
              key={column}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCheckboxPopup} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Device;
