import React, { useState, useEffect } from "react";
import axios from "axios";
import CustomerForm from "./customerForm";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  TextField,
  Snackbar,
  Box,
  TablePagination,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import SettingsIcon from "@mui/icons-material/Settings";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const Customer = () => {
  const [customers, setCustomers] = useState([]);
  const [parameterSets, setParameterSets] = useState(null);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [sortCustomer, setSortCustomer] = useState("asc");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsOptions = [5, 10, 15, 20];
  const [selectedColumn, setSelectedColumn] = useState("");
  const [checkboxPopupOpen, setCheckboxPopupOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState({
    customer_id: true,
    name: true,
    description: true,
    distribution: true,
    parameter_set_name: true,
    sortcode: true
  });

  const columnNames = {
    customer_id: "KundenID",
    name: "Name",
    description: "Beschreibung",
    distribution: "Vertrieb",
    parameter_set_name: "Parametersatz",
    sortcode: "Sortcode"

  };

  useEffect(() => {
    fetchCustomers();
    fetchParameterSets();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await axios.get("/api/customersWorkflow");
      setCustomers(response.data);
      setFilteredCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const fetchParameterSets = async () => {
    try {
      const response = await axios.get("/api/parameterSets");
      setParameterSets(response.data);
    } catch (error) {
      console.error("Error fetching parameter set:", error);
    }
  };

  const getParameterSetsForCustomer = (customerId) => {
    const psForCustomer = parameterSets && parameterSets.filter(ps => ps.customer_id === customerId);
    if (psForCustomer && psForCustomer.length > 0) {
      let result = "";
      psForCustomer.forEach(ps => result = result + ps.name + "\n");
      return result;
    }

    return "";

  }

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const searchColumns = Object.keys(visibleColumns);
    setFilteredCustomers(
      customers.filter((customer) =>
        searchColumns.some((column) =>
          customer[column].toString().toLowerCase().includes(searchValue)
        )
      )
    );
  };

  const handleCheckboxChange = (event) => {
    setVisibleColumns({
      ...visibleColumns,
      [event.target.name]: event.target.checked,
    });
  };

  const handleColumnSelect = (event) => {
    setSelectedColumn(event.target.value);
  };

  const handleSort = (field) => {
    const sortedCustomers = [...filteredCustomers].sort((a, b) => {
      if (sortCustomer === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });

    setFilteredCustomers(sortedCustomers);
    setSortCustomer(sortCustomer === "asc" ? "desc" : "asc");
  };

  const createCustomer = async (customerData) => {
    let flatCustomerData = {};
    Object.keys(customerData).map((key) => flatCustomerData[key] = customerData[key].value);
    try {
      const response = await axios.post("/api/customersWorkflow", flatCustomerData);
      fetchCustomers();
      showSnackbar(
        `Kunde mit ID ${flatCustomerData.customer_id} wurde erfolgreich erstellt.`
      );
    } catch (error) {
      console.error("Error creating customer:", error);
    }
  };

  const updateCustomer = async (customerData) => {
    let flatCustomerData = {};
    Object.keys(customerData).map((key) => flatCustomerData[key] = customerData[key].value);
    try {
      const updatedCustomerData = {
        ...flatCustomerData,
        distribution:
        flatCustomerData.distribution !== undefined
            ? flatCustomerData.distribution
            : 0,
      };

      await axios.put(`/api/customersWorkflow/${flatCustomerData.customer_id}`, flatCustomerData);
      fetchCustomers();
      showSnackbar(`Kunde mit ID ${flatCustomerData.customer_id} wurde erfolgreich aktualisiert.`);
    } catch (error) {
      console.error("Error updating customer:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/customersWorkflow/${id}`);
      setFilteredCustomers(
        filteredCustomers.filter((customer) => customer.customer_id !== id)
      );
      showSnackbar(`Kunde mit ID ${id} wurde erfolgreich gelöscht.`);
    } catch (error) {
      console.log("Delete customer failed", error);
    }
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openCheckboxPopup = () => {
    setCheckboxPopupOpen(true);
  };

  const closeCheckboxPopup = () => {
    setCheckboxPopupOpen(false);
  };

  return (
    <div>
      <Box pt={2} sx={{ paddingLeft: "240px", paddingTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Suchen"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Grid>

          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setOpenCreate(true)}
            style={{ marginTop: "20px", marginLeft: "20px" }}
          >
            Kunde hinzufügen
          </Button>
          <IconButton
            onClick={openCheckboxPopup}
            style={{ marginLeft: "10px" }}
          >
            <SettingsIcon />
          </IconButton>
        </Grid>
        <TableContainer component={Paper} style={{ padding: "30px", marginTop: "10px" }}>
          <Table>
            <TableHead>
              <TableRow>
                {visibleColumns.customer_id && (
                                    <TableCell onClick={() => handleSort("customer_id")} sx={{ cursor: 'pointer' }}>
                    Kunden ID
                  </TableCell>
                )}
                {visibleColumns.name && (
                  <TableCell onClick={() => handleSort("name")} sx={{ cursor: 'pointer' }}>
                    Name
                  </TableCell>
                )}
                {visibleColumns.description && (
                  <TableCell onClick={() => handleSort("description")} sx={{ cursor: 'pointer' }}>
                    Beschreibung
                  </TableCell>
                )}
                {visibleColumns.distribution && (
                  <TableCell onClick={() => handleSort("distribution")} sx={{ cursor: 'pointer' }}>
                    Vertrieb
                  </TableCell>
                )}
                {visibleColumns.parameter_set_name && (
                  <TableCell onClick={() => handleSort("parameter_set_name")} sx={{ cursor: 'pointer' }}>
                    Parametersatz
                  </TableCell>
                )}
                {visibleColumns.sortcode && (
                  <TableCell onClick={() => handleSort("sortcode")} sx={{ cursor: 'pointer' }}>
                    Sortcode
                  </TableCell>
                )}
                <TableCell>Aktionen</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCustomers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((customer) => (
                  <TableRow key={customer.customer_id}>
                    {visibleColumns.customer_id && (
                      <TableCell>{customer.customer_id}</TableCell>
                    )}
                    {visibleColumns.name && (
                      <TableCell>{customer.name}</TableCell>
                    )}
                    {visibleColumns.description && (
                      <TableCell>{customer.description}</TableCell>
                    )}
                    {visibleColumns.distribution && (
                      <TableCell>
                        {customer.distribution === 1 ? (
                          <CheckIcon style={{ color: "green" }} />
                        ) : (
                          <CloseIcon style={{ color: "red" }} />
                        )}
                      </TableCell>
                    )}
                    {visibleColumns.parameter_set_name && (
                      <TableCell>{getParameterSetsForCustomer(customer.customer_id)}</TableCell>
                    )}
                    {visibleColumns.sortcode && (
                      <TableCell>{customer.sortcode}</TableCell>
                    )}

                    <TableCell>
                      <Button
                        onClick={() => {
                          setSelectedCustomer(customer);
                          setOpenEdit(true);
                        }}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        onClick={() => {
                          handleDelete(customer.customer_id);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filteredCustomers.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Zeilen pro Seite:"
          rowsPerPageOptions={rowsOptions}
          SelectProps={{
            inputProps: { "aria-label": "Zeilen pro Seite" },
          }}
        />
        <CustomerForm
          isOpen={openCreate}
          onClose={handleCloseCreate}
          onSubmit={createCustomer}
          title="Kunde erstellen"
        />
        {selectedCustomer && (
          <CustomerForm
            isOpen={openEdit}
            onClose={() => {
              setSelectedCustomer(null);
              handleCloseEdit();
            }}
            onSubmit={updateCustomer}
            title="Kunde bearbeiten"
            customer={selectedCustomer}
          />
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        />
      </Box>
      {/* Checkbox Popup */}
      <Dialog open={checkboxPopupOpen} onClose={closeCheckboxPopup}>
        <DialogTitle>Spalten auswählen</DialogTitle>
        <DialogContent>
          {Object.keys(visibleColumns).map((column) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleColumns[column]}
                  onChange={handleCheckboxChange}
                  name={column}
                />
              }
              label={columnNames[column]}
              key={column}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCheckboxPopup} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Customer;
