import React, { useState, useEffect } from "react";
import axios from "axios";
import UserForm from "./userForm";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  TextField,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  TableFooter,
  TablePagination,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import Draggable from "react-draggable";

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsOptions = [5, 10, 15, 20];
  const [searchColumn, setSearchColumn] = useState("id");
  const [columns, setColumns] = useState([
    { id: "id", label: "ID", visible: true },
    { id: "firstname", label: "Vorname", visible: true },
    { id: "lastname", label: "Nachname", visible: true },
    { id: "email", label: "Email", visible: true },
    {
      id: "role",
      label: "Rolle",
      visible: true,
      valueMap: {
        1: "Tester",
        2: "Moderator",
        3: "Abteilungsleiter",
        4: "Admin",
      },
    },
  ]);

  const actionsColumn = { id: "actions", label: "Actions", visible: true };
  const [columnsSettingsOpen, setColumnsSettingsOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const PaperComponent = (props) => {
    return (
      <Draggable handle="#draggable-dialog-title">
        <Paper {...props} />
      </Draggable>
    );
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const [columnFilter, setColumnFilter] = useState([
    "ID",
    "Firstname",
    "Lastname",
    "Email",
  ]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get("/api/admin/users");
      setUsers(response.data);
      setFilteredUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSearchColumnChange = (event) => {
    setSearchColumn(event.target.value);
  };

  const handleColumnsSettingsClick = () => {
    setColumnsSettingsOpen(true);
  };

  const handleColumnsSettingsClose = () => {
    setColumnsSettingsOpen(false);
  };

  const handleColumnVisibilityChange = (event) => {
    const columnName = event.target.name;
    const newColumns = columns.map((column) =>
      column.id === columnName
        ? { ...column, visible: !column.visible }
        : column
    );
    setColumns(newColumns);
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    setFilteredUsers(
      users.filter((user) =>
        Object.entries(user)
          .filter(([key]) => key === searchColumn)
          .some(([_, value]) =>
            value.toString().toLowerCase().includes(searchValue)
          )
      )
    );
  };

  const handleSort = (field) => {
    const sortedUsers = [...filteredUsers].sort((a, b) => {
      if (sortOrder === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });

    setFilteredUsers(sortedUsers);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const createUser = async (userData) => {
    let flatUserData = {};
    Object.keys(userData).map((key) => flatUserData[key] = userData[key].value);
    try {
      await axios.post("/api/admin/users", flatUserData);
      fetchUsers();
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  const updateUser = async (userData) => {
    let flatUserData = {};
    Object.keys(userData).map((key) => flatUserData[key] = userData[key].value);
    try {
      await axios.put(`/api/admin/users/${flatUserData.id}`, flatUserData);
      fetchUsers();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/admin/users/${id}`);
      setFilteredUsers(filteredUsers.filter((user) => user.id !== id));
    } catch (error) {
      console.log("Delete user failed", error);
    }
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  return (
    <div>
      <Box px={2} pt={2} sx={{ paddingLeft: "240px", paddingTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearch}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="search-select-label">Search Column</InputLabel>
              <Select
                labelId="search-select-label"
                value={searchColumn}
                onChange={handleSearchColumnChange}
                label="Search Column"
              >
                {columns.map((column) => (
                  <MenuItem key={column.id} value={column.id}>
                    {column.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenCreate(true)}
          style={{ marginTop: "20px" }}
        >
          Add User
        </Button>
        <IconButton
          onClick={handleColumnsSettingsClick}
          style={{ marginLeft: "10px" }}
        >
          <SettingsIcon />
        </IconButton>
      </Box>

      <Paper sx={{ paddingLeft: "240px", paddingTop: "20px" }}>
        <TableContainer style={{ padding: "30px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"></TableCell>
                {columns.map(
                  (column) =>
                    column.visible && (
                      <TableCell
                        key={column.id}
                        onClick={() => handleSort(column.id)}
                      >
                        {column.label}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user) => (
                  <TableRow key={user.id}>
                    <TableCell padding="checkbox">
                      <Avatar src={user.profile_picture} />
                    </TableCell>
                    {columns.map(
                      (column) =>
                        column.visible && (
                          <TableCell key={column.id}>
                            {column.id === "role"
                              ? column.valueMap[user[column.id]]
                              : user[column.id]}
                          </TableCell>
                        )
                    )}
                    {actionsColumn.visible && (
                      <TableCell>
                        <Button
                          onClick={() => {
                            setSelectedUser(user);
                            setOpenEdit(true);
                          }}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          onClick={() => {
                            handleDelete(user.id);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={rowsOptions}
                  count={filteredUsers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="Zeilen pro Seite:"
                  SelectProps={{
                    inputProps: { "aria-label": "Zeilen pro Seite" },
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <Dialog
          open={columnsSettingsOpen}
          onClose={handleColumnsSettingsClose}
          PaperComponent={PaperComponent}
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            Show Columns
          </DialogTitle>
          <DialogContent>
            {columns.map((column) => (
              <FormControlLabel
                key={column.id}
                control={
                  <Checkbox
                    checked={column.visible}
                    onChange={handleColumnVisibilityChange}
                    name={column.id}
                  />
                }
                label={column.label}
              />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleColumnsSettingsClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <UserForm
          isOpen={openCreate}
          onClose={handleCloseCreate}
          onSubmit={createUser}
          title="Create User"
        />
        {selectedUser && (
          <UserForm
            isOpen={openEdit}
            onClose={() => {
              setSelectedUser(null);
              handleCloseEdit();
            }}
            onSubmit={updateUser}
            title="Edit User"
            user={selectedUser}
          />
        )}
      </Paper>
    </div>
  );
};

export default Admin;
