import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Paper,
  MenuItem,
  Select,
} from "@mui/material";
import Draggable from "react-draggable";
import axios from "axios";

const DraggableDialog = (props) => {
  const handleDrag = (event) => {
    event.preventDefault();
  };

  return (
    <Draggable handle=".drag-handle" onDrag={handleDrag}>
      <Paper {...props} />
    </Draggable>
  );
};

const UserForm = ({ isOpen, onSubmit, user, onClose, title }) => {
  const emptyErrorMessage = "Dies ist ein Pflichtfeld"
  const emailExistsErrorMessage = "Ein User mit dieser E-Mail Adresse existiert bereits"

  const [formData, setFormData] = useState({
    firstname: {
      value: user?.firstname || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    lastname: {
      value: user?.lastname || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    email: {
      value: user?.email || "",
      emailExistsError: false,
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage,
      emailExistsErrorMessage: emailExistsErrorMessage
    },
    password: {
      value: "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    confirmPassword: {
      value: "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    role: {
      value: user?.role || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    }
  });

  const roles = [
    { value: 1, label: "Tester" },
    { value: 2, label: "Moderator" },
    { value: 3, label: "Abteilungsleiter" },
    { value: 4, label: "Admin" },
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;

    if(formData[name.toString()].emptyErrorMessage) {
      checkNoEmptyInput(name, value);
    } else {
      setFormData({
        ...formData,
        [name]:{
          ...formData[name],
          value
        }
      })
    }
  };

  const handleSubmit = async () => {
    if (!(await validInputFields())) return;

    if (formData.password.value !== formData.confirmPassword.value) {
      alert("Passwords do not match.");
      return;
    }
    onSubmit(formData);
    onClose();
  };

  const checkNoEmptyInput = (fieldName, fieldValue) => {

    const isValid = !(fieldValue === "");

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]:{
        ...prevFormData[fieldName],
        value: fieldValue,
        emptyError:!isValid
      }
    }));

    return isValid;
  }

  const validInputFields = async () => {
    let isValid = true;
    const formFields = Object.keys(formData);

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formData[currentField].value;

      if(formData[currentField.toString()].emptyErrorMessage && currentValue === ""){
        isValid = false;
        checkNoEmptyInput(currentField, currentValue)
      }

      if(currentField.toString() === "email" && !user){
        isValid = !(await emailExists(currentValue))
      }
    }

    return isValid;
  }

  const emailExists = async (email) => {
    const response = await axios.get(`api/user/email/${email}`);

    const emailExists = response.data.length > 0;

    setFormData((prevFormData) => ({
      ...prevFormData,
      email:{
        ...prevFormData[email],
        value: email,
        emailExistsError: emailExists
      }
    }));

    return emailExists;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      PaperComponent={DraggableDialog}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            required
            label="Firstname"
            name="firstname"
            value={formData.firstname.value}
            onChange={handleChange}
            onBlur={() => checkNoEmptyInput("firstname", formData.firstname.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
            error={formData.firstname.emptyError}
            helperText={formData.firstname.emptyError?emptyErrorMessage:""}
          />
          <TextField
            required
            label="Lastname"
            name="lastname"
            value={formData.lastname.value}
            onChange={handleChange}
            onBlur={() => checkNoEmptyInput("lastname", formData.lastname.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
            error={formData.lastname.emptyError}
            helperText={formData.lastname.emptyError?emptyErrorMessage:""}
          />
          <TextField
            required
            label="Email"
            name="email"
            value={formData.email.value}
            onChange={handleChange}
            onBlur={() => checkNoEmptyInput("email", formData.email.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
            error={formData.email.emptyError || formData.email.emailExistsError}
            helperText={formData.email.emptyError?emptyErrorMessage:formData.email.emailExistsError?emailExistsErrorMessage:""}
          />
          <TextField
            required
            label="Passwort"
            name="password"
            value={formData.password.value}
            onChange={handleChange}
            onBlur={() => checkNoEmptyInput("password", formData.password.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
            error={formData.password.emptyError}
            helperText={formData.password.emptyError?emptyErrorMessage:""}
          />
          <TextField
            required
            label="Passwort bestätigen"
            name="confirmPassword"
            value={formData.confirmPassword.value}
            onChange={handleChange}
            onBlur={() => checkNoEmptyInput("confirmPassword", formData.confirmPassword.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
            error={formData.confirmPassword.emptyError}
            helperText={formData.confirmPassword.emptyError?emptyErrorMessage:""}
          />
          <FormControl
            required
            fullWidth
            error={formData.role.emptyError}
            sx={{ marginBottom: 5 }}
            >
            <InputLabel id="role-label" >Rolle</InputLabel>
              <Select
                labelId="role-label"
                label="Rolle"
                id="role"
                name="role"
                value={formData.role.value}
                onChange={handleChange}
                onBlur={() => checkNoEmptyInput("role", formData.role.value)}
                fullWidth
                sx={{ marginBottom: 2 }}
              >
                {roles.map((role) => (
                  <MenuItem key={role.value} value={role.value}>
                    {role.label}
                  </MenuItem>
                ))}
              </Select>
            <FormHelperText>{formData.role.emptyError?emptyErrorMessage:""}</FormHelperText>
          </FormControl>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {user ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default UserForm;
