import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Paper,
  Select,
  MenuItem,
} from "@mui/material";
import Draggable from "react-draggable";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const DraggableDialog = (props) => {
  const handleDrag = (event) => {
    event.preventDefault();
  };

  return (
    <Draggable handle=".drag-handle" onDrag={handleDrag}>
      <Paper {...props} />
    </Draggable>
  );
};

const CustomerForm = ({ isOpen, onClose, onSubmit, title, customer }) => {
  const emptyErrorMessage = "Dies ist ein Pflichtfeld";
  const formatErrorMessage = "Wert muss eine Zahl sein!";
  const [formData, setFormData] = useState({
    customer_id: {
      value: customer?.customer_id || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage,
    },
    name: {
      value: customer?.name || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage,
    },
    description: {
      value: customer?.description || "",
    },
    distribution: {
      value: customer?.distribution || 0,
    },
    sortcode: {
      value: customer?.sortcode || "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage,
      formatError: false,
      formatErrorMessage: formatErrorMessage,
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (formData[name.toString()].emptyErrorMessage) {
      checkNoEmptyInput(name, value);
    } else {
      setFormData({
        ...formData,
        [name]: {
          ...formData[name],
          value,
        },
      });
    }

    if (formData[name.toString()].formatErrorMessage) {
      checkNumericInput(name, value);
    } else {
      setFormData({
        ...formData,
        [name]: {
          ...formData[name],
          value,
        },
      });
    }
  };

  const handleSubmit = () => {
    const updatedFormData = {
      ...formData,
    };

    if (!validInputFields()) return;

    onSubmit(updatedFormData);
    onClose();
  };

  const handleClose = () => {
    const formFields = Object.keys(formData);
    let newFormData = { ...formData };

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      if (formData[currentField.toString()].emptyErrorMessage) {
        newFormData = {
          ...newFormData,
          [currentField]: {
            ...newFormData[currentField],
            emptyError: false,
          },
        };
      }
    }
    setFormData(newFormData);

    onClose();
  };

  const checkNoEmptyInput = (fieldName, fieldValue) => {
    const isValid = !(fieldValue === "");

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: {
        ...prevFormData[fieldName],
        value: fieldValue,
        emptyError: !isValid,
      },
    }));

    return isValid;
  };

  const checkNumericInput = (fieldName, fieldValue) => {
    console.log("CheckNumeric gecastet mit Startwert: " + fieldValue);
    const isValid = /^-?\d+$/.test(fieldValue);
    console.log("Regex check befand: " + isValid);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: {
        ...prevFormData[fieldName],
        value: fieldValue,
        formatError: !isValid,
      },
    }));

    return isValid;
  };

  const validInputFields = () => {
    let isValid = true;
    const formFields = Object.keys(formData);

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formData[currentField].value;

      if (formData[currentField.toString()].emptyErrorMessage && currentValue === "") {
        isValid = false;
        checkNoEmptyInput(currentField, currentValue);
      }
      // If the field has the format error Message, test for strictly numeric input
      if (formData[currentField.toString()].formatErrorMessage && !/^-?\d+$/.test(currentValue)) {
        isValid = false;
        checkNumericInput(currentField, currentValue);
      }
    }
    return isValid;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      PaperComponent={DraggableDialog}
    >
      <DialogTitle className="drag-handle">{title}</DialogTitle>
      <DialogContent>
        <TextField
          label="Kunden ID"
          name="customer_id"
          value={formData.customer_id.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("customer_id", formData.customer_id.value)}
          fullWidth
          sx={{ marginTop: 5, marginBottom: 5 }}
          error={formData.customer_id.emptyError}
          helperText={formData.customer_id.emptyError ? emptyErrorMessage : ""}
        />
        <TextField
          label="Name"
          name="name"
          value={formData.name.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("name", formData.name.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          error={formData.name.emptyError}
          helperText={formData.name.emptyError ? emptyErrorMessage : ""}
        />
        <TextField
          label="Beschreibung"
          name="description"
          value={formData.description.value}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 5 }}
        />
        <Select
          value={formData.distribution.value}
          onChange={handleChange}
          name="distribution"
          fullWidth
          sx={{ marginBottom: 5 }}
        >
          <MenuItem value={0}>
            <CloseIcon style={{ color: "red", marginRight: 5 }} />
            Kein Vertrieb
          </MenuItem>
          <MenuItem value={1}>
            <CheckIcon style={{ color: "green", marginRight: 5 }} />
            Vertrieb
          </MenuItem>
        </Select>
        <TextField
          label="Sortcode"
          name="sortcode"
          value={formData.sortcode.value}
          onChange={handleChange}
          onBlur={() => checkNumericInput("sortcode", formData.sortcode.value)}
          error={formData.sortcode.formatError}
          helperText={formData.sortcode.formatError ? formatErrorMessage : ""}
          fullWidth
          sx={{ marginBottom: 5 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Abbrechen
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {customer ? "Aktualisieren" : "Erstellen"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerForm;
