import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Box,
  MenuItem,
  TableSortLabel,
  TablePagination,
  IconButton,
  Tabs,
  Tab,
  Checkbox,
  TextField,
  Button,
  List,
  ListItem,
  FormControl,
  InputLabel,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const SIDEBAR_OFFSET = "150px";

function formatDate(date) {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return new Date(date).toLocaleString("de-DE", options);
}

const formatDate2 = (dateString) => {
  if (dateString === "NULL" || dateString === "01.01.1970") {
    return "";
  }

  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}

function createJobData(jobID, tester, performedDate, priority, dueDate) {
  return {
    jobID,
    tester,
    performedDate: formatDate(performedDate),
    priority,
    dueDate: formatDate(dueDate),
    orders: [],
  };
}

function createOrderData(orderID, customerId, orderDate, orderType) {
  return {
    orderID,
    customerId,
    orderDate: formatDate2(orderDate),
    orderType,
    positions: [],
  };
}

function createPositionData(
  positionID,
  orderID,
  articleNumber,
  articleStand,
  amount,
  amountTotal
) {
  return {
    positionID,
    orderID,
    articleNumber,
    articleStand,
    amount,
    amountTotal,
  };
}

const Jobs = () => {
  const [orders, setOrders] = useState([]);
  const [tab, setTab] = useState(0); // 0 for Red Orders, 1 for Yellow Orders, 2 for Green Orders
  const [open, setOpen] = useState(null);
  const [orderPositions, setOrderPositions] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortField, setSortField] = useState("order_id");
  const [pageOrders, setPageOrders] = useState(0);
  const [pageJobs, setPageJobs] = useState(0);
  const [rowsPerPageOrders, setRowsPerPageOrders] = useState(6);
  const [rowsPerPageJobs, setRowsPerPageJobs] = useState(10);
  const rowsOptions = [5, 10, 15, 20];
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [tester, setTester] = useState("");
  const [priority, setPriority] = useState("");
  const [amounts, setAmounts] = useState({});
  const [positionsToFill, setPositionsToFill] = useState([]);
  const [due_date, setDueDate] = useState("");
  const [jobs, setJobs] = useState([]);
  const [job, setJob] = useState(null);
  const [openJobs, setOpenJobs] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState({
    order_id: true,
    customer_id: true,
    order_date: true,
    order_type: true,
  });
  const [customerNames, setCustomerNames] = useState({});
  const [userList, setUserList] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const fetchOrders = async () => {
    try {
      const response = await axios.get("/api/ordersWorkflow");
      const orders = response.data;

      for (const order of orders) {
        const customerName = await fetchCustomerName(order.customer_id);
        order.customerName = customerName;
        order.statusColor = getStatusColor(order.status);
      }

      setOrders(orders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const StatusIcon = ({ order }) => (
    <FiberManualRecordIcon
      style={{
        marginRight: "5px",
        color: order.statusColor,
      }}
      fontSize="small"
    />
  );

  const getStatusColor = (status) => {
    switch (status) {
      case "rot":
        return "red";
      case "gelb":
        return "yellow";
      case "grün":
        return "green";
      default:
        return "gray";
    }
  };

  const fetchOrderPositions = async (orderId) => {
    try {
      const response = await axios.get(`/api/orderPositions/${orderId}`);
      setOrderPositions((prevPositions) => ({
        ...prevPositions,
        [orderId]: response.data,
      }));
    } catch (error) {
      console.error("Error fetching order positions:", error);
    }
  };

  const fetchJobs = async () => {
    try {
      const response = await fetch("/api/jobs");
      const data = await response.json();

      const jobData = data.map(async (job) => {
        const testerName = await fetchTesterName(job.tester);
        return createJobData(
          job.job_id,
          testerName,
          job.performed_date,
          job.priority,
          job.due_date
        );
      });

      const jobsWithTesterNames = await Promise.all(jobData);

      setJobs(jobsWithTesterNames);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  const fetchOrdersByJobID = async (jobID) => {
    try {
      const response = await fetch(`/api/ordersWorkflow/job/${jobID}`);
      const data = await response.json();

      const orderData = data.map((order) =>
        createOrderData(
          order.order_id,
          order.customer_id,
          order.order_date,
          order.order_type
        )
      );

      return orderData;
    } catch (error) {
      console.error("Error fetching orders:", error);
      return [];
    }
  };

  const updateOrderPosition = async (positionID, orderID, amount) => {
    try {
      await axios.put(`/api/orderPositions/position/${positionID}/order/${orderID}`, { amount });
    } catch (error) {
      console.error("Error updating order position:", error);
    }
  };

  const fetchOrderPositionsByJobID = async (jobID, orderID) => {
    try {
      const response = await fetch(
        `/api/orderPositions/job/${jobID}/order/${orderID}`
      );
      const data = await response.json();

      const positionData = data.map((position) => {
        const { amount, amount_total, ...rest } = position;
        const calculatedAmount = amount_total - amount;
        return createPositionData(
          position.position_id,
          position.order_id,
          position.article_number,
          position.article_stand,
          calculatedAmount,
          position.amount_total
        );
      });

      return positionData;
    } catch (error) {
      console.error("Error fetching order positions:", error);
      return [];
    }
  };

  const fetchCustomerName = async (customerId) => {
    try {
      const response = await axios.get(`/api/customersWorkflow/${customerId}`);
      setCustomerNames((prevNames) => ({
        ...prevNames,
        [customerId]: response.data.name,
      }));
    } catch (error) {
      console.error("Error fetching customer name:", error);
    }
  };

  const fetchUserList = async () => {
    try {
      const response = await axios.get("/api/admin/users/");
      const userListData = response.data;

      const formattedUserList = userListData.map((user) => ({
        id: user.id,
        fullName: `${user.firstname} ${user.lastname}`,
      }));
      setUserList(formattedUserList);
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };

  const fetchTesterName = async (testerId) => {
    try {
      const response = await axios.get(`/api/user/${testerId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching tester name:", error);
      return "";
    }
  };

  const handleSelectPosition = async (position, orderId) => {
    const positionWithOrderId = { ...position, orderId };
    if (
      selectedPositions.find(
        (p) => p.position_id === position.position_id && p.orderId === orderId
      )
    ) {
      setSelectedPositions(
        selectedPositions.filter(
          (p) => p.position_id !== position.position_id || p.orderId !== orderId
        )
      );
    } else {
      setSelectedPositions([...selectedPositions, positionWithOrderId]);
    }
  };

  const handleAmountChange = (position, amount) => {
    const parsedAmount = Number(amount);

    if (parsedAmount <= position.amount && parsedAmount >= 0) {
      setAmounts((prevAmounts) => ({
        ...prevAmounts,
        [position.position_id]: parsedAmount,
      }));
    } else {
      setAmounts((prevAmounts) => ({
        ...prevAmounts,
        [position.position_id]: String(position.amount),
      }));
    }
  };

  const handleRemovePosition = (position) => {
    setPositionsToFill(
      positionsToFill.filter((p) => p.position_id !== position.position_id)
    );
    setAmounts((prevAmounts) => {
      const { [position.position_id]: _, ...newAmounts } = prevAmounts;
      return newAmounts;
    });
  };

  const handleArrowClick = () => {
    setPositionsToFill((prevPositions) => [
      ...prevPositions,
      ...selectedPositions,
    ]);
    setSelectedPositions([]);
  };

  const handleRowToggle = async (jobID) => {
    if (openJobs.includes(jobID)) {
      setOpenJobs(openJobs.filter((id) => id !== jobID));
    } else {
      setOpenJobs([...openJobs, jobID]);

      const selectedJob = jobs.find((job) => job.jobID === jobID);

      if (selectedJob.orders.length === 0) {
        const orders = await fetchOrdersByJobID(jobID);

        for (const order of orders) {
          const positions = await fetchOrderPositionsByJobID(
            jobID,
            order.orderID
          );
          order.positions = positions;
        }

        selectedJob.orders = orders;

        setJobs([...jobs]);
      }
    }
  };

  const positionColumnNames = {
    position_id: "Position ID",
    article_type: "Artikelnummer",
    article_stand: "Artikelstand",
    amount: "Menge",
  };

  const createJob = async (event) => {
    event.preventDefault();
    const testerValue = tester;
    const priorityValue = priority;
    const dueDateValue = due_date;
    const positionsToFillValue = positionsToFill.map((position) => ({
      position_id: position.position_id,
      order_id: position.order_id,
      article_number: position.article_number,
      article_stand: position.article_stand,
      amount: amounts[position.position_id] || 0,
    }));

    try {
      if (!positionsToFillValue || positionsToFillValue.length === 0) {
        throw new Error("Es wurden keine Positionen zum Ausfüllen ausgewählt.");
      }

      const jobResponse = await axios.post("/api/jobs", {
        tester: testerValue,
        priority: priorityValue,
        due_date: dueDateValue,
        positionsToFill: positionsToFillValue,
      });

      const createdJob = jobResponse.data;
      setJob(createdJob);

      const job_id = jobResponse.data.job_id;

      if (positionsToFill.length > 0) {
        const tasks = positionsToFill.map((position) => {
          const amount = amounts[position.position_id] || 0;
          return {
            position_id: position.position_id,
            order_id: position.order_id,
            article_number: position.article_number,
            article_stand: position.article_stand,
            amount: amount,
          };
        });

        if (tasks.length === 0) {
          throw new Error(
            "Es wurden keine Positionen zum Ausfüllen ausgewählt."
          );
        }

        await axios.post(`/api/jobsTasks/createTasksForJob/${job_id}`, tasks);

        // Aktualisieren der Positionen und des orderPositions Zustands
        for (let position of positionsToFill) {
          let amount = amounts[position.position_id] || 0;
          await updateOrderPosition(
            position.position_id,
            position.order_id,
            position.amount - amount
          );
          setOrderPositions((prevOrderPositions) => {
            const updatedOrderPositions = { ...prevOrderPositions };
            updatedOrderPositions[position.orderId] = updatedOrderPositions[
              position.orderId
            ].map((pos) => {
              if (pos.position_id === position.position_id) {
                return {
                  ...pos,
                  amount: position.amount - amount,
                };
              }
              return pos;
            });
            return updatedOrderPositions;
          });
          fetchOrders(); // refresh orders page to reflect the status color change
        }
      }

      setSnackbarMessage("Job und Aufgaben erfolgreich erstellt!");
      setSnackbarOpen(true);
      setTester("");
      setPriority("");
      setDueDate("");
      setPositionsToFill([]);
      setAmounts({});
    } catch (error) {
      console.error("Error creating job:", error);
      setSnackbarMessage("Fehler beim Erstellen des Jobs!");
      setSnackbarOpen(true);
    }
  };

  const handleSort = (field) => {
    setSortField(field);
    setSortOrder((sortOrder) => (sortOrder === "asc" ? "desc" : "asc"));
  };

  const handleChangePageOrders = (event, newPage) => {
    setPageOrders(newPage);
  };

  const handleChangeRowsPerPageOrders = (event) => {
    setRowsPerPageOrders(parseInt(event.target.value, 10));
    setPageOrders(0);
  };

  const handleChangePageJobs = (event, newPage) => {
    setPageJobs(newPage);
  };

  const handleChangeRowsPerPageJobs = (event) => {
    setRowsPerPageJobs(parseInt(event.target.value, 10));
    setPageJobs(0);
  };

  const handleColumnSelect = (field) => {
    setSelectedColumns({
      ...selectedColumns,
      [field]: !selectedColumns[field],
    });
  };

  const handleClick = async (event, orderId) => {
    console.log("handleClick called");
    console.log("Before update - open:", open);

    if (open === orderId) {
      setOpen(null);
    } else {
      setOpen(orderId);
      await fetchOrderPositions(orderId);
    }

    console.log("After update - open:", open);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setPageOrders(0); // Reset pagination when switching tabs
  };

  useEffect(() => {
    fetchUserList();
    fetchOrders();
    fetchJobs();
  }, [job]);

  useEffect(() => {
    Object.keys(amounts).forEach(async (positionId) => {
      const orderIds = Object.keys(orderPositions);
      for (let i = 0; i < orderIds.length; i++) {
        const orderId = orderIds[i];
        const positions = orderPositions[orderId];
        const positionIndex = positions.findIndex(
          (pos) => pos.position_id === positionId
        );
        if (positionIndex !== -1) {
          positions[positionIndex].amount = amounts[positionId];
          await updateOrderPosition(positionId, amounts[positionId]);
          setOrderPositions((prev) => ({ ...prev, [orderId]: [...positions] }));
          break;
        }
      }
    });
  }, [amounts]);

  return (
    <Container maxWidth="lg" sx={{ paddingTop: 4, paddingBottom: 4, marginLeft: SIDEBAR_OFFSET}}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <Paper>
            <Tabs value={tab} onChange={handleTabChange} aria-label="Order tabs">
              <Tab label="Teilweise in Produktion" />
              <Tab label="Noch nicht begonnen" />
              <Tab label="In Produktion" />
            </Tabs>
            <TableContainer style={{ overflowX: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {selectedColumns.order_id && (
                      <TableCell>
                        <TableSortLabel
                          active={sortField === "order_id"}
                          direction={sortOrder}
                          onClick={() => handleSort("order_id")}
                        >
                          Bestellungs ID
                        </TableSortLabel>
                      </TableCell>
                    )}
                    {selectedColumns.customer_id && (
                      <TableCell>
                        <TableSortLabel
                          active={sortField === "customer_id"}
                          direction={sortOrder}
                          onClick={() => handleSort("customer_id")}
                        >
                          Kunde
                        </TableSortLabel>
                      </TableCell>
                    )}
                    {selectedColumns.order_date && (
                      <TableCell>
                        <TableSortLabel
                          active={sortField === "order_date"}
                          direction={sortOrder}
                          onClick={() => handleSort("order_date")}
                        >
                          Bestellungsdatum
                        </TableSortLabel>
                      </TableCell>
                    )}
                    {selectedColumns.order_type && (
                      <TableCell>
                        <TableSortLabel
                          active={sortField === "order_type"}
                          direction={sortOrder}
                          onClick={() => handleSort("order_type")}
                        >
                          Bestellungstyp
                        </TableSortLabel>
                      </TableCell>
                    )}
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders
                    .filter((order) => order.isDone === 0) // Only show orders where isDone = 0
                    .filter((order) => {
                      switch (tab) {
                        case 0:
                          return order.status === "gelb";
                        case 1:
                          return order.status === "rot";
                        case 2:
                          return order.status === "grün";
                        default:
                          return false;
                      }
                    })
                    .sort((a, b) =>
                      sortOrder === "asc"
                        ? a[sortField] > b[sortField]
                          ? 1
                          : -1
                        : a[sortField] < b[sortField]
                        ? 1
                        : -1
                    )
                    .slice(pageOrders * rowsPerPageOrders, pageOrders * rowsPerPageOrders + rowsPerPageOrders)
                    .map((order) => (
                      <React.Fragment key={order.order_id}>
                        <TableRow
                          onClick={() => {
                            if (open === order.order_id) {
                              setOpen(null);
                            } else {
                              setOpen(order.order_id);
                              fetchOrderPositions(order.order_id);
                            }
                          }}
                          sx={{ "&:hover": { cursor: "pointer" } }}
                        >
                          {selectedColumns.order_id && (
                            <TableCell>{order.order_id}</TableCell>
                          )}
                          {selectedColumns.customer_id && (
                            <TableCell>
                              {customerNames[order.customer_id]}
                            </TableCell>
                          )}
                          {selectedColumns.order_date && (
                            <TableCell>
                              {new Date(order.order_date).toLocaleDateString()}
                            </TableCell>
                          )}
                          {selectedColumns.order_type && (
                            <TableCell>{order.order_type}</TableCell>
                          )}
                          <TableCell
                            colSpan={6}
                            style={{
                              textAlign: "center",
                              paddingTop: 0,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <StatusIcon order={order} />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={6}
                          >
                            <Collapse
                              in={open === order.order_id}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={1}>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      {Object.keys(positionColumnNames).map(
                                        (key) => (
                                          <TableCell key={key}>
                                            {positionColumnNames[key]}
                                          </TableCell>
                                        )
                                      )}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {orderPositions[order.order_id]?.map(
                                      (position) => (
                                        <TableRow key={position.position_id}>
                                          <TableCell>
                                            {position.position_id}
                                          </TableCell>
                                          <TableCell>
                                            {position.article_number}
                                          </TableCell>
                                          <TableCell>
                                            {position.article_stand}
                                          </TableCell>
                                          <TableCell>
                                            {`${position.amount} / ${position.amount_total}`}
                                          </TableCell>
                                          <TableCell>
                                            <Checkbox
                                              checked={selectedPositions.find(
                                                (p) =>
                                                  p.position_id ===
                                                    position.position_id &&
                                                  p.orderId === order.order_id
                                              )}
                                              onChange={() =>
                                                handleSelectPosition(
                                                  position,
                                                  order.order_id
                                                )
                                              }
                                            />
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsOptions}
              component="div"
              count={orders.filter((order) => order.isDone === 0 && (tab === 0 ? order.status === "gelb" : tab === 1 ? order.status === "rot" : order.status === "grün")).length} // Adjust pagination count
              rowsPerPage={rowsPerPageOrders}
              page={pageOrders}
              onPageChange={handleChangePageOrders}
              onRowsPerPageChange={handleChangeRowsPerPageOrders}
              labelRowsPerPage="Zeilen pro Seite:"
              SelectProps={{
                inputProps: { "aria-label": "Zeilen pro Seite" },
              }}
            />
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          md={1}
          container
          alignItems="center"
          justifyContent="center"
        >
          <IconButton onClick={handleArrowClick}>
            <ArrowForwardIcon />
          </IconButton>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper style={{ padding: "20px" }}>
            <h2>Job erstellen</h2>
            <FormControl required fullWidth sx={{ mb: 2 }}>
              <InputLabel id="tester-label">Tester</InputLabel>
              <Select
                labelId="tester-label"
                id="tester"
                label="Tester"
                value={tester}
                onChange={(e) => setTester(e.target.value)}
              >
                {userList.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {`${user.fullName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl required fullWidth sx={{ mb: 2 }}>
              <InputLabel id="priority-label">Priority</InputLabel>
              <Select
                labelId="priority-label"
                id="priority"
                label="Priorität"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Due Date"
              value={due_date}
              type="date"
              onChange={(e) => setDueDate(e.target.value)}
              fullWidth
              sx={{ marginBottom: 5 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <h3 style={{ marginTop: "20px" }}>Ausgewählte Positionen</h3>

            <List>
              {positionsToFill.map((position) => (
                <ListItem
                  key={position.position_id}
                  sx={{
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    marginBottom: "10px",
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={5}>
                      <span>
                        <b>Bestellung: </b>{position.order_id}
                        <br/>
                        <b>Position: </b>{position.position_id}
                      </span>
                    </Grid>
                    <Grid item xs={4} sx={{ marginLeft: "3px" }}>
                      <TextField
                        label="Menge"
                        value={`${amounts[position.position_id] || ""}`}
                        onChange={(e) =>
                          handleAmountChange(position, e.target.value)
                        }
                        fullWidth
                        sx={{ width: "100%" }}
                      />
                  </Grid>
                    <Grid item xs={1} sx={{ marginLeft: "3px" }}>
                      <span>{`/ ${position.amount}`}</span>
                    </Grid>
                    <Grid item xs={1} sx={{ marginLeft: "3px" }}>
                      <IconButton
                        aria-label="remove"
                        onClick={() => handleRemovePosition(position)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>

            <Button
              variant="contained"
              color="primary"
              disabled={
                !tester ||
                !priority ||
                !due_date ||
                positionsToFill.length === 0
              }
              onClick={createJob}
            >
              Job erstellen
            </Button>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
      <TableContainer component={Paper} sx={{marginTop: 2}}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Job ID</TableCell>
              <TableCell>Tester</TableCell>
              <TableCell>Erstellungsdatum</TableCell>
              <TableCell>Priorität</TableCell>
              <TableCell>Fälligkeitsdatum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs
            .slice(pageJobs * rowsPerPageJobs, pageJobs * rowsPerPageJobs + rowsPerPageJobs)
            .map((job) => (
              <React.Fragment key={job.jobID}>
                <TableRow>
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleRowToggle(job.jobID)}
                    >
                      {openJobs.includes(job.jobID) ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {job.jobID}
                  </TableCell>
                  <TableCell>{job.tester}</TableCell>
                  <TableCell>{job.performedDate}</TableCell>
                  <TableCell>{job.priority}</TableCell>
                  <TableCell>{job.dueDate}</TableCell>
                </TableRow>
                {openJobs.includes(job.jobID) && (
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={job.orders.length > 0}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            Bestellungen
                          </Typography>
                          <Table size="small" aria-label="orders">
                            <TableHead>
                              <TableRow>
                                <TableCell>Bestellungs ID</TableCell>
                                <TableCell>Kunde</TableCell>
                                <TableCell>Bestellungsdatum</TableCell>
                                <TableCell>Bestellungstyp</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {job.orders.map((order) => (
                                <React.Fragment key={order.orderID}>
                                  <TableRow>
                                    <TableCell>{order.orderID}</TableCell>
                                    <TableCell>{order.customerId}</TableCell>
                                    <TableCell>{order.orderDate}</TableCell>
                                    <TableCell>{order.orderType}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        paddingBottom: 0,
                                        paddingTop: 0,
                                      }}
                                      colSpan={6}
                                    >
                                      <Collapse
                                        in={order.positions.length > 0}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Box sx={{ margin: 1 }}>
                                          <Typography
                                            variant="h6"
                                            gutterBottom
                                            component="div"
                                          >
                                            Positionen
                                          </Typography>
                                          <Table
                                            size="small"
                                            aria-label="positions"
                                          >
                                            <TableHead>
                                              <TableRow>
                                                <TableCell>
                                                  Position ID
                                                </TableCell>
                                                <TableCell>
                                                  Bestellungs ID
                                                </TableCell>
                                                <TableCell>
                                                  Artikelnummer
                                                </TableCell>
                                                <TableCell>
                                                  Artikelstand
                                                </TableCell>
                                                <TableCell>Menge</TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {order.positions.map(
                                                (position) => (
                                                  position.orderID === order.orderID ?
                                                  <TableRow
                                                    key={position.positionID}
                                                  >
                                                    <TableCell>
                                                      {position.positionID}
                                                    </TableCell>
                                                    <TableCell>
                                                      {position.orderID}
                                                    </TableCell>
                                                    <TableCell>
                                                      {position.articleNumber}
                                                    </TableCell>
                                                    <TableCell>
                                                      {position.articleStand}
                                                    </TableCell>
                                                    <TableCell>
                                                      {position.amount}
                                                    </TableCell>
                                                  </TableRow>
                                                : [])
                                              )}
                                            </TableBody>
                                          </Table>
                                        </Box>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
              rowsPerPageOptions={rowsOptions}
              component="div"
              count={jobs.length}
              rowsPerPage={rowsPerPageJobs}
              page={pageJobs}
              onPageChange={handleChangePageJobs}
              onRowsPerPageChange={handleChangeRowsPerPageJobs}
              labelRowsPerPage="Zeilen pro Seite:"
              SelectProps={{
                inputProps: { "aria-label": "Zeilen pro Seite" },
              }}
            />
    </Container>
  );
};

export default Jobs;
