import React from "react";
import {
  Box,
  CircularProgress,
  Button,
  Grid,
  TextField
 } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const standButtons = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "K", "L", "M"];

const sxLoadingBoxes = {
  border: "1px solid",
  borderColor: "primary.main",
  height: "200px",
  padding: "5px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "top",
}

const styleBoardHeading = {
  fontSize: "18px",
  textAlign: "center",
  marginTop: "0px",
  marginBottom: "auto"
}

const sxCheckCircleIcon = {
  fontSize: 20,
  color: "green",
  marginBottom: "5px"
}

const sxCancelIcon = {
  fontSize: 20,
  color: "red",
  marginBottom: "5px"
}

const sxCircularProgress = {
  fontSize: 20,
  marginBottom: "5px"
}

const styleBmkInputField = {
    style: {
      height: "20px",
      width: "100%"
    }
}

const ScanForm = ({
    componentScan,
    loadingPowerStage,
    loadingControlBoard,
    loadingSpikeSupressionBoard,
    loadingEmvBoard,
    loadingSignalboard,
    loadingEmiShield,
    loadingEplcBoard,
    powerStageChecked,
    controlBoardChecked,
    spikeSupressionBoardChecked,
    emvBoardChecked,
    signalboardChecked,
    emiShieldChecked,
    eplcBoardChecked,
    deviceId,
    componentId,
    powerStageSN,
    controlBoardSN,
    spikeSupressionBoardSN,
    emvBoardSN,
    signalboardSN,
    emiShieldSN,
    eplcBoardSN,
    powerStageStand,
    controlBoardStand,
    spikeSupressionBoardStand,
    emvBoardStand,
    signalboardStand,
    bmkPowerStage,
    bmkControlBoard,
    bmkSpikeSupressionBoard,
    bmkEmvBoard,
    bmkSignalboard,
    bmkEmiShield,
    bmkEplcBoard,
    bmkNumber,
    snFieldValue,
    inputRef,
    allChecked,
    handleSNInputChange,
    handleKeyDown,
    handleSNInput,
    handleSubmit
  }) => {
    return (
        <div>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
            <Box>
              <p>Device ID: {deviceId}</p>
              <p>Komponenten ID: {componentId}</p>
              <p>BMK Nummer: {bmkNumber}</p>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box sx={sxLoadingBoxes}>
              <h2 style={styleBoardHeading}>SN Power Stage</h2>
              {loadingPowerStage ? (
                <CircularProgress sx={sxCircularProgress}/>
              ) : powerStageChecked ? (
                <CheckCircleIcon sx={sxCheckCircleIcon} />
              ) : (
                <CancelIcon sx={sxCancelIcon} />
              )}
              <p>{(powerStageSN && powerStageStand) ? powerStageSN + " " + powerStageStand : ""}</p>
              {bmkPowerStage ? <p>{bmkPowerStage}</p> :
              <TextField
                inputProps={styleBmkInputField}
                name="bmk-power-stage"
                label="BMK Power Stage"
                value={bmkPowerStage}
                onChange={handleSNInputChange}
                onKeyDown={handleKeyDown}
              />}
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box sx={sxLoadingBoxes}>
              <h2 style={styleBoardHeading}>SN Control Board</h2>
              {loadingControlBoard ? (
                <CircularProgress sx={sxCircularProgress}/>
              ) : controlBoardChecked ? (
                <CheckCircleIcon sx={sxCheckCircleIcon} />
              ) : (
                <CancelIcon sx={sxCancelIcon} />
              )}
              <p>{(controlBoardSN && controlBoardStand) ? controlBoardSN + " " + controlBoardStand : ""}</p>
              {bmkControlBoard ? <p>{bmkControlBoard}</p> :
              <TextField
                inputProps={styleBmkInputField}
                name="bmk-control-board"
                label="BMK Controlboard"
                value={bmkControlBoard}
                onChange={handleSNInputChange}
                onKeyDown={handleKeyDown}
              />}
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box sx={sxLoadingBoxes}>
                <h2 style={styleBoardHeading}>SN Spike Supression Board</h2>
              {loadingSpikeSupressionBoard ? (
                <CircularProgress sx={sxCircularProgress}/>
              ) : spikeSupressionBoardChecked ? (
                <CheckCircleIcon sx={sxCheckCircleIcon} />
              ) : (
                <CancelIcon sx={sxCancelIcon} />
              )}
              <p>{(spikeSupressionBoardSN && spikeSupressionBoardStand) ? spikeSupressionBoardSN + " " + spikeSupressionBoardStand : ""}</p>
              {bmkSpikeSupressionBoard ? <p>{bmkSpikeSupressionBoard}</p> :
              <TextField
                inputProps={styleBmkInputField}
                name="bmk-spike-supression-board"
                label="BMK Spike Supression Board"
                value={bmkSpikeSupressionBoard}
                onChange={handleSNInputChange}
                onKeyDown={handleKeyDown}
              />}
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box sx={sxLoadingBoxes}>
                 <h2 style={styleBoardHeading}>SN EMV Board</h2>
              {loadingEmvBoard ? (
                <CircularProgress sx={sxCircularProgress}/>
              ) : emvBoardChecked ? (
                <CheckCircleIcon sx={sxCheckCircleIcon} />
              ) :(
                <CancelIcon sx={sxCancelIcon} />
              )}
              <p>{(emvBoardSN && emvBoardStand) ? emvBoardSN + " " + emvBoardStand : ""}</p>
              {bmkEmvBoard ? <p>{bmkEmvBoard}</p> :
              <TextField
                inputProps={styleBmkInputField}
                name="bmk-emv-board"
                label="BMK EMV Board"
                value={bmkEmvBoard}
                onChange={handleSNInputChange}
                onKeyDown={handleKeyDown}
              />}
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box sx={sxLoadingBoxes}>
                    <h2 style={styleBoardHeading}>SN Signalboard</h2>
              {loadingSignalboard ? (
                <CircularProgress sx={sxCircularProgress}/>
              ) : signalboardChecked ? (
                <CheckCircleIcon sx={sxCheckCircleIcon} />
              ) : (
                <CancelIcon sx={sxCancelIcon} />
              )}
              <p>{(signalboardSN && signalboardStand) ? signalboardSN + " " + signalboardStand : ""}</p>
              {bmkSignalboard ? <p>{bmkSignalboard}</p> :
              <TextField
                inputProps={styleBmkInputField}
                name="bmk-signalboard"
                label="BMK Signalboard"
                value={bmkSignalboard}
                onChange={handleSNInputChange}
                onKeyDown={handleKeyDown}
              />}
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box sx={sxLoadingBoxes}>
            <h2 style={styleBoardHeading}>SN EMI Shield</h2>
              {loadingEmiShield ? (
                <CircularProgress sx={sxCircularProgress}/>
              ) : emiShieldChecked ? (
                <CheckCircleIcon sx={sxCheckCircleIcon} />
              ) : emiShieldSN ? (
                <CancelIcon sx={sxCancelIcon} />
              ) : (
                <p>N.A.</p>
              )}
              <p>{emiShieldSN ? emiShieldSN : ""}</p>
              {bmkEmiShield ? <p>{bmkEmiShield}</p> : (loadingEmiShield || emiShieldSN) ?
              <TextField
                inputProps={styleBmkInputField}
                name="bmk-emi-shield"
                label="BMK EMI Shield"
                value={bmkEmiShield}
                onChange={handleSNInputChange}
                onKeyDown={handleKeyDown}
              /> : ""}
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box sx={sxLoadingBoxes}>
            <h2 style={styleBoardHeading}>EPLC Board</h2>
              {loadingEplcBoard ? (
                <CircularProgress sx={sxCircularProgress}/>
              ) : eplcBoardChecked ? (
                <CheckCircleIcon sx={sxCheckCircleIcon} />
              ) : eplcBoardSN ? (
                <CancelIcon sx={sxCancelIcon} />
              ) : (
                <p>N.A.</p>
              )}
              <p>{eplcBoardSN ? eplcBoardSN : ""}</p>
              {bmkEplcBoard ? <p>{bmkEplcBoard}</p> : (loadingEplcBoard || eplcBoardSN) ?
              <TextField
                inputProps={styleBmkInputField}
                name="bmk-eplc-board"
                label="BMK EPLC Board"
                value={bmkEplcBoard}
                onChange={handleSNInputChange}
                onKeyDown={handleKeyDown}
              /> : ""}
            </Box>
          </Grid>
        </Grid>
    <Grid>
    <TextField
      label="Seriennummer"
      id="sn-field"
      name="sn-field"
      //Focus SN input field after Enter or standButtons keydown
      inputRef={inputRef}
      value={snFieldValue}
      onChange={handleSNInputChange}
      onKeyDown={handleKeyDown}
      sx={{ marginTop: 2, marginRight: 1 }}
    />
    </Grid>
    <Grid container spacing={2} sx={{marginTop: 2, whiteSpace: 'nowrap'}}>
    <Grid item xs={12} sm={10}>
    {standButtons
      .map((buttonValue) => (
        <Button
          onClick={(event) => handleSNInput(event.currentTarget.value)}
          value={buttonValue}
          variant="contained"
          color="primary"
          style={{marginRight: '3px', maxWidth: '65px', maxHeight: '55px', minWidth: '65px', minHeight: '55px'}}
        >
          {buttonValue}
        </Button>
      ))
    }          </Grid>
          <Grid item xs={12} sm={2}>

      <Button
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        disabled={!(allChecked || componentScan)}
        sx={{marginTop: "10px"}}
      >
        Bestätigen
      </Button>
      </Grid>
    </Grid>
    </div>
    );
}

export default ScanForm;