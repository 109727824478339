import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  FormControl,
  FormHelperText,
  Paper,
  CircularProgress,
  IconButton,
} from "@mui/material";
import Draggable from "react-draggable";
import CheckIcon from "@mui/icons-material/Check";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

const DraggableDialog = (props) => {
  const handleDrag = (event) => {
    event.preventDefault();
  };

  return (
    <Draggable handle=".drag-handle" onDrag={handleDrag}>
      <Paper {...props} />
    </Draggable>
  );
};

const TestResultsTemplateForm = ({ isOpen, onClose, onSubmit, title }) => {
  const emptyErrorMessage = "Dies ist ein Pflichtfeld"
  const validFileTypeDocx = ".docx";
  const fileTypeErrorMessage = `Ungültiger Dateityp. Gültige Dateitypen: ${validFileTypeDocx}`

  const [articleTypes, setArticleTypes] = useState([]);
  const [formData, setFormData] = useState({
    template_type: {
      value: null,
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    module_number: {
      value: "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    template_file: {
      value: null,
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage,
      fileTypeError: false,
      fileTypeErrorMessage: fileTypeErrorMessage
    }, 
    uploading: {
      value: false,
    }
  });

  useEffect(() => {
    fetchArticleTypes();
  }, []);

  const fetchArticleTypes = async () => {
    try {
      const response = await axios.get("/api/articleTypes");

      setArticleTypes(response.data);
    } catch (error) {
      console.error("Error fetching article types:", error);
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    if(formData[name.toString()].emptyErrorMessage) {
      checkNoEmptyInput(name, value);
    } else {
      setFormData({
        ...formData,
        [name]:{
          ...formData[name],
          value
        }
      })
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const name = event.target.name;

    if (!file.name.endsWith(validFileTypeDocx)) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: {
          ...prevData[name],
          fileTypeError: true,
          value: null
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: {
          ...prevData[name],
          emptyError: false,
          fileTypeError: false,
          value: file
        },
      }));
    }
  };

  const handleFileRemove = () => {
    setFormData((prevData) => ({
      ...prevData,
      template_file:{
        ...prevData["template_file"],
        emptyError: true,
        fileTypeError: false,
        value: null
      }
    }));
  };

  const handleSubmit = async () => {
    if (formData.uploading.value) return;

    if (!validInputFields()) return;

    // setFormData((prevData) => ({
    //   ...prevData,
    //   uploading: true,
    // }));

    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));

      onSubmit(formData);

      // setFormData((prevData) => ({
      //   ...prevData,
      //   uploading: false,
      // }));

      onClose();
    } catch (error) {
      console.error("Error creating test result template:", error);
      setFormData((prevData) => ({
        ...prevData,
        uploading: false,
      }));
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFormData((prevData) => ({
      ...prevData,
      software_file: file,
    }));
  };

  const checkNoEmptyInput = (fieldName, fieldValue) => {

    const isValid =!(fieldValue === "" || !fieldValue);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]:{
        ...prevFormData[fieldName],
        value: fieldValue,
        emptyError:!isValid
      }
    }));

    return isValid;
  }

  const validInputFields = () => {
    let isValid = true;
    const formFields = Object.keys(formData);

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formData[currentField].value;

      if(formData[currentField.toString()].emptyErrorMessage && (currentValue === "" || !currentValue)){

        isValid = false;
        checkNoEmptyInput(currentField, currentValue);

        if((formData.template_type.value === "label" || formData.template_type.value === "certificate")
        && currentField.toString() === "module_number") {
          isValid = true;
        }
      }

      if(formData[currentField.toString()].fileTypeErrorMessage && formData[currentField.toString()].fileTypeError) {
        isValid = false;
      }
    }

    return isValid;
  }

  const uniqueListOfModuleNumbers = () => {
    return [...new Set(articleTypes.map(item => item["modul_number"]))]
  }

  const slicedModuleNumbers = () => {
    return uniqueListOfModuleNumbers().map(((no => no.indexOf(".") === -1 ? no :no.substr(0, no.indexOf(".")))));
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      PaperComponent={DraggableDialog}
      onDrop={handleDrop}
      onDragOver={(event) => event.preventDefault()}
    >
      <DialogTitle className="drag-handle">{title}</DialogTitle>
      <DialogContent>
        <FormControl
          required
          sx={{ marginBottom: 2 }}
          error={formData.template_type.emptyError}
          fullWidth
          >
          <InputLabel id="template-type">Vorlagentyp</InputLabel>
            <Select
              labelId="template-type"
              id="template_type"
              label="Vorlagentyp"
              name="template_type"
              value={formData.template_type.value}
              onChange={handleChange}
              onBlur={() => checkNoEmptyInput("template_type", formData.template_type.value)}
            >
              <MenuItem
                key={"report"}
                value={"report"}
              >
                Bericht
              </MenuItem>
              <MenuItem
                key={"label"}
                value={"label"}
              >
                Etikett
              </MenuItem>
              <MenuItem
                key={"certificate"}
                value={"certificate"}
              >
                Zertifikat
              </MenuItem>
            </Select>
        <FormHelperText>{formData.template_type.emptyError?emptyErrorMessage:""}</FormHelperText>
        </FormControl>
        {formData.template_type.value === "report" && <FormControl
              fullWidth
              sx={{ marginBottom: 2 }}
              error={formData.module_number.emptyError}
            >
              <InputLabel id="module-number">Modulnummer</InputLabel>
              <Select
                labelId="module-number"
                id="module-number"
                label="Modulnummer"
                name="module_number"
                value={formData.module_number.value}
                onChange={handleChange}
                onBlur={() => checkNoEmptyInput("module_number", formData.module_number.value)}
              >
                {slicedModuleNumbers().map((moduleNumber) => (
                  <MenuItem
                    key={moduleNumber}
                    value={moduleNumber}
                  >
                    {moduleNumber}
                  </MenuItem>
                ))}
              </Select>
            <FormHelperText>{formData.module_number.emptyError?emptyErrorMessage:""}</FormHelperText>
            </FormControl>
        }
        {formData.template_type.value && <FormControl
          fullWidth
          error={formData.template_file.emptyError || formData.template_file.fileTypeError}
          sx={{ marginBottom: 2 }}
        >
          {formData.template_file.value ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={handleFileRemove}
                color="primary"
                disabled={formData.uploading.value}
              >
                <DeleteIcon />
              </IconButton>
              {formData.uploading.value ? (
                <CircularProgress size={24} sx={{ marginRight: 1 }} />
              ) : (
                <CheckIcon color="primary" sx={{ marginRight: 1 }} />
              )}
              {formData.uploading.value
                ? "Wird hochgeladen..."
                : `Datei: ${formData.template_file.value.name}`}
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                id="file-input"
                name="template_file"
                type="file"
                accept={validFileTypeDocx}
                style={{ display: "none" }}
                onChange={handleFileUpload}
                disabled={formData.uploading.value}
              />
                <IconButton sx={{ marginRight: 1 }}>
                <label htmlFor="file-input">
                  <CloudUploadIcon 
                    color="primary"
                    disabled={formData.uploading.value}
                    sx={{"&:hover":{cursor: "pointer"}}}
                  />
                </label>
                </IconButton>
              {formData.uploading.value ? "Wird hochgeladen..." : "Datei hochladen"}
            </div>
          )}
        <FormHelperText>{formData.template_file.emptyError?emptyErrorMessage:formData.template_file.fileTypeError?
          fileTypeErrorMessage:""}</FormHelperText>
        </FormControl>
      }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Abbrechen
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {formData.uploading.value
            ? "Wird hochgeladen..."
            : "Erstellen"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TestResultsTemplateForm;
