import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  Box,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Snackbar } from "@mui/material";

const OrderForm = ({ onSubmit, fetchOrders }) => {
  const emptyErrorMessage = "Dies ist ein Pflichtfeld"
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [customers, setCustomers] = useState([]);
  const [formData, setFormData] = useState({
    order_id: {
      value: "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    customer_id: {
      value: "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    distribution: {
      value: ""
    },
    order_date: {
      value: "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    },
    order_type: {
      value: "",
      emptyError: false,
      emptyErrorMessage: emptyErrorMessage
    }
  });

  const filterCustomersByDistribution = () => {
    // Filter für das "Kunde"-Select-Feld
    const filteredCustomers = customers;

    // Filter für das "Vertrieb"-Select-Feld (nur Kunden mit distribution === 1)
    const filteredDistributionCustomers = customers.filter(
      (customer) => customer.distribution === 1
    );

    setCustomers(filteredCustomers);
    setFilteredDistributionCustomers(filteredDistributionCustomers);
  };

  const [filteredDistributionCustomers, setFilteredDistributionCustomers] =
    useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if(formData[name.toString()].emptyErrorMessage) {
      checkNoEmptyInput(name, value);
    } else {
      setFormData({
        ...formData,
        [name]:{
          ...formData[name],
          value
        }
      })
    }
  };

  const handleSubmit = (event) => {
    if (!validInputFields()) return;

    let flatFormData = {};
    Object.keys(formData).map((key) => flatFormData[key] = formData[key].value);

    event.preventDefault();
    for (let field in formData) {
      if (!formData[field]) {
        console.error(`${field} is required`);
        return;
      }
    }

    // Check if order_id already exists
    axios
      .get(`/api/ordersWorkflow/${formData.order_id.value}`)
      .then((response) => {
        if (response.data.length > 0) {
          setFormData({
            order_id: {
              value: "",
              emptyError: false,
              emptyErrorMessage: emptyErrorMessage
            },
            customer_id: {
              value: "",
              emptyError: false,
              emptyErrorMessage: emptyErrorMessage
            },
            distribution: {
              value: ""
            },
            order_date: {
              value: "",
              emptyError: false,
              emptyErrorMessage: emptyErrorMessage
            },
            order_type: {
              value: "",
              emptyError: false,
              emptyErrorMessage: emptyErrorMessage
            }
          });

          return;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          axios
            .post("/api/ordersWorkflow", flatFormData)
            .then((response) => {
              setSnackbarMessage("Bestellung wurde erfolgreich erstellt!");
              setSnackbarOpen(true);

              // Clear the form
              setFormData({
                order_id: {
                  value: "",
                  emptyError: false,
                  emptyErrorMessage: emptyErrorMessage
                },
                customer_id: {
                  value: "",
                  emptyError: false,
                  emptyErrorMessage: emptyErrorMessage
                },
                distribution: {
                  value: ""
                },
                order_date: {
                  value: "",
                  emptyError: false,
                  emptyErrorMessage: emptyErrorMessage
                },
                order_type: {
                  value: "",
                  emptyError: false,
                  emptyErrorMessage: emptyErrorMessage
                }
              });

              // Fetch orders again to update the list
              fetchOrders();
            })
            .catch((error) => {
              console.error("Error creating order:", error);
            });
        } else {
          setSnackbarMessage("BestellungsID gibt es bereits!");
          setSnackbarOpen(true);
          console.error("Error checking order ID:", error);
        }
      });
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    filterCustomersByDistribution();
  }, [customers]);

  const isFormComplete = Object.values(formData).every((value) => value !== "");

  const fetchCustomers = async () => {
    try {
      const response = await axios.get("/api/customersWorkflow");
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const checkNoEmptyInput = (fieldName, fieldValue) => {

    const isValid = !(fieldValue === "");

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]:{
        ...prevFormData[fieldName],
        value: fieldValue,
        emptyError:!isValid
      }
    }));

    return isValid;
  }

  const validInputFields = () => {
    let isValid = true;
    const formFields = Object.keys(formData);

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formData[currentField].value;

      if(formData[currentField.toString()].emptyErrorMessage && currentValue === ""){
        isValid = false;
        checkNoEmptyInput(currentField, currentValue)
      }

  }
  return isValid;
}


  return (
    <Box sx={{ padding: 2, margin: "0 auto", maxWidth: 800 }}>
      <Typography variant="h5">Neue Bestellung</Typography>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <TextField
          required
          label="Order ID"
          name="order_id"
          value={formData.order_id.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("order_id", formData.order_id.value)}
          fullWidth
          sx={{ marginBottom: 5, marginTop: 5 }}
          error={formData.order_id.emptyError}
          helperText={formData.order_id.emptyError?emptyErrorMessage:""}
        />
        <FormControl
          required
          fullWidth
          variant="outlined"
          sx={{ marginBottom: 5 }}
          error={formData.customer_id.emptyError}
        >
          <InputLabel id="customer-label">Kunde</InputLabel>
          <Select
            labelId="customer-label"
            id="customer_id"
            name="customer_id"
            label="Kunde"
            value={formData.customer_id.value}
            onChange={handleChange}
            onBlur={() => checkNoEmptyInput("customer_id", formData.customer_id.value)}
          >
            {customers.map((customer) => (
              <MenuItem key={customer.customer_id} value={customer.customer_id}>
                {customer.name}
              </MenuItem>
            ))}
          </Select>
        <FormHelperText>{formData.customer_id.emptyError?emptyErrorMessage:""}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{ marginBottom: 5 }}
          error={formData.distribution.emptyError}
        >
          <InputLabel id="distribution-label">Vertrieb</InputLabel>
          <Select
            labelId="distribution-label"
            id="distribution"
            name="distribution"
            label="Vertrieb"
            value={formData.distribution.value}
            onChange={handleChange}
          >
            {filteredDistributionCustomers.map((customer) => (
              <MenuItem key={customer.customer_id} value={customer.customer_id}>
                {customer.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          required
          label="Order Date"
          name="order_date"
          type="date"
          value={formData.order_date.value}
          onChange={handleChange}
          onBlur={() => checkNoEmptyInput("order_date", formData.order_date.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          InputLabelProps={{
            shrink: true,
          }}
          error={formData.order_date.emptyError}
          helperText={formData.order_date.emptyError?emptyErrorMessage:""}
        />
        <FormControl
          required
          fullWidth
          variant="outlined"
          sx={{ marginBottom: 5 }}
          error={formData.order_type.emptyError}
        >
          <InputLabel id="order-type-label">Bestellungstyp</InputLabel>
          <Select
            labelId="order-type-label"
            id="order_type"
            name="order_type"
            label="Bestellungstyp"
            value={formData.order_type.value}
            onChange={handleChange}
            onBlur={() => checkNoEmptyInput("order_type", formData.order_type.value)}
          >
            <MenuItem value="Produktionsauftrag">Produktionsauftrag</MenuItem>
            <MenuItem value="Reparaturauftrag">Reparaturauftrag</MenuItem>
          </Select>
        <FormHelperText>{formData.order_type.emptyError?emptyErrorMessage:""}</FormHelperText>
        </FormControl>
        <Button
          type="submit"
          fullWidth
          sx={{ marginBottom: 5 }}
          disabled={!isFormComplete}
          variant={isFormComplete ? "contained" : "outlined"}
          color={isFormComplete ? "primary" : "secondary"}
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default OrderForm;
