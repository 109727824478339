import React from "react";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ReportIcon from "@mui/icons-material/Report";
import PeopleIcon from "@mui/icons-material/People";
import ListIcon from "@mui/icons-material/List";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";
import DevicesIcon from "@mui/icons-material/Devices";
import TerminalIcon from "@mui/icons-material/Terminal"; // Terminal Icon
import logo from "../../media/images/favicon.png";
import BarChartIcon from "@mui/icons-material/BarChart";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

const Sidebar = () => {
  const navigate = useNavigate();

  const handleNavigationClick = (route) => {
    navigate(route);
  };

  return (
    <Paper
      sx={{
        p: 2,
        textAlign: "center",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "white",
        borderRight: "2px solid rgb(52, 188, 52)",
        borderRadius: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "150px",
        transition: "width 0.3s ease-in-out",
        overflowX: "hidden",
        zIndex: "2"
      }}
    >
      <>
        <img
          onClick={() => handleNavigationClick("/")}
          sx={{ p: 1, textAlign: "center" }}
          src={logo}
          alt="Logo"
          style={{ width: "70%", paddingBottom: "85px", marginLeft: "15px" }}
        />

        <IconButton
          onClick={() => handleNavigationClick("/job-list")}
          sx={{ p: 1 }}
        >
          <CheckIcon fontSize="large" color="primary" />
        </IconButton>
        <Typography>Job Liste</Typography>
        {/* Neues Icon fÃ¼r die Software */}
        
        
        <hr
          style={{
            margin: "8px 0",
            border: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
        />
        

        <IconButton
          onClick={() => handleNavigationClick("/orders")}
          sx={{ p: 1 }}
        >
          <ShoppingCartIcon fontSize="large" color="primary" />
        </IconButton>
        <Typography>Bestellungen</Typography>
        
        
        <hr
          style={{
            margin: "8px 0",
            border: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
        />


        <IconButton onClick={() => handleNavigationClick("/job")} sx={{ p: 1 }}>
          <ScheduleIcon fontSize="large" color="primary" />
        </IconButton>
        <Typography>Job</Typography>
        
        
        <hr
          style={{
            margin: "8px 0",
            border: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
        />
        

        <IconButton
          onClick={() => handleNavigationClick("/devices")}
          sx={{ p: 1 }}
        >
          <DevicesIcon fontSize="large" color="primary" />
        </IconButton>
        <Typography>Devices</Typography>
        
        <hr
          style={{
            margin: "8px 0",
            border: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
        />


        <IconButton
          onClick={() => handleNavigationClick("/test-result")}
          sx={{ p: 1 }}
        >
          <ReportIcon fontSize="large" color="primary" />
        </IconButton>
        <Typography>Bericht</Typography>
        
        
        <hr
          style={{
            margin: "8px 0",
            border: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
        />
        
        
        <IconButton
          onClick={() => handleNavigationClick("/customers")}
          sx={{ p: 1 }}
        >
          <PeopleIcon fontSize="large" color="primary" />
        </IconButton>
        <Typography>Kunden</Typography>
        
        
        <hr
          style={{
            margin: "8px 0",
            border: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
        />
        
        <IconButton
          onClick={() => handleNavigationClick("/parameterSet")}
          sx={{ p: 1 }}
        >
          <BarChartIcon fontSize="large" color="primary" />
        </IconButton>
        <Typography>Parametersatz</Typography>
        {/* Neues Icon fÃ¼r Component Scan */}
        <hr
          style={{
            margin: "8px 0",
            border: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
        />
        
        <IconButton
          onClick={() => handleNavigationClick("/article")}
          sx={{ p: 1 }}
        >
          <ListIcon fontSize="large" color="primary" />
        </IconButton>
        <Typography>Artikel</Typography>
        
        
        <hr
          style={{
            margin: "8px 0",
            border: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
        />

        <IconButton
          onClick={() => handleNavigationClick("/componentScan")}
          sx={{ p: 1 }}
        >
          <QrCodeScannerIcon fontSize="large" color="primary" />
        </IconButton>
        <Typography>Produktion</Typography>
        
        <hr
          style={{
            margin: "8px 0",
            border: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
        />

        
        <IconButton
          onClick={() => handleNavigationClick("/software")}
          sx={{ p: 1 }}
        >
          <TerminalIcon fontSize="large" color="primary" />
        </IconButton>
        <Typography>Software</Typography>
        {/* Neues Icon fÃ¼r Parametersatz */}
        

      </>
    </Paper>
  );
};

export default Sidebar;